/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author: Ramadevi Gavirneni
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ISpecificDayHours } from "../../../../../companion";

@Component({
  selector: "specific-day-hours",
  templateUrl: "./specific-day-hours.template.html"
})

export class SpecificDayHoursComponent {

  // Passes a reference to Specific Day Hours element
  @Input()
  item: ISpecificDayHours;

  // Passes an index into the list of specific day hours so we can act on a specific item in the list (used for remove)
  @Input()
  itemIndex: number;

  // Passes a Id to use for the individual elements based on the element in the parent component
  @Input()
  specificDayHoursId: string;

  // Flag indicating if this element represents
  // special day(true; allows calendar date specification using date picker and allowing it to be removed)
  //or
  // day of week (false: day name is fixed and cannot be updated and the day cannot be removed)
  @Input()
  specialHours: boolean;

  // emitter for remove click
  @Output("remove")
  onRemoveSpecialDayHoursEventEmitter: EventEmitter<Number> = new EventEmitter<Number>();

  ngOnInit() {
    // Nothing special to do.
  }

  // handler for when open/close check box is updated
  onOpenChange() {
    if (!this.item.open) {
      // clear the start and end times when change to closed
      this.item.startTime = "";
      this.item.endTime = "";
    }
  }

  // handler for date updated
  onDateChange() {
    // Nothing special to do. We validate it when saving the group.
  }

  // handler for start time updated
  onStartTimeChange() {
    // Nothing special to do. We validate it when saving the group.
  }

  // handler for end time updated
  onEndTimeChange() {
    // Nothing special to do. We validate it when saving the group.
  }

  // handler for when remove button is clicked to remove the item
  removeSpecialDayHours(removeItem: ISpecificDayHours) {
    // pass it on to the parent component so it can handle it
    this.onRemoveSpecialDayHoursEventEmitter.emit(this.itemIndex);
  }

}
