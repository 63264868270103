import { CommonModule } from "@angular/common";
import {Component, Input} from "@angular/core";


@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "signal-bars",
  templateUrl: "./signal-bars.template.html"
})
export class SignalBarsComponent {
    // a quality value from 0 to 5.
    @Input() quality: number;

    getBarCount()
    {
        if(this.quality > 4)
        {
            return 4;
        }
        if(this.quality > 3)
        {
            return 3;
        }
        if(this.quality > 2)
        {
            return 2;
        }
        return 1;
    }
}