"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
});
const e = /\n/g;
function t(t) {
  const o = [...t.matchAll(e)].map(e => e.index || 0);
  o.unshift(-1);
  const s = n(o, 0, o.length);
  return e => r(s, e);
}
function n(e, t, r) {
  if (r - t == 1) return {
    offset: e[t],
    index: t + 1
  };
  const o = Math.ceil((t + r) / 2),
    s = n(e, t, o),
    l = n(e, o, r);
  return {
    offset: s.offset,
    low: s,
    high: l
  };
}
function r(e, t) {
  return function (e) {
    return Object.prototype.hasOwnProperty.call(e, "index");
  }(e) ? {
    line: e.index,
    column: t - e.offset
  } : r(e.high.offset < t ? e.high : e.low, t);
}
function o(e, t) {
  return {
    ...e,
    regex: s(e, t)
  };
}
function s(e, t) {
  if (0 === e.name.length) throw new Error(`Rule #${t} has empty name, which is not allowed.`);
  if (function (e) {
    return Object.prototype.hasOwnProperty.call(e, "regex");
  }(e)) return function (e) {
    if (e.global) throw new Error(`Regular expression /${e.source}/${e.flags} contains the global flag, which is not allowed.`);
    return e.sticky ? e : new RegExp(e.source, e.flags + "y");
  }(e.regex);
  if (function (e) {
    return Object.prototype.hasOwnProperty.call(e, "str");
  }(e)) {
    if (0 === e.str.length) throw new Error(`Rule #${t} ("${e.name}") has empty "str" property, which is not allowed.`);
    return new RegExp(l(e.str), "y");
  }
  return new RegExp(l(e.name), "y");
}
function l(e) {
  return e.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&");
}
exports.createLexer = function (e, n = "", r = {}) {
  const s = "string" != typeof n ? n : r,
    l = "string" == typeof n ? n : "",
    c = e.map(o),
    i = !!s.lineNumbers;
  return function (e, n = 0) {
    const r = i ? t(e) : () => ({
      line: 0,
      column: 0
    });
    let o = n;
    const s = [];
    e: for (; o < e.length;) {
      let t = !1;
      for (const n of c) {
        n.regex.lastIndex = o;
        const c = n.regex.exec(e);
        if (c && c[0].length > 0) {
          if (!n.discard) {
            const e = r(o),
              t = "string" == typeof n.replace ? c[0].replace(new RegExp(n.regex.source, n.regex.flags), n.replace) : c[0];
            s.push({
              state: l,
              name: n.name,
              text: t,
              offset: o,
              len: c[0].length,
              line: e.line,
              column: e.column
            });
          }
          if (o = n.regex.lastIndex, t = !0, n.push) {
            const t = n.push(e, o);
            s.push(...t.tokens), o = t.offset;
          }
          if (n.pop) break e;
          break;
        }
      }
      if (!t) break;
    }
    return {
      tokens: s,
      offset: o,
      complete: e.length <= o
    };
  };
};