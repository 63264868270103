/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";

/**
 * Truncate a string to the given length and append suffix.
 * @param	length Text max length. Default 20.
 * @param	suffix Appended to the end of the string if truncted. Default ''.
 * @example Usage:
 * ```html
 * <p>
 *   {{ 'Hello world' | truncate:5:'...' }}
 * </p>
 * <!-- Formats to: 'Hello...' -->
 * ```
 */
@Pipe({ name: "truncate" })
export class TruncatePipe {
  transform(value: string, max: string, postfix: string): any {
    let length = parseInt(max || "20", 10),
      suffix = postfix || "";

    if (value.length <= length) {
      return value;
    }

    return value.substring(0, length) + suffix;
  }
}
