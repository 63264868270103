/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { ListComponent } from "./list.component";
import { ListSearchPipe } from "./list-search.pipe";
import { ListItemComponent } from "./list-item.component";
import { ListNewItemComponent } from "./list-new-item.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  imports: [BrowserModule, FormsModule, SharedModule],
  declarations: [ListComponent, ListItemComponent, ListNewItemComponent, ListSearchPipe],
  exports: [ListComponent]
})
export class ListModule {}
