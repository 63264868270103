/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Ramadevi Gavirneni
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ISpecificDayHours, IOperatingHours, IHoursOfOperation } from "../../../../../companion";

@Component({
  selector: "hours-of-operation",
  templateUrl: "./hours-of-operation.template.html"
})

export class HoursOfOperationComponent {

  // Passes a reference to group's operatingHours object
  @Input()
  operatingHours: IOperatingHours;

  // Passes a Id to use for the elements based on the element in the parent component
  @Input()
  operatingHoursId: string;

  // List of timezone options supported
  @Input()
  timezoneOptions: any[];

  ngOnInit() {
    // nothing special to do
  }

  // A new timezone is selected from the Operating Timezone list
  onOperatingTimezoneChange(operatingHours: IOperatingHours, value: string) {
    // Nothing special to do.
  }

  // adds an item to the list of special day hours; to the end with default of closed.
  // called when Add New + is clicked
  addSpecialDayHours() {
    let newItem: ISpecificDayHours = { theDay: "", open: false, startTime: "", endTime: "" };
    if (!this.operatingHours.specialDayHours) {
      this.operatingHours.specialDayHours = [];
    }
    this.operatingHours.specialDayHours.push(newItem);
  }

  // removes an item from the list of special day hours based on the index
  removeSpecialDayHours(removeItemIndex: number) {
    if (removeItemIndex >= 0 && removeItemIndex < this.operatingHours.specialDayHours.length) {
      this.operatingHours.specialDayHours.splice(removeItemIndex, 1);
    }
  }

}
