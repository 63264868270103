/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IChatRoom } from "./chatroom.interface";
import { IEndpoint, IEndpointRef } from "../endpoint/endpoint.interface";
import { IMessage } from "../message/message.interface";
import { UUID } from "../util/uuid";

/**
 * chat room class
 */
export class ChatRoom implements IChatRoom {
  constructor(
    public targets: IEndpointRef[],
    public messages: IMessage[] = [],
    public ownerRtcId?: string,
    public roomId?: string,
    public unreadMessageCount: number = 0
  ) {
    if (roomId == null) {
      this.roomId = UUID.UUID();
    }

    if (ownerRtcId == null) {
      this.ownerRtcId = targets[0]?.rtcId;
    }

    if (targets == null) {
      this.targets = [];
    }
  }
}
