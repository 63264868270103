/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IChatRoom } from "./chatroom.interface";
import { ChatRoom } from "./chatroom";
import { IEndpoint, IEndpointRef } from "../endpoint/endpoint.interface";
import { IEndpointService } from "../endpoint/endpoint.service.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { IChatRoomService } from "./chatroom.service.interface";
import { IMessage } from "../message/message.interface";
import { Message } from "../message/message";
import { MessageService } from "../message/message.service";
import * as _ from "lodash";

/**
 * Chat room methods delegate
 */
export class ChatRoomService implements IChatRoomService {
  private static sharedInstance: ChatRoomService;
  public rooms: IChatRoom[] = [];
  public currentRoom: IChatRoom;

  private endpointService: IEndpointService;

  private notifyCurrentRoomClosedFn: ()=>void;

  /**
   * get shared singleton object
   */
  static getSharedInstance(): IChatRoomService {
    if (!ChatRoomService.sharedInstance) {
      ChatRoomService.sharedInstance = new ChatRoomService();
      ChatRoomService.sharedInstance.endpointService = EndpointService.getSharedInstance();
    }
    return ChatRoomService.sharedInstance;
  }

  setCurrentRoomClosedHandler(callback: ()=>void): void {
    this.notifyCurrentRoomClosedFn = callback;
  }
  /**
   * create a new chat room
   * @param room: IChatRoom - the room object to create
   */
  create(room: IChatRoom): void {
    this.rooms.push(room);
  }

  /**
   * delete a chat room
   * @param room: IChatRoom - the room object to delete
   */
  delete(room: IChatRoom): void {
    let didNotify = false;
    if (this.currentRoom?.roomId == room.roomId && this.notifyCurrentRoomClosedFn) {
      //NOTIFY
      this.currentRoom = null;
      this.notifyCurrentRoomClosedFn();
      didNotify = true;
    }
    this.rooms.splice(this.rooms.indexOf(room), 1);

    if (!didNotify && this.rooms.length == 0 && this.notifyCurrentRoomClosedFn) {
      //NOTIFY
      this.currentRoom = null;
      this.notifyCurrentRoomClosedFn();
      didNotify = true;
    }
  }

  /**
   * Locate and clear/delete any chatrooms that include this endpoint.
   * There's no point in keeping them around if we can't see who they are.
   * @param removedIds: string[] - list of rtcIds that were removed.
   */
  deleteChatsByEpIds(removedIds: string[]): void {
    let roomsToRemove = [];
    _.forEach(this.rooms, (room) => {
      if (_.find(room.targets, (target) => {
        return removedIds.includes(target.rtcId);
      })) {
        console.log("Clearing chat due to removed endpoint:", room.roomId);
        roomsToRemove.push(room);
      }
    })

    _.forEach(roomsToRemove, (room) => {
      this.delete(room);
    })
  }

  /**
   * add endpoint to a chat room
   * @param endpoint: IEndpoint - the endpoint object to enter
   * @param room: IChatRoom - the room object to enter
   */
  addEndpointToRoom(endpoint: IEndpointRef, room: IChatRoom): void {
    if (!endpoint || !room) {
      return;
    }
    if (!_.find(room.targets, { rtcId: endpoint.rtcId })) {
      room.targets.push(endpoint);
      var message: IMessage = new Message(
        this.endpointService.myEndpoint.rtcId,
        this.endpointService.myEndpoint.name,
        _.map(room.targets, "rtcId"),
        _.map(room.targets, "name"),
        this.endpointService.myEndpoint.name + " Added " + endpoint.name + " to this group chat.",
        room.ownerRtcId
      );
      MessageService.getSharedInstance().sendMessage(room, message);
      this.addMessageToChatRoom(room, message, false);
    }
  }

  /**
   * endpoints enter a chat room
   * @param endpoints: IEndpoint[] - the endpoint objects to enter
   * @param room: IChatRoom - the room object to enter
   */
  addEndpointsToRoom(endpoints: IEndpoint[], room: IChatRoom): void {
    _.forEach(endpoints, (endpoint: IEndpoint) => {
      this.addEndpointToRoom(endpoint, room);
    });
  }

  /**
   * find or create chat rooms by message target endpoints
   */
  createChatRoomByMessageTargets(targetEndpoints: IEndpointRef[], ownerRtcId: string, roomId?: string): IChatRoom {
    let owner = this.endpointService.getEndpointById(ownerRtcId);
    let amOwner = (this.endpointService.myEndpoint.rtcId === ownerRtcId);
    if (!targetEndpoints.includes(owner)) {
      targetEndpoints.push(owner);
    }
    // Create the room (auto roomId if not specified in constructor)
    let newRoom = new ChatRoom(targetEndpoints, [], owner?.rtcId, roomId);
    this.create(newRoom);  

    /* Do not notify on chat create (see RM6687)

    // Do I let everyone know that I created this room? If the roomId is known when creating... this is not a new chat.
    if (owner && amOwner && !roomId) {
      let names = _.map(newRoom.targets, "name")
      var message: IMessage = new Message(
        this.endpointService.myEndpoint.rtcId,
        this.endpointService.myEndpoint.name,
        _.map(newRoom.targets, "rtcId"),
        names,
        this.endpointService.myEndpoint.name + " Added " + names.join(", ") + " to this group chat.",
        ownerRtcId
      );
      MessageService.getSharedInstance().sendMessage(newRoom, message);
      this.addMessageToChatRoom(newRoom, message, false);
    }
    */
    return newRoom;
  }

  findExistingRoomByTargets(targetEndpoints: IEndpoint[]) : IChatRoom {
    var targetRooms = _.filter(this.rooms, (room: IChatRoom) => {
      return _.isEqual(
        _.orderBy(
          _.compact(_.map(room.targets, (endpoint: IEndpointRef) => {
            if (this.endpointService.myEndpoint.rtcId != endpoint.rtcId) {
              return endpoint.rtcId;
            }
          }))
        ),
        _.orderBy(
          _.compact(_.map(targetEndpoints, (endpoint: IEndpointRef) => {
            if (this.endpointService.myEndpoint.rtcId != endpoint.rtcId) {
              return endpoint.rtcId;
            }
          }))
        )
      );
    });

    return targetRooms?.[0];
  }

  findChatRoomById(roomId: string): IChatRoom {
    var targetRooms = _.filter(this.rooms, (room: IChatRoom) => {
      return room.roomId == roomId;
    });
    
    return targetRooms?.[0];
  }

  /**
   * add message to the room
   * @param message:IMessage - the new message to add
   * @param incrementUnread: boolean - flag if should trigger unread count increment
   */
  addMessageToChatRoom(chatroom: IChatRoom, message: IMessage, incrementUnread: boolean): void {
    chatroom.messages.push(message);
    if (incrementUnread) {
      chatroom.unreadMessageCount++;
    }
  }

  /**
   * reset unread message count on this room
   */
  resetUnreadOfChatRoom(chatroom: IChatRoom): void {
    chatroom.unreadMessageCount = 0;
  }

  /**
   * count total unread messages
   */
  countTotalUnreadMessages(): number {
    return _.sumBy(this.rooms, "unreadMessageCount");
  }

  /**
   * clear all chat rooms
   */
  clearAll() {
    this.currentRoom = null;
    this.rooms = [];
  }
}
