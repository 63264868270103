'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
function clamp(left, x, right) {
  return Math.max(left, Math.min(x, right));
}
function escapeWhitespace(str) {
  return str.replace(/(\t)|(\r)|(\n)/g, (m, t, r) => t ? '\\t' : r ? '\\r' : '\\n');
}
exports.clamp = clamp;
exports.escapeWhitespace = escapeWhitespace;