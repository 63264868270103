/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { TreeItemComponent } from "./tree-item.component";
import { TreeViewComponent } from "./tree-view.component";

@NgModule({
  imports: [BrowserModule],
  declarations: [TreeViewComponent, TreeItemComponent],
  bootstrap: [TreeViewComponent],
  exports: [TreeViewComponent]
})
export class TreeViewModule {}
