/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  frivolta,amaggi
 */
import * as ErrorStackParser from "error-stack-parser";
const UAParser = require("ua-parser-js");
import { IEndpointService } from "../endpoint/endpoint.service.interface"
import { EndpointService } from "../endpoint/endpoint.service"
import { CollectLogsMode, ICollectLogs } from "../settings/config.interface";

const PERIODIC_CLEANUP_TIMEOUT = 60 * 1000;

interface IConsoleLog {
  type: string;
  time: string;
  fileName: string;
  functionName: string;
  lineNumber: number;
  columnNumber: number;
  msg: string | string[];
}

/**
 * Log util
 */
export class LogUtil {

  private static logInstance: LogUtil;
  private endpointService: IEndpointService = EndpointService.getSharedInstance();

  private readonly logKeyPrefix: string = "console_log_key_";
  private readonly logKeyCount: string = this.logKeyPrefix + "_count";

  private static getUserMediaRequestCount = 0;

  logsSettings: ICollectLogs = {};
  store = [];
  storeLimitCount = 10000;
  logsToRemove: string[];
  logMethod = window.console.log;
  warnMethod = window.console.warn;
  debugMethod = window.console.debug;
  infoMethod = window.console.info;
  errorMethod = window.console.error;

  hasPermissionToSend = false;

  /**
   * get shared singleton object for console log
   */
  static getLogInstance(): LogUtil {
    if (!LogUtil.logInstance) {
      LogUtil.logInstance = new LogUtil();
      LogUtil.logInstance.periodicCleanUp();
    }
    return LogUtil.logInstance;
  }

  /**
   * init
   */
  init(settings: ICollectLogs) {
    this.logsSettings = settings;
    this.storeLimitCount = settings.maxItems || 10000;
    if (this.logsSettings?.enabled) {
      this.overrideConsole();
    }

    // clear the webRTC stats for new instance
    this.getStorage()?.removeItem("WEB_RTC_CALL_STATS");
  }

  /**
   * periodically remove oldest log
   */
  private periodicCleanUp() {
    const timer = setTimeout(() => {
      clearTimeout(timer);

      const keys = this.getLogKeys();
      if (keys.length > this.storeLimitCount) {
        const keysToRemove = keys.slice(0,  keys.length - this.storeLimitCount);
        this.removeItems(keysToRemove);
      }

      this.periodicCleanUp();
    }, PERIODIC_CLEANUP_TIMEOUT);
  }

  /**
   * generate error to get stack trace
   * @private
   */
  private generateError() {
    const err = new Error();

    if (err.stack) {
      return err;
    }

    try {
      throw err;
    } catch (e) {
      return e;
    }
  }

  /**
   * return the most significant stack
   * @param stacks
   * @private
   */
  private getSignificantStackTrace(stacks: StackFrame[]): StackFrame {
    const stack = stacks.filter(r => {
      if (!r.functionName) {
        return false;
      }
      return !r.functionName.startsWith("LogUtil.") &&
        !r.functionName.startsWith("console.window.") &&
        !r.functionName.startsWith("window.console.") &&
        !r.functionName.startsWith("ConferenceService.defaultAlertHandle") &&
        !r.functionName.startsWith("ConferenceService.alertHandler") &&
        !r.functionName.startsWith("RTCClient.alertHandle") &&
        !r.functionName.startsWith("self.debugPrinter") &&
        !r.functionName.startsWith("logDebug") &&
        !r.functionName.startsWith("generateError");
    });

    if (stack.length > 0) {
      return stack[0];
    }

    return stacks[stacks.length - 1];
  }

  /**
   * return log type
   * @param method
   * @private
   */
  private getLogType(method): string {
    switch (method) {
      case this.logMethod:   return "log";
      case this.warnMethod:  return "warn";
      case this.debugMethod: return "debug";
      case this.infoMethod:  return "info";
      case this.errorMethod: return "error";
      default: return "";
    }
  }

  /**
   * return an object with all the log info
   * @param method
   * @private
   */
  private getLogInfo(method): IConsoleLog {
    const error = this.generateError();
    const stack = ErrorStackParser.parse(error);
    const selStack = this.getSignificantStackTrace(stack);
    const logType = this.getLogType(method);

    return {
      type: logType,
      time: new Date().toISOString(),
      fileName: selStack.fileName,
      functionName: selStack.functionName,
      lineNumber: selStack.lineNumber,
      columnNumber: selStack.columnNumber,
      msg: [selStack.source]
    };
  }

  /**
   * return the storage
   * @private
   */
  private getStorage(): Storage {
    if (sessionStorage) {
      return sessionStorage;
    }

    if (localStorage) {
      return localStorage;
    }
    return null;
  }

  /**
   * return all the log keys saved in the storage
   * @private
   */
  private getLogKeys(): string[] {
    let search = this.logKeyPrefix;
    let storage = this.getStorage();

    return Object.keys(storage)
      .filter((key) => key.startsWith(search))
      .filter((key) => key !== this.logKeyCount)
      .sort((a, b) => {
        return parseInt(a.replace(this.logKeyPrefix, ""), 10) -
          parseInt(b.replace(this.logKeyPrefix, ""), 10);
      });
  }

  /**
   * return all the logs
   * @private
   */
  private getLogs(): IConsoleLog[] {
    const storage = this.getStorage();
    let result;
    let chunk;

    this.logsToRemove = [];

    if (!storage) {
      return this.store;
    }
    result = [];

    const keys = this.getLogKeys();
    keys.forEach(key => {
      chunk = JSON.parse(storage.getItem(key));
      this.logsToRemove.push(key);

      chunk.forEach(row => {
        result.push(row);
      });
    });

    this.store.forEach(st => {
      result.push(st);
    });

    return result;
  }

  /**
   * replace console.log method
   * @param method
   * @param obj
   * @param argumentArray
   * @private
   */
  private replaceConsoleMethod(method, obj: any, argumentArray: any[]) {
    const info = this.getLogInfo(method);
    try {
      info.msg = [obj, ...argumentArray.map(arg => JSON.stringify(arg))];
      this.writeLog(info);
      if (method) {
        method.apply(
          console,
          info.type === "error" ? [obj, ...argumentArray, "\n\t\t at " + info.functionName + "@" + info.fileName + ",line:" + info.lineNumber + ",column:" + info.columnNumber]
                                : [obj, ...argumentArray]
        );
      }
    } catch (error) {
      //
    }
  }

  /**
   * undo console.* override
   * @private
   */
  private undoReplace() {
    window.console.log = this.logMethod;
    window.console.warn = this.warnMethod;
    window.console.info = this.infoMethod;
    window.console.debug = this.debugMethod;
    window.console.error = this.errorMethod;
  }

  /**
   * return browser info
   * @private
   */
  private getUAParserResult(): UAParser.IResult {
    const parser = new UAParser(navigator.userAgent);
    return parser.getResult();
  }

  /**
   * return an object
   * @param time
   * @param type
   * @param msg
   * @param fileName
   * @param functionName
   * @param lineNumber
   * @param columnNumber
   * @private
   */
  private getJsonRow(time: string,
                     type: string,
                     msg: string | string[],
                     fileName: string,
                     functionName: string,
                     lineNumber: number,
                     columnNumber: number): IConsoleLog {
    return {
      time: time,
      type: type,
      msg: msg,
      fileName: fileName,
      functionName: functionName,
      lineNumber: lineNumber,
      columnNumber: columnNumber
    };
  }

  /**
   * return an object representing a log
   * @private
   */
  private getFormattedLogs(): IConsoleLog[] {
    let result = this.getUAParserResult();
    let data: IConsoleLog[];

    if (result.os.name === "Mac OS" && navigator.maxTouchPoints > 1) {
      result.os.name = "iPad OS";
      result.os.version = "13.x";
    }

    const msg =
      result.browser.name + "|" + result.browser.version + "|" + result.os.name + "|" + result.os.version + "|" + result.engine.name;
    data = [
      this.getJsonRow(new Date().toISOString(), "info", msg, null, null, null, null)
    ];

    const logs = this.getLogs();
    logs.forEach(row => {
      data.push(this.getJsonRow(row.time, row.type, row.msg, row.fileName, row.functionName, row.lineNumber, row.columnNumber));
    });

    return data;
  }

  /**
   * remove a list of logs from the storage
   * @param keys
   * @private
   */
  private removeItems(keys: string[]) {
    keys.forEach(key => {
      this.getStorage().removeItem(key);
    });
  }

  /**
   * remove all the logs from the storage
   * @private
   */
  private clearLogs() {
    this.removeItems(this.logsToRemove);
    let search = this.logKeyPrefix;
    let keys = Object.keys(this.getStorage)
      .filter((key) => key.startsWith(search))
      .filter((key) => key !== this.logKeyCount);

    if (keys.length === 0) {
      this.getStorage().setItem(this.logKeyCount, JSON.stringify(0));
    }
  }

  /**
   * override the console.* functions
   */
  overrideConsole() {
    try {
      window.console.log = (obj, ...argumentArray) => { this.replaceConsoleMethod(this.logMethod, obj, argumentArray); };
      window.console.warn = (obj, ...argumentArray) => { this.replaceConsoleMethod(this.warnMethod, obj, argumentArray); };
      window.console.info = (obj, ...argumentArray) => { this.replaceConsoleMethod(this.infoMethod, obj, argumentArray); };
      window.console.debug = (obj, ...argumentArray) => { this.replaceConsoleMethod(this.debugMethod, obj, argumentArray); };
      window.console.error = (obj, ...argumentArray) => { this.replaceConsoleMethod(this.errorMethod, obj, argumentArray); };
    } catch (ex) {
      this.undoReplace();
      window.console.log("Log sending not available on this platform!");
    }
  }


  /**
   * write a log in the storage
   * @param info
   */
  writeLog(info) {
    let storage, chunksCount, nextKey;
    this.store.push(info);
    storage = this.getStorage();

    if (!storage) {
      return;
    }

    chunksCount = JSON.parse(storage.getItem(this.logKeyCount)) || 0;
    chunksCount = 1 + chunksCount;
    nextKey = this.logKeyPrefix + chunksCount;
    storage.setItem(nextKey, JSON.stringify(this.store));
    storage.setItem(this.logKeyCount, JSON.stringify(chunksCount));
    this.store = [];
  }

  /**
   * write web RTC status to session store...
   * @param stats the getStats() from webRTC Peer connection
   */
  writeWebRTCStats(stats)
  {
    // we convert stats to a webrtc internals style so they can be read by the tool
    // create a new call log here
    let storage = this.getStorage();
    let cachedCallStatsString: any = storage.getItem("WEB_RTC_CALL_STATS");
    let cachedCallStats = cachedCallStatsString ? JSON.parse(cachedCallStatsString) : {};
    
    if(!storage) {
      return;
    }

    if(!cachedCallStats.PeerConnections)
    {
      // create a new entry of call stats
      cachedCallStats.PeerConnections = {};
      // update user agent
      cachedCallStats.UserAgent = window.navigator.userAgent;
    }

    if(!cachedCallStats.PeerConnections[stats.peerRtcId])
    {
      // make peer id the peer rtcID ()
      cachedCallStats.PeerConnections[stats.peerRtcId] = {};
      // some parameters the tool needs.
      cachedCallStats.PeerConnections[stats.peerRtcId].url = window.location.origin;
      cachedCallStats.PeerConnections[stats.peerRtcId].updateLog = [];
      cachedCallStats.PeerConnections[stats.peerRtcId].stats = {};
    }

    // hold the timestamp for the stats
    let timestamp = stats.time;

    // for each entry find out if it already exists,
    if(stats.msg && timestamp)
    {
      stats.msg.forEach((dataEntry : any) =>
      {
        let statsEntry = dataEntry[1];

        // hold the type here
        let statsType = statsEntry.type;
        let id = statsEntry.id

        for(const key in statsEntry)
        {
          // ignore type id and timestamp.
          if(key !== "type" && key !== "timestamp" && key !=="id")
          {
            let statsKey = id + "-" + key;
            if(!cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey])
            {
              // create the entry, 
              cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey] = {};
              cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].startTime = timestamp;
              cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].statsType = statsType;
              cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].values = "[]";
            }

            // append the value to the cache.
            let tempJSON = JSON.parse(cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].values);
            tempJSON.push(statsEntry[key]);

            cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].values = 
              JSON.stringify(tempJSON);
            // update the end time
            cachedCallStats.PeerConnections[stats.peerRtcId].stats[statsKey].endTime = timestamp;
          }
        }
      });
    }

    storage.setItem("WEB_RTC_CALL_STATS", JSON.stringify(cachedCallStats));
  }

  /**
   * update users stats.
   */
  updateGetUserMedia(stream : MediaStream, rtcId : string)
  {
    // create a new call log here
    let storage = this.getStorage();
    let cachedCallStatsString: any = storage.getItem("WEB_RTC_CALL_STATS");
    let cachedCallStats = cachedCallStatsString ? JSON.parse(cachedCallStatsString) : {};
    
    if(!storage) {
      return;
    }

    if(!cachedCallStats.getUserMedia)
    {
      cachedCallStats.getUserMedia = [];
    }

    // for each track push this on... if it doesn't already exist
    stream.getTracks().forEach((track : MediaStreamTrack) =>
    {
      let dataEntry : any = {};
      dataEntry.request_type = "getUserMedia";
      dataEntry.stream_id = stream.id;
      dataEntry.timestamp = Date.now();
      dataEntry.request_td = LogUtil.getUserMediaRequestCount;
      dataEntry.rid = rtcId;
      dataEntry.pid = rtcId;
      dataEntry.origin = window.location.origin;

      if(track.kind === "audio")
      {
        dataEntry.audio_track_info = track.label;
      }
      else if(track.kind === "video")
      {
        dataEntry.video_track_info = track.label;
      }
      cachedCallStats.getUserMedia.push(dataEntry);
    })

    LogUtil.getUserMediaRequestCount++;
    storage.setItem("WEB_RTC_CALL_STATS", JSON.stringify(cachedCallStats));
  }

  /**
   * Send WEB RTC stats to the log server
   */
  sendWebRTCStats() : Promise<any> 
  {
    const browser = this.getUAParserResult().browser.name
      .toLowerCase()
      .replace(/\s+/g, "");

    let storage = this.getStorage();

    if(!storage) {
      return;
    }
    
    const data = storage.getItem("WEB_RTC_CALL_STATS");
    return this.sendDataToServer(data, "webrtc-stats");
  }

  /**
   * send the console logs to server
   */
  sendLogs(): Promise<any> {
    const data = this.getFormattedLogs();
    return this.sendDataToServer(data, "console-logs");
  }

  /**
   * Helper function to send log data to log server
   */
  private sendDataToServer(data : any, apiMethod : string) : Promise<any>
  {
    const browser = this.getUAParserResult().browser.name
    .toLowerCase()
    .replace(/\s+/g, "");

    const body = {
      clientId: this.endpointService.myEndpoint.rtcId,
      serverUrl: "https://" + window.location.hostname,
      instanceId: this.logsSettings.machineId,
      browser,
      data
    };

    const serverUrl = CollectLogsMode[this.logsSettings.mode] === CollectLogsMode.Server2Server ?
      "/s2s/" + apiMethod :
      this.logsSettings.serverUrl + "/c2s/" + apiMethod;
    return fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
      })
      .then((resp) => {
        this.clearLogs();
        console.log("Success:", resp);
        return Promise.resolve(resp);
      })
      .catch((error) => {
        console.error("Error:", error);
        throw Error(error);
      });
  }
}
