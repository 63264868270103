/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { IMenuItem } from "companion";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";
@Component({
  selector: "footer",
  templateUrl: "footer.template.html"
})
/**
 * footer view
 */
export class FooterComponent {
  /**
   * flag if footer position should be fixed
   */
  @Input() positionFixed: boolean = true;

  /**
   * flag if nav shown
   */
  @Input() isNavShown: boolean = true;

  /**
   * flag if helper icon should be shown
   */
  @Input() showHelper: boolean;

  /**
   * flag if it's in locked mode
   */
  @Input() isLocked: boolean;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  get copyright(): string {
    return this.localizationService.myLocalizationData.footer
      ? (this.localizationService.myLocalizationData.footer?.copyright || 'Copyright [CURRENT_YEAR]').replace(
          /\[CURRENT_YEAR\]/gim,
          new Date().getFullYear().toString()
        )
      : "";
  }

  get borderColor(): string {
    return this.localizationService.getValueByPath(".footer.borderColor");
  }

  get borderWidth(): string {
    return this.borderColor ? "5px" : "0px";
  }

  openMenu(menu: IMenuItem) {
    if (menu.url.toLowerCase().startsWith("[pdf]")) {
      Dispatcher.dispatch(ActionType.OpenPDFViewer, { url: menu.url.substr(5), autoCloseAfterSec: 5 * 60 });
    } else {
      window.open(menu.url);
    }
  }
}
