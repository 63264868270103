/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";

/**
 * Truncate a string to the given length and append prefix.
 * @param	length Text max length. Default 20.
 * @param	prefix to the begining of the string if truncted. Default ''.
 * @example Usage:
 * ```html
 * <p>
 *   {{ 'Hello world' | truncateLeft:5:'...' }}
 * </p>
 * <!-- Formats to: '...world' -->
 * ```
 */
@Pipe({ name: "truncateLeft" })
export class TruncateLeftPipe {
  transform(value: string, max: string = "20", prefix: string = ""): any {
    let length = parseInt(max, 10);

    if (value.length <= length) {
      return value;
    }

    return prefix + value.substring(value.length - length, value.length);
  }
}
