/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
const line_break_pattern: any = /\r?\n|\r/;
const line_break_string: string = "\r\n";
const SECONDARY_VIDEO_MAX_FS: number = 3840;
const PRIMARY_VIDEO_MAX_FS: number = 3840;
const SECONDARY_VIDEO_MAX_FR: number = 30;
const PRIMARY_VIDEO_MAX_FR: number = 60;
import { SdpTransform } from "./sdp-transform";
import { H264Util, IProfileLevelDetail } from "./h264";
import { VideoResolution } from "../settings/video-resolution";
import { Browser } from "./browser";
const MIN_AUDIO_BANDWIDTH: {
  [codec: string]: number;
} = { opus: 6, default: 64 };
const MIN_VIDEO_BANDWIDTH: number = 100;
const ACCEPTABLE_AUDIO_BANDWIDTH: {
  [codec: string]: number;
} = { opus: 40, default: 64 };
// const ACCEPTABLE_VIDEO_BANDWIDTH: number = 200;
const PREFERRED_AUDIO_BANDWIDTH: {
  [codec: string]: number;
} = { opus: 64, default: 64 };
const PREFERRED_VIDEO_BANDWIDTH: number = 500;
// If RTCP FB's don't include these items, remove the given URI from EXT MAP
const RTCP_FB_REQ_EXT = {
  "transport-cc" : ["http://www.webrtc.org/experiments/rtp-hdrext/abs-send-time", "http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01"]
};
// If the RTP map codec list includes these items but not the ones it requires, remove them from the RTP map.
const AUDIO_CODEC_REQ_CODEC = {
  "red": ['opus']
}
export class SDPUtil {
  /**
   * filter the sdp to allow valid codecs and candidates only
   * @param sdp: string - the original sdp
   * @param type: string - media type [audio | video]
   * @param invalidCodecs?: string[] - invalid codecs as they appear in SDP rtp map
   */
  static codecFilter(sdp: string, type: string, invalidCodecs?: string[], index?: number) {
    if (!invalidCodecs) {
      return sdp;
    }
    let sdpData = SdpTransform.parse(sdp);
    if (!sdpData.media || sdpData.media.length === 0) {
      return sdp;
    }

    let mediaSections = _.filter(sdpData.media, (mediaSection: any) => {
      return mediaSection.type === type;
    });
    if (index != null) {
      if (mediaSections[index] != null) {
        mediaSections = [mediaSections[index]];
      } else {
        return sdp;
      }
    }
    _.forEach(mediaSections, (mediaSection: any) => {
      let validCodecs = _.filter(_.map(mediaSection.rtp, "codec"), (codec: string) => {
        return !_.includes(invalidCodecs, codec);
      });

      if (mediaSection.rtp) {
        // Strip filtered RTPs
        mediaSection.rtp = _.filter(mediaSection.rtp, (rtp: any) => {
          return _.includes(validCodecs, rtp.codec);
        });

        // Strip dependent rtps, audio has a list, video has a list or no list...
        if (mediaSection.type === "audio") {
          let disallowedCodec = [];
          _.forEach(_.keys(AUDIO_CODEC_REQ_CODEC), (codecKey) => {
            if (!_.some(mediaSection.rtp, (mediaRtp) => {
              return _.includes(AUDIO_CODEC_REQ_CODEC[codecKey], mediaRtp.codec);
            })) {
              disallowedCodec = _.union(disallowedCodec,[codecKey]);
            }
          });
          if (disallowedCodec.length > 0) {
            console.log(`No codec requesting ${disallowedCodec}`);
            mediaSection.rtp = _.filter(mediaSection.rtp, (rtp: any) => {
              return !_.includes(disallowedCodec, rtp.codec);
            });
          }
        }
       
        let matchedRTPs: any[] = 
          _.filter(mediaSection.rtp, (rtp: any) => {
            return _.includes(validCodecs, rtp.codec);
          });
        if (mediaSection.rtcpFb) {
          mediaSection.rtcpFb = _.filter(mediaSection.rtcpFb, (rtcp: any) => {
            return _.includes(_.map(matchedRTPs, "payload"), rtcp.payload);
          });
        }
        if (mediaSection.fmtp) {
          mediaSection.fmtp = _.filter(mediaSection.fmtp, (fmtp: any) => {
            return _.includes(_.map(matchedRTPs, "payload"), fmtp.payload);
          });
        }
        mediaSection.payloads = _.map(matchedRTPs, "payload").join(" ");
      }

      if (mediaSection.ext) {
        let disallowedURI = [];
        _.forEach(_.keys(RTCP_FB_REQ_EXT), (rtcpFbKey) => {
          if (!Array.isArray(mediaSection.rtcpFb) || !mediaSection.rtcpFb.length ||
            !_.some(mediaSection.rtcpFb, (mediaRtcpFb) => {
              if(rtcpFbKey === mediaRtcpFb.type) {
                return true;
              }
            })
          ) {
            disallowedURI = _.union(disallowedURI, (RTCP_FB_REQ_EXT[rtcpFbKey]));
          }
        });

        if (disallowedURI.length > 0) {
          console.log(`No RTCP-FB requesting ${JSON.stringify(disallowedURI)}`);
          // Filter the extensions based on required rtcp-fb types
          mediaSection.ext = _.filter(mediaSection.ext, (ext: any) => {
            return !_.includes(disallowedURI, ext.uri);
          });
        }
      }
    });

    // cleanup stranded media sections
    sdpData.media = _.filter(sdpData.media, (mediaSection: any) => {
        return !((mediaSection?.type === "audio" || mediaSection?.type === "video") && mediaSection?.rtp?.length === 0);
    });
  
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  static stripDanglingFmtpFromRtpmap(sdp: string): string {
    let sdpData = SdpTransform.parse(sdp);
    if (!sdpData.media || sdpData.media.length === 0) {
      return sdp;
    }
    let mediaSections = sdpData.media;
    _.forEach(mediaSections, (mediaSection: any) => {
      
      // Check fmtpMap for apt entries that don't have an rtpMap item.
      // Remove them from fmtpMap and rtpMap (payload match)
      mediaSection.fmtp = _.filter(mediaSection.fmtp, (fmtp: any) => {
        console.log(`Checking ${JSON.stringify(fmtp)}`);
        if (fmtp.config.includes("apt=")) {
          let re = /^.*apt=(\d+)([;])?/u;
          let result = re.exec(fmtp.config);
          console.log(`REGEX RESULT ${JSON.stringify(result)}`);
          let refRtpPayload = parseInt(result[1]);
          console.log(`Searching for rtp pt ${refRtpPayload} in ${JSON.stringify(_.map(mediaSection.rtp, "payload"))}`);
          if (_.includes(_.map(mediaSection.rtp, "payload"), refRtpPayload)) {
            console.log("Is OK");
            return true;
          } else {
            console.log("Must remove RTP entry for removing FMTP Entry")
            // convert payloads lids to array for mgmt.
            let payloadsList = mediaSection.payloads.split(' ');
            // Strip dangling.
            mediaSection.rtp = _.filter(mediaSection.rtp, (rtp: any) => {
              console.log(`Checking ${JSON.stringify(rtp)} is not for ${fmtp.payload}`);
              if (rtp.payload != fmtp.payload) {
                console.log("is OK");
                return true;
              } else {
                console.log(`Removing RTP entry ${JSON.stringify(rtp)}`);
                // Strip this rtp item from the payloads list. (returns removed items, updates input)
                _.remove(payloadsList, (n) => {
                  return n === rtp.payload.toString();
                });
              }
            })
            // put payloads back into mediaSection
            mediaSection.payloads = payloadsList.join(' ');
            return false;
          }
        } else { return true; }
      })
    });
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * Filter unused candidates from the OFFER/invite to prevent the message from getting too large.
   * NOTE component 1 = RTP || (RTP + RTCP MUX), 2 = RTCP
   * (if rtcp-mux is supported) https://developer.mozilla.org/en-US/docs/Web/API/RTCIceCandidate/component
   * @param sdp: string - the original sdp
   */
  static candidateFilter(sdp: string, relayOnly?: boolean, ipv4Only?: boolean, rtpOnly?: boolean) {
    let sdpData = SdpTransform.parse(sdp);

    if (sdp.search("a=rtcp-mux") > 0) {
      _.forEach(sdpData.media, (mediaSection: any) => {
        if (mediaSection.candidates) {
          mediaSection.candidates = _.filter(mediaSection.candidates, (candidate: any) => {
            console.log(`checking candidate for approval ${JSON.stringify(candidate)}`);
            let keep = (!rtpOnly || candidate.component === 1) &&
                      (!ipv4Only || this.isValidIPV4Address(candidate.ip)) &&
                      (!relayOnly || candidate.type === "relay") &&
                      (candidate.transport != "tcp");
            if (!keep) {
              console.log(`removing candidate ${JSON.stringify(candidate)}`);
            }
            return (keep);
          });
        }
      });
    }

    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * fix H264 fmtp line to support MCU calls
   * mainly adjusts the profile level of h264
   * @param sdp: string - the original sdp
   */
  static H264SdpFmtpFixerForEvergreen(
    sdp: string,
    resolution: VideoResolution,
    framerate: number,
    index: number = 0
  ): string {
    let validCodecs = ["H264"];
    let sdpData = SdpTransform.parse(sdp);
    if (!sdpData.media || sdpData.media.length === 0) {
      return sdp;
    }
    let defaultProfileLevelId: string = H264Util.getProfileLevelIdByResolutionAndFramerate(resolution, framerate);
    let defaultAsymmetryAllowed: number = 1;
    // packetization mode 0 is better for broadcast in Evg 5.10
    // Safari and Android requires packetization mode 1
    let defaultPacketizationMode: number = 0;
    if (Browser.isMobile() || Browser.whichBrowser() === "Safari") {
      defaultPacketizationMode = 1;
    }
    let videoSections = _.filter(sdpData.media, (mediaSection: any) => {
      return mediaSection.type === "video";
    });
    let videoSection = videoSections[index];
    if (!videoSection) {
      return sdp;
    }
    if (videoSection.rtp) {
      let matchedRtps = _.filter(videoSection.rtp, (rtp: any) => {
        return _.includes(validCodecs, rtp.codec);
      });
      let matchedPayloads = _.map(matchedRtps, (rtp: any) => {
        return rtp.payload;
      });
      videoSection.fmtp = videoSection.fmtp || [];
      _.forEach(matchedPayloads, (payload: any) => {
        let matchedFmtp = _.find(videoSection.fmtp, (fmtp: any) => {
          return fmtp.payload === payload;
        });
        if (matchedFmtp) {
          let m = /profile-level-id=(?<levelId>[0-9a-fA-F]{6})/i.exec(matchedFmtp.config)
          // Capture the matched profile level ID so we can use it later.
          let groups;
          if (m) {
            groups = m['groups'];
           }
          if (!m) {
            matchedFmtp.config = matchedFmtp.config + ";profile-level-id=" + defaultProfileLevelId;
          } else {
            // Splice in the level info into the profile information in the case
            // were a profile-level-id was specified.
            // This effectively overwrites the last 2 characters with the ones identified for our constraint.
            matchedFmtp.config = matchedFmtp.config.replace(/profile-level-id=[0-9a-fA-F]{6}/i,
              "profile-level-id=" + groups.levelId.slice(0,4) + defaultProfileLevelId.slice(-2)
              );
          }
          if (!/packetization-mode=[0-1]{1}/i.exec(matchedFmtp.config)) {
            matchedFmtp.config = matchedFmtp.config + ";packetization-mode=" + defaultPacketizationMode;
          } else if (Browser.isMobile() || Browser.whichBrowser() === "Safari") {
            // Safari and Android requires packetization mode 1
            matchedFmtp.config = matchedFmtp.config.replace(
              /packetization-mode=[0-1]{1}/i,
              "packetization-mode=" + defaultPacketizationMode
            );
          }
        } else {
          videoSection.fmtp.push({
            config:
              "level-asymmetry-allowed=" +
              defaultAsymmetryAllowed +
              ";packetization-mode=" +
              defaultPacketizationMode +
              ";profile-level-id=" +
              defaultProfileLevelId,
            payload: payload
          });
        }
      });
    }
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * fix H264 fmtp line from Evergreen to make browser happy
   * this mainly adjusts the profile level of h264
   * @param sdp: string - the original sdp
   */
  static H264SdpFmtpCleanerFromEvergreen(
    sdp: string,
    resolution: VideoResolution,
    framerate: number,
    index: number = 0
  ): string {
    let validCodecs = ["H264"];
    let sdpData = SdpTransform.parse(sdp);
    if (!sdpData.media || sdpData.media.length === 0) {
      return sdp;
    }
    let defaultProfileLevelId: string = H264Util.getProfileLevelIdByResolutionAndFramerate(resolution, framerate);
    let defaultAsymmetryAllowed: number = 1;
    // packetization mode 0 is better for broadcast in Evg 5.10
    // Safari and Android requires packetization mode 1
    let defaultPacketizationMode: number = 0;
    if (Browser.isMobile() || Browser.whichBrowser() === "Safari") {
      defaultPacketizationMode = 1;
    }
    let videoSections = _.filter(sdpData.media, (mediaSection: any) => {
      return mediaSection.type === "video";
    });
    let videoSection = videoSections[index];
    if (!videoSection) {
      return sdp;
    }
    if (videoSection.rtp) {
      let matchedRtps = _.filter(videoSection.rtp, (rtp: any) => {
        return _.includes(validCodecs, rtp.codec);
      });
      let matchedPayloads = _.map(matchedRtps, (rtp: any) => {
        return rtp.payload;
      });
      videoSection.fmtp = videoSection.fmtp || [];
      _.forEach(matchedPayloads, (payload: any) => {
        let matchedFmtp = _.find(videoSection.fmtp, (fmtp: any) => {
          return fmtp.payload === payload;
        });
        if (matchedFmtp) {
          let m = /profile-level-id=(?<levelId>[0-9a-fA-F]{6})/i.exec(matchedFmtp.config)
          // Capture the matched profile level ID so we can use it later.
          let groups;
          if (m) {
            groups = m['groups'];
           }
          if (!m) {
            matchedFmtp.config = matchedFmtp.config + ";profile-level-id=" + defaultProfileLevelId;
          } else {
            // Splice in the level info into the profile information in the case
            // were a profile-level-id was specified.
            // This effectively overwrites the last 2 characters witht he ones identified for our constraint.
            matchedFmtp.config = matchedFmtp.config.replace(/profile-level-id=[0-9a-fA-F]{6}/i,
              "profile-level-id=" + defaultProfileLevelId.slice(0,4) + groups.levelId.slice(4,6)
              );
          }
          if (!/packetization-mode=[0-1]{1}/i.exec(matchedFmtp.config)) {
            matchedFmtp.config = matchedFmtp.config + ";packetization-mode=" + defaultPacketizationMode;
          } else if (Browser.isMobile() || Browser.whichBrowser() === "Safari") {
            // Safari and Android requires packetization mode 1
            matchedFmtp.config = matchedFmtp.config.replace(
              /packetization-mode=[0-1]{1}/i,
              "packetization-mode=" + defaultPacketizationMode
            );
          }
        } else {
          videoSection.fmtp.push({
            config:
              "level-asymmetry-allowed=" +
              defaultAsymmetryAllowed +
              ";packetization-mode=" +
              defaultPacketizationMode +
              ";profile-level-id=" +
              defaultProfileLevelId,
            payload: payload
          });
        }
      });
    }
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * remove an exact match line
   * @param sdp: string - the original sdp
   */
  static removeLine(sdp: string, line: string): string {
    let sdpLines: string[] = sdp.split(line_break_pattern);
    _.remove(sdpLines, (sdpLine: string) => {
      return sdpLine === line;
    });
    let newSdp: string = this.buildSdpString(sdpLines);
    return newSdp;
  }

  /**
   * copy fingerprint to media sections
   * @param sdp: string - the original sdp
   */
  static copyFingerprintToMediaSections(sdp: string): string {
    let sdpData = SdpTransform.parse(sdp);
    if (sdpData.fingerprint && sdpData.media && sdpData.media.length > 0) {
      _.forEach(sdpData.media, (mediaSection: any) => {
        mediaSection.fingerprint = sdpData.fingerprint;
      });
    } else {
      return sdp;
    }
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * filter the sdp to set max bandwidth only
   * @param sdp: string - the original sdp
   */
  static videoBandwidthSdpFilterOnVideoSection(
    sdp: string,
    bandwidth: number,
    index: number = 0
  ): string {
    if (!bandwidth) {
      return sdp;
    }
    let sdpData = SdpTransform.parse(sdp);
    if (index === 0) {
      sdpData.bandwidth = [{ type: "CT", limit: bandwidth }];
    }
    let mediaSection = _.filter(sdpData.media, (m: any) => {
      return m.type === "video";
    })[index];
    if (!mediaSection) {
      return sdp;
    }
    mediaSection.bandwidth = [];
    mediaSection.bandwidth.push({ type: "AS", limit: bandwidth });
    mediaSection.bandwidth.push({ type: "TIAS", limit: bandwidth * 1000 });
    if (mediaSection.rtp) {
      let allPayloads = _.map(mediaSection.rtp, "payload");
      mediaSection.fmtp = mediaSection.fmtp || [];
      _.forEach(allPayloads, (payload: any) => {
        let matchedFmtp = _.find(mediaSection.fmtp, (fmtp: any) => {
          return fmtp.payload === payload;
        });

        if (matchedFmtp) {
          if (/x-google-start-bitrate/i.exec(matchedFmtp.config)) {
            matchedFmtp.config = matchedFmtp.config.replace(
              /(x-google-start-bitrate=)\d*/i, "x-google-start-bitrate=" + bandwidth);
          }
          else if (!/x-google-start-bitrate/i.exec(matchedFmtp.config) && !/apt=/i.exec(matchedFmtp.config)) {
            matchedFmtp.config += ";x-google-start-bitrate=" + bandwidth;
          }
        }
      });
    }
    
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * build new sdp
   * @param sdpLines: string[] - array of each line in the sdp
   */
  static buildSdpString(sdpLines: string[]): string {
    var newSdp: string = "";

    for (var i = 0; i < sdpLines.length; ++i) {
      if (!sdpLines[i] || sdpLines[i].length === 0) {
        continue;
      }
      newSdp += sdpLines[i] + line_break_string;
    }
    return newSdp;
  }
  /**
   * check if video bandwidth is over limit according to the sdp
   */
  static isBandwidthOverLimitOnVideoSection(sdp: string, currentBandwidth: number, index: number = 0): boolean {
    let result: boolean = false;
    let sdpBandwidth: number = this.getAcceptableBandwidthOnVideoSection(sdp, index);
    if (sdpBandwidth) {
      result = sdpBandwidth < currentBandwidth;
    }
    return result;
  }

  /**
   * get acceptable bandwidth according to the sdp
   */
  static getAcceptableBandwidthOnVideoSection(sdp: string, index: number = 0): number {
    let result: number;
    let sdpData = SdpTransform.parse(sdp);
    if (sdpData.bandwidth && sdpData.bandwidth[0] && sdpData.bandwidth[0].type === "CT") {
      result = sdpData.bandwidth[0].limit;
    } else {
      let mediaSection = _.filter(sdpData.media, (section: any) => {
        return section.type === "video";
      })[index];
      if (mediaSection && mediaSection.bandwidth && mediaSection.bandwidth.length > 0) {
        let sdpBandwidthLine = mediaSection.bandwidth[0];
        result = sdpBandwidthLine.type === "TIAS" ? sdpBandwidthLine.limit / 1000 : sdpBandwidthLine.limit;
      }
    }
    return result;
  }

  /**
   * get acceptable frame rate according to the sdp
   */
  static getAcceptableFramerate(
    sdp: string,
    defaultFramerate: number = 30,
    videoResolution?: VideoResolution,
    codecs: string[] = ["H264", "VP8"],
    index: number = 0
  ): number {
    let maxFramerate: number = defaultFramerate;
    _.forEach(codecs, (codec: string) => {
      let maxFr: number, maxMbps: number, maxMbpsFr: number;
      let configLine: string = this.getConfigLine(sdp, "video", codec, index);
      let matchedFrPattern: string[] = /max-fr=(\d+)/i.exec(configLine);
      if (matchedFrPattern) {
        maxFr = Number.parseInt(matchedFrPattern[1], 10);
      }
      if (videoResolution) {
        if (codec && codec.toUpperCase() === "H264") {
          let matchedProfileIdPattern: string[] = /profile-level-id=(\S{6})/i.exec(configLine);
          if (matchedProfileIdPattern) {
            let profileId = matchedProfileIdPattern[1];
            let profileLevelDetail: IProfileLevelDetail = H264Util.getProfileLevelDetail(profileId);
            maxMbps = profileLevelDetail.maxBlocksPerSec;
          }
        }
        let matchedMbpsPattern: string[] = /max-mbps=(\d+)/i.exec(configLine);
        if (matchedMbpsPattern) {
          maxMbps = Number.parseInt(matchedMbpsPattern[1], 10);
        }
        let blocks: number = (videoResolution.width * videoResolution.height) / (16 * 16);
        maxMbpsFr = maxMbps / blocks;
      }
      maxFramerate = _.min([maxFr, maxMbpsFr, maxFramerate]);
    });
    return maxFramerate;
  }

  /**
   * check if frame rate is over limit according to the sdp
   */
  static isFramerateOverLimit(
    sdp: string,
    framerate: number,
    videoResolution?: VideoResolution,
    codecs: string[] = ["H264", "VP8"],
    index: number = 0
  ): boolean {
    let maxFr: number, maxMbps: number, maxMbpsFr: number, maxFramerate: number;
    return _.some(codecs, (codec: string) => {
      let configLine: string = this.getConfigLine(sdp, "video", codec, index);
      let matchedFrPattern: string[] = /max-fr=(\d+)/i.exec(configLine);
      if (matchedFrPattern) {
        maxFr = Number.parseInt(matchedFrPattern[1], 10);
      }
      if (videoResolution) {
        let blocks: number = (videoResolution.width * videoResolution.height) / (16 * 16);
        if (codec && codec.toUpperCase() === "H264") {
          let matchedProfileIdPattern: string[] = /profile-level-id=(\S{6})/i.exec(configLine);
          if (matchedProfileIdPattern) {
            let profileId = matchedProfileIdPattern[1];
            let profileLevelDetail: IProfileLevelDetail = H264Util.getProfileLevelDetail(profileId);
            maxMbps = profileLevelDetail.maxBlocksPerSec;
          }
        }
        let matchedMbpsPattern: string[] = /max-mbps=(\d+)/i.exec(configLine);
        if (matchedMbpsPattern) {
          maxMbps = Number.parseInt(matchedMbpsPattern[1], 10);
        }
        maxMbpsFr = maxMbps / blocks;
      }
      maxFramerate = _.min([maxFr, maxMbpsFr]);
      return maxFramerate && framerate > maxFramerate;
    });
  }

  /**
   * check if video resolution is oversized according to the sdp
   */
  static isResolutionOverLimit(
    sdp: string,
    videoResolution: VideoResolution,
    MinFrameRate: number = 3,
    codecs: string[] = ["H264", "VP8"],
    index: number = 0
  ): boolean {
    let blocks: number = (videoResolution.width * videoResolution.height) / (16 * 16);
    let blocksPerSec: number = blocks * MinFrameRate;
    let maxFs: number, maxMbps: number;
    return _.some(codecs, (codec: string) => {
      let configLine: string = this.getConfigLine(sdp, "video", codec, index);
      if (codec && codec.toUpperCase() === "H264") {
        let matchedProfileIdPattern: string[] = /profile-level-id=(\S{6})/i.exec(configLine);
        if (matchedProfileIdPattern) {
          let profileId = matchedProfileIdPattern[1];
          let profileLevelDetail: IProfileLevelDetail = H264Util.getProfileLevelDetail(profileId);
          maxFs = profileLevelDetail.maxBlocks;
          maxMbps = profileLevelDetail.maxBlocksPerSec;
        }
      }
      let matchedFsPattern: string[] = /max-fs=(\d+)/i.exec(configLine);
      if (matchedFsPattern) {
        maxFs = Number.parseInt(matchedFsPattern[1], 10);
      }
      let matchedMbpsPattern: string[] = /max-mbps=(\d+)/i.exec(configLine);
      if (matchedMbpsPattern) {
        maxMbps = Number.parseInt(matchedMbpsPattern[1], 10);
      }
      return blocks > maxFs || blocksPerSec > maxMbps;
    });
  }

  private static getConfigLine(sdp: string, type: string, codec: string, index: number = 0): string {
    let configLine: string = "";
    let sdpData = SdpTransform.parse(sdp);
    sdpData.media = sdpData.media || [];
    let mediaSections = _.filter(sdpData.media, (mediaSectionItr: any) => {
      return mediaSectionItr.type === type;
    });
    let mediaSection = mediaSections[index];
    if (mediaSection && mediaSection.rtp) {
      let matchedRtps = _.filter(mediaSection.rtp, (rtp: any) => {
        return rtp.codec === codec;
      });
      let matchedPayloads = _.map(matchedRtps, (rtp: any) => {
        return rtp.payload;
      });
      mediaSection.fmtp = mediaSection.fmtp || [];
      _.forEach(matchedPayloads, (payload: any) => {
        let matchedFmtp = _.find(mediaSection.fmtp, (fmtp: any) => {
          return fmtp.payload === payload;
        });
        if (matchedFmtp) {
          configLine = matchedFmtp.config;
        }
      });
    }
    return configLine;
  }

  /**
   * get acceptable video resolutions according to the sdp
   */
  static getAcceptableResolutions(
    sdp: string,
    videoResolutionOptions: VideoResolution[] = VideoResolution.systemDefaultResolutions,
    MinFrameRate: number = 3,
    index: number = 0
  ): VideoResolution[] {
    let result: VideoResolution[] = videoResolutionOptions;
    result = _.filter(videoResolutionOptions, (videoResolution: VideoResolution) => {
      return !this.isResolutionOverLimit(sdp, videoResolution, MinFrameRate, undefined, index);
    });
    return result;
  }

  /**
   * filter to support experiment feature unified plan renegotiation
   * @param sdp: string - the original sdp
   */
  static unifiedPlanSdpFilterForRole(sdp: string, otherSdp: string, type: string): string {
    let sdpData = SdpTransform.parse(sdp);
    let sslRole: string;
    if (type === "offer") {
      sslRole = "actpass";
    } else {
      if (otherSdp) {
        let otherSdpData = SdpTransform.parse(otherSdp);
        if (otherSdpData.media && otherSdpData.media.length > 0) {
          switch (otherSdpData.media[0].setup) {
            case "active":
              sslRole = "passive";
              break;
            case "passive":
              sslRole = "active";
              break;
            case "actpass":
              sslRole = null;
              break; // don't change
            case "holdconn":
              sslRole = "holdconn";
              break;
          }
        }
      } else {
        sslRole = "active";
      }
    }
    if (sslRole) {
      _.forEach(sdpData.media, (mediaSection: any) => {
        if (mediaSection.setup && sslRole) {
          mediaSection.setup = sslRole;
        }
      });
    }
    let newSdp: string = SdpTransform.write(sdpData);

    return newSdp;
  }

  /**
   * filter to support experiment feature unified plan for mid value and bundle value
   * @param sdp: string - the original sdp
   */
  static unifiedPlanSdpFilterForMidAndBundle(sdp: string, mediaIds?: number[]): string {
    let sdpData = SdpTransform.parse(sdp);
    if (_.some(sdpData.media, (m) => Number.isNaN(m.mid))) {
      return sdp;
    }
    let mids: string = "";
    _.forEach(sdpData.media, (mediaSection: any, index: number) => {
      if (mediaIds) {
        mediaSection.mid = mediaIds[index];
      } else {
        mediaSection.mid = index;
      }
      mids += " " + mediaSection.mid;
    });
    if (sdpData.groups && sdpData.groups[0] && sdpData.groups[0].type === "BUNDLE") {
      sdpData.groups[0].mids = mids.substr(1);
    }
    let newSdp: string = SdpTransform.write(sdpData);

    return newSdp;
  }

  /**
   * limit secondary video sdp to send max frame size 3840
   */
  static limitSecondaryVideoFrameSize(sdp: string): string {
    let validCodecs = ["H264", "VP8"];
    let sdpData = SdpTransform.parse(sdp);
    if (!sdpData.media || sdpData.media.length === 0) {
      return sdp;
    }
    let videoSections = _.filter(sdpData.media, (mediaSection: any) => {
      return mediaSection.type === "video";
    });
    let secondaryVideoSection = videoSections[1];
    if (secondaryVideoSection && secondaryVideoSection.rtp) {
      let matchedRtps = _.filter(secondaryVideoSection.rtp, (rtp: any) => {
        return _.includes(validCodecs, rtp.codec);
      });
      let matchedPayloads = _.map(matchedRtps, (rtp: any) => {
        return rtp.payload;
      });
      secondaryVideoSection.fmtp = secondaryVideoSection.fmtp || [];
      _.forEach(matchedPayloads, (payload: any) => {
        let matchedFmtp = _.find(secondaryVideoSection.fmtp, (fmtp: any) => {
          return fmtp.payload === payload;
        });
        if (matchedFmtp) {
          if (/max-fs=\d*/i.exec(matchedFmtp.config)) {
            matchedFmtp.config = matchedFmtp.config.replace(/max-fs=\d*/i, "max-fs=" + SECONDARY_VIDEO_MAX_FS);
          } else {
            matchedFmtp.config = matchedFmtp.config + ";max-fs=" + SECONDARY_VIDEO_MAX_FS;
          }
        } else {
          secondaryVideoSection.fmtp.push({
            config: "max-fs=" + SECONDARY_VIDEO_MAX_FS,
            payload: payload
          });
        }
      });
    }
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }

  /**
   * change direction (sendonly, recvonly, sendrecv, inactive) of a media
   */
  static updateDirection(sdp: string, mediaType: string, direction: string, index: number = 0): string {
    let sdpData = SdpTransform.parse(sdp);
    let mediaSections = _.filter(sdpData.media, (m: any) => {
      return m.type === mediaType;
    });
    let mediaSection = mediaSections[index];
    if (!mediaSection) {
      return sdp;
    }
    mediaSection.direction = direction;
    let newSdp: string = SdpTransform.write(sdpData);

    return newSdp;
  }

  /**
   * rearrange media sections in sdp
   */
  static rearrangeMediaSections(sdp: string, sortOrder: {mediaType: string[], mediaId: number[]}): string {
    let sdpData = SdpTransform.parse(sdp);
    let order = _.clone(sortOrder.mediaType);
    sdpData.media = _.orderBy(sdpData.media, (m: any) => {
      let index = _.indexOf(order, m.type);
      order[index] = "taken";
      return index;
    });
    let newSdp: string = SdpTransform.write(sdpData);
    newSdp = this.unifiedPlanSdpFilterForMidAndBundle(newSdp, sortOrder["mediaId"]);
    return newSdp;
  }

  /**
   * count video channels in sdp
   */
  static countVideoChannelsInSdp(sdp: string): number {
    let sdpData = SdpTransform.parse(sdp);
    let videoSections = _.filter(sdpData.media, (m: any) => {
      return m.type === "video";
    });
    return videoSections.length;
  }

  /**
   * patch missing video sections on sdp
   */
  static patchMissingVideoSectionsOnSdp(sdp: any, missingVideoSectionsCount: number): string {
    let sdpData = SdpTransform.parse(sdp);
    for (let i = 0; i < missingVideoSectionsCount; ++i) {
      let cloneOfVideoChannel: any = {
        type: "video",
        setup: sdpData.media[0].setup,
        port: 0,
        protocol: sdpData.media[0].protocol,
        direction: "disabled",
        connection: sdpData.media[0].connection
      };
      sdpData.media.push(cloneOfVideoChannel);
    }
    let newSdp: string = SdpTransform.write(sdpData);
    newSdp = this.unifiedPlanSdpFilterForMidAndBundle(newSdp);
    return newSdp;
  }

  /**
   * check if ip is valid ipv4
   */
  static isValidIPV4Address(address: string): boolean {
    if (!address) {
      return false;
    }
    if (
      // tslint:disable-next-line:max-line-length
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        address
      )
    ) {
      return true;
    }
    return false;
  }

  /**
   * assign content type to each media section
   */
  static assignContentTypeToMediaSections(sdp: string, pc: any, mediaStream: MediaStream): string {
    let trackIdToContentTypes = {};
    if (mediaStream.getAudioTracks()[0]) {
      trackIdToContentTypes[mediaStream.getAudioTracks()[0].id] = "audio";
    }
    if (mediaStream.getVideoTracks()[0]) {
      trackIdToContentTypes[mediaStream.getVideoTracks()[0].id] = "main";
    }
    if (mediaStream.getVideoTracks()[1]) {
      trackIdToContentTypes[mediaStream.getVideoTracks()[1].id] = "slides";
    }
    let midToContentType = _.fromPairs(_.map(pc.getTransceivers(), (t) => [t.mid, trackIdToContentTypes[t.sender.track.id]]));
    let sdpData = SdpTransform.parse(sdp);
    _.forEach(sdpData.media, (m) => {
      m.invalid = m.invalid || [];
      m.invalid.push({value: `content:${midToContentType[m.mid]}`});
    });
    let newSdp: string = SdpTransform.write(sdpData);
    return newSdp;
  }
}
