/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { DeviceInfo } from "../settings/device-info";
import { IUser } from "../user/user.interface";
import { IDeviceService } from "./device.service.interface";
import { VolumeMeter } from "../util/volume-meter";
import { UserService } from "../user/user.service";
import { IRTCClient } from "./rtc.client.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { EasyRTCService } from "./rtc.service.easy";

const MAX_RETRY: number = 3;
/**
 * device methods delegate
 */
export class DeviceService implements IDeviceService {
  private static sharedInstance: DeviceService;
  /**
   * microphone options
   */
  micOptions: Array<DeviceInfo> = [];

  /**
   * camera options
   */
  cameraOptions: Array<DeviceInfo> = [];

  /**
   * speaker options
   */
  speakerOptions: Array<DeviceInfo> = [];

  /**
   * microphone volume meter
   */
  micVolumeMeter: VolumeMeter;

  /**
   * number of times of retry
   */
  retryNumbers: number = 0;

  rtcClient: IRTCClient;

  /**
   * get shared singleton object
   */
  static getSharedInstance(): IDeviceService {
    if (!DeviceService.sharedInstance) {
      DeviceService.sharedInstance = new DeviceService();
    }
    return DeviceService.sharedInstance;
  }

  constructor() {
    this.rtcClient = EasyRTCService.getSharedInstance().rtcClient;
  }

  /**
   * Get all available device information
   */
  getDevices(maxTry: number = MAX_RETRY): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices
        .enumerateDevices()
        .then((mediaDevices: MediaDeviceInfo[]) => { return this.processDeviceInfo(mediaDevices) })
        .then(() => { return this.getDefaultDeviceSelection(); })
        .then(resolve)
        .catch((error: Error) => {
          return this.gotDevicesFailure(error, maxTry);
        })
        .then(resolve)
        .catch(reject);
      } else {
        reject(new Error("Not Support navigator.mediaDevices.enumerateDevices"));
      }
    });
  }

  /**
   * handle get media devices error
   */
  private gotDevicesFailure(error: any, maxTry: number = MAX_RETRY): Promise<void> {
    this.rtcClient.alertHandler("got devices failed: ", error ? error.message : undefined);
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      if (this.retryNumbers >= maxTry) {
        this.retryNumbers = 0;
        reject(new Error("GET_DEVICE_FAIL"));
      } else {
        let getDeviceTryTimer = setTimeout(() => {
          clearTimeout(getDeviceTryTimer);
          this.retryNumbers++;
          this.getDevices(maxTry)
          .then(() => {
            this.retryNumbers = 0;
            resolve();
          })
          .catch(reject);
        }, 3000);
      }
    });
  }

  /**
   * handler on devices info received event
   */
  private processDeviceInfo(deviceInfos: Array<DeviceInfo>) {
    for (var i = 0; i < deviceInfos.length; ++i) {
      var deviceInfo = deviceInfos[i];
      if (!deviceInfo || !deviceInfo.label || deviceInfo.label.length === 0) {
        continue;
      }
      switch (deviceInfo.kind) {
        case "audioinput":
          if (
            !_.find(this.micOptions, (mic: DeviceInfo) => {
              return mic.deviceId === deviceInfo.deviceId;
            })
          ) {
            this.micOptions.push(deviceInfo);
          }
          break;
        case "videoinput":
          if (
            !_.find(this.cameraOptions, (camera: DeviceInfo) => {
              return camera.deviceId === deviceInfo.deviceId;
            })
          ) {
            this.cameraOptions.push(deviceInfo);
          }
          break;
        case "audiooutput":
          if (
            !_.find(this.speakerOptions, (speaker: DeviceInfo) => {
              return speaker.deviceId === deviceInfo.deviceId;
            })
          ) {
            this.speakerOptions.push(deviceInfo);
          }
          break;
        default:
          break;
      }
    }
    // Firefox doesn't provide speaker device info
    // but it use the PC default speaker
    // adding Default to the list in order to support self-tester feature.
    if (this.speakerOptions.length === 0) {
      this.speakerOptions.push(new DeviceInfo("default", "Default", "audiooutput"));
    }

    if (this.micOptions.length > 0 && !this.rtcClient.selectedMicOption) {
      this.rtcClient.selectedMicOption = this.micOptions[0];
      EasyRTCService.getSharedInstance().setAudioSource(this.rtcClient.selectedMicOption.deviceId)
        .catch((error) => { 
          console.log("Failed to set default Microphone! deviceId:", this.rtcClient.selectedMicOption.deviceId, "error:", error);
        });
    }
    if (this.cameraOptions.length > 0) {
      this.rtcClient.selectedPrimaryCameraOption = this.rtcClient.selectedPrimaryCameraOption || this.cameraOptions[0];
    }
    if (this.cameraOptions.length > 1 && this.rtcClient.secondaryCameraEnabled) {
      this.rtcClient.selectedSecondaryCameraOption = this.rtcClient.selectedSecondaryCameraOption || this.cameraOptions[1];
    }
    if (this.speakerOptions.length > 0 && !this.rtcClient.selectedSpeakerOption) {
      this.rtcClient.selectedSpeakerOption = this.speakerOptions[0];
    }

    if (!this.rtcClient.selectedMicOption && !this.rtcClient.selectedPrimaryCameraOption && !this.rtcClient.selectedSpeakerOption) {
      throw new Error("get devices failed");
    }
    return;
  }

  /**
   * select new mic
   * @param device: DeviceInfo - new device info
   */
  selectMicrophone(device: DeviceInfo): void {
    if (device) {
      this.rtcClient.selectedMicOption = device;
      EasyRTCService.getSharedInstance().setAudioSource(this.rtcClient.selectedMicOption.deviceId)
      .catch((error)=>{console.log("Failed to selectMicrophone: ", error)});
    }
  }

  /**
   * select new camera
   * @param device: DeviceInfo - new device info
   */
  selectPrimaryCamera(device: DeviceInfo, updateVideoSource: boolean = true): void {
    if (device) {
      const cameraRotations = this.getCameraRotations();
      const cameraRotation = cameraRotations[device.deviceId];

      this.rtcClient.selectedPrimaryCameraOption = device;
      this.rtcClient.selectedPrimaryCameraRotation = cameraRotation;
      if (updateVideoSource) {
        this.sendCameraRotation(cameraRotations[device.deviceId]);
        EasyRTCService.getSharedInstance().updateVideoSource(true)
        .catch((error)=>{console.log("Failed to selectPrimaryCamera: ", error)});;
      }
    }
  }

  /**
   * send camera rotation
   * @param degree
   */
  sendCameraRotation(degree: string) {
    const endpointService = EndpointService.getSharedInstance();
    endpointService.myEndpoint.cameraRotation = degree;
    endpointService.sendEndpointUpdate();
  }

  /**
   * save camera rotations info
   * @param cameraRotations - Info on cameras rotation
   */
  saveCameraRotations(cameraRotations: { [cameraId: string]: string }): void {
    localStorage.setItem("cameraInfos", JSON.stringify(cameraRotations));
  }

  /**
   * get camera rotations info
   */
  getCameraRotations(): { [cameraId: string]: string } {
    return JSON.parse(localStorage.getItem("cameraInfos")) || {};
  }

  /**
   * select secondary camera
   * @param device: DeviceInfo - device info
   */
  selectSecondaryCamera(device: DeviceInfo, updateVideoSource: boolean = true): void {
    if (device) {
      this.rtcClient.selectedSecondaryCameraOption = device;
      if (updateVideoSource) {
        EasyRTCService.getSharedInstance().updateVideoSource(false)
        .catch((error)=>{console.log("Failed to selectSecondaryCamera: ", error)});
      }
    }
  }

  /**
   * select new mic
   * @param device: DeviceInfo - new device info
   */
  selectSpeaker(device: DeviceInfo): void {
    if (device) {
      this.rtcClient.selectedSpeakerOption = device;
      EasyRTCService.getSharedInstance().setAudioOutput(null, this.rtcClient.selectedSpeakerOption.deviceId)
      .catch((error)=>{console.log("Failed to selectSpeaker: ", error)});;
    }
  }

  /**
   * reset to default selections on all devices
   */
  private getDefaultDeviceSelection() {
    let currentUser: IUser = UserService.getSharedInstance().currentUser;
    if (
      currentUser.preferedMicrophoneDeviceId &&
      _.find(this.micOptions, { deviceId: currentUser.preferedMicrophoneDeviceId })
    ) {
      this.selectMicrophone(_.find(this.micOptions, { deviceId: currentUser.preferedMicrophoneDeviceId }));
    }
    if (
      currentUser.preferedPrimaryCameraDeviceId &&
      _.find(this.cameraOptions, { deviceId: currentUser.preferedPrimaryCameraDeviceId })
    ) {
      this.selectPrimaryCamera(_.find(this.cameraOptions, { deviceId: currentUser.preferedPrimaryCameraDeviceId }));
    } else if (currentUser.preferedPrimaryCameraDeviceLabel) {
      let matchedDevice = _.find(this.cameraOptions, (option: DeviceInfo) => {
        return _.includes(option.label.toLowerCase(), currentUser.preferedPrimaryCameraDeviceLabel.toLowerCase());
      });
      if (matchedDevice) {
        this.selectPrimaryCamera(matchedDevice);
      }
    }
    if (
      currentUser.preferedSecondaryCameraDeviceId &&
      _.find(this.cameraOptions, { deviceId: currentUser.preferedSecondaryCameraDeviceId })
    ) {
      this.selectSecondaryCamera(_.find(this.cameraOptions, { deviceId: currentUser.preferedSecondaryCameraDeviceId }), this.rtcClient.secondaryCameraEnabled);
    } else if (currentUser.preferedSecondaryCameraDeviceLabel) {
      let matchedDevice = _.find(this.cameraOptions, (option: DeviceInfo) => {
        return _.includes(option.label.toLowerCase(), currentUser.preferedSecondaryCameraDeviceLabel.toLowerCase());
      });
      if (matchedDevice) {
        this.selectSecondaryCamera(matchedDevice, this.rtcClient.secondaryCameraEnabled);
      }
    }
    if (
      currentUser.preferedSpeakerDeviceId &&
      _.find(this.speakerOptions, { deviceId: currentUser.preferedSpeakerDeviceId })
    ) {
      this.selectSpeaker(_.find(this.speakerOptions, { deviceId: currentUser.preferedSpeakerDeviceId }));
    }
    if (this.rtcClient.cameraStream || this.rtcClient.secondaryCameraStream) {
      this.updateDeviceSelection();
    }
    if (!this.rtcClient.selectedMicOption && !this.rtcClient.selectedPrimaryCameraOption && !this.rtcClient.selectedSpeakerOption) {
      throw new Error("get default devices failed");
    }
  }

  /**
   * update device selection with streams
   */
  updateDeviceSelection() {
    let micStream = this.rtcClient.cameraStream;
    let cameraStream = this.rtcClient.cameraStream;
    let secondaryCameraStream = this.rtcClient.secondaryCameraStream;
    if (this.micOptions && micStream && micStream.getAudioTracks().length > 0) {
      let matchedOption = _.find(this.micOptions, { label: micStream.getAudioTracks()[0].label }) as DeviceInfo;
      if (matchedOption) {
        this.rtcClient.selectedMicOption = matchedOption;
      }
    }
    if (this.cameraOptions && cameraStream && cameraStream.getVideoTracks().length > 0) {
      let matchedOption = _.find(this.cameraOptions, { label: cameraStream.getVideoTracks()[0].label }) as DeviceInfo;
      if (matchedOption) {
        this.rtcClient.selectedPrimaryCameraOption = matchedOption;
      }
    }
    if (this.cameraOptions && secondaryCameraStream && secondaryCameraStream.getVideoTracks().length > 0) {
      let matchedOption = _.find(this.cameraOptions, { label: secondaryCameraStream.getVideoTracks()[0].label });
      if (matchedOption) {
        this.rtcClient.selectedSecondaryCameraOption = matchedOption as DeviceInfo;
      }
    }
  }

  /**
   * find device by id
   */
  findDeviceById(deviceId: string): DeviceInfo {
    let matchedMic = _.find(this.micOptions, (device: DeviceInfo) => {
      return device.deviceId === deviceId;
    });

    let matchedCamera = _.find(this.cameraOptions, (device: DeviceInfo) => {
      return device.deviceId === deviceId;
    });

    let matchedSpeaker = _.find(this.speakerOptions, (device: DeviceInfo) => {
      return device.deviceId === deviceId;
    });

    return matchedMic || matchedCamera || matchedSpeaker;
  }

  /**
   * render mic volume meter on a canvas
   */
  renderMicVolumeMeterOnCanvas(canvasElementId: string, localStream?: any): void {
    if (!canvasElementId) {
      return;
    }
    if (!(localStream || this.rtcClient.cameraStream)) {
      return;
    }
    if (!this.micVolumeMeter) {
      this.micVolumeMeter = new VolumeMeter(
        canvasElementId,
        localStream || this.rtcClient.cameraStream
      );
      this.micVolumeMeter.render();
      this.micVolumeMeter.start();
    } else {
      this.micVolumeMeter.id = canvasElementId;
      if (this.micVolumeMeter.stream !== (localStream || this.rtcClient.cameraStream)) {
        this.micVolumeMeter.stop();
        this.micVolumeMeter = new VolumeMeter(
          canvasElementId,
          localStream || this.rtcClient.cameraStream
        );
        this.micVolumeMeter.render();
        this.micVolumeMeter.start();
      }
    }
  }

  /**
   * enable secondary camera source
   */
  enableSecondaryCamera(): void {
    this.rtcClient.secondaryCameraEnabled = true;
    this.updateDeviceSelection();
    let currentUser = UserService.getSharedInstance().currentUser;
    let secondaryDevice;
    if (!secondaryDevice && currentUser.preferedSecondaryCameraDeviceId) {
      secondaryDevice = _.find(this.cameraOptions, { deviceId: currentUser.preferedSecondaryCameraDeviceId });
    }
    if (!secondaryDevice && currentUser.preferedSecondaryCameraDeviceLabel) {
      secondaryDevice = _.find(this.cameraOptions, (option: DeviceInfo) => {
        return _.includes(option.label.toLowerCase(), currentUser.preferedSecondaryCameraDeviceLabel.toLowerCase());
      });
    }
    if (!secondaryDevice) {
      secondaryDevice = _.find(this.cameraOptions, (camera: DeviceInfo) => {
        return camera.deviceId !== this.rtcClient.selectedPrimaryCameraOption.deviceId;
      });
    }
    this.selectSecondaryCamera(secondaryDevice);
  }

  /**
   * disable secondary camera source
   */
  disableSecondaryCamera(): void {
    this.rtcClient.secondaryCameraEnabled = false;
    this.rtcClient.selectedSecondaryCameraOption = null;
    UserService.getSharedInstance().currentUser.preferedSecondaryCameraDeviceId = null;
    UserService.getSharedInstance().currentUser.preferedSecondaryCameraDeviceLabel = null;
    this.updateDeviceSelection();
    EasyRTCService.getSharedInstance().updateVideoSource(false)
    .catch((error) => {
      console.log("disableSecondaryCamera - Failed to updateVideoSource: ", error);
    });
  }

  /**
   * switch to next camera
   */
  switchCamera(): void {
    console.log("switch camera requested");
    let currentCameraIndex: number = _.findIndex(this.cameraOptions, (device: DeviceInfo) => {
      return device.deviceId === this.rtcClient.selectedPrimaryCameraOption.deviceId;
    });
    let nextCameraIndex: number = currentCameraIndex + 1;
    nextCameraIndex = nextCameraIndex >= this.cameraOptions.length ? 0 : nextCameraIndex;
    this.selectPrimaryCamera(this.cameraOptions[nextCameraIndex]);
  }
}
