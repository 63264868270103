<!--
    Copyright Compunetix Incorporated 2016
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi
-->

<div
  *ngIf="!isMobile"
  class="top-nav-control-container"
  [title]="localizationService.myLocalizationData.presenceStatus[presenceStatusClass[myEndpoint.status]] || presenceStatusClass[myEndpoint.status]"
>
  <div class="top-nav-control">
    <div
      *ngIf="!canChangeStatus()"
      class="badge disabled-badge"
      [ngClass]="'badge-' + (myEndpoint.status | labelStyle)"
    >
      <div
        class="tag full-width tag-default"
        [ngClass]="'badge-' + (myEndpoint.status | labelStyle)"
      >{{localizationService.myLocalizationData.presenceStatus[presenceStatusClass[myEndpoint.status]] || presenceStatusClass[myEndpoint.status]}}
      </div>
    </div>
    <div class="badge" dropdown *ngIf="canChangeStatus()">
      <button
        id="button-basic" dropdownToggle type="button"
        class="tag full-width dropdown-toggle"
        aria-controls="dropdown-basic"
        [ngClass]="'badge-' + (myEndpoint.status | labelStyle)"
      >{{localizationService.myLocalizationData.presenceStatus[presenceStatusClass[myEndpoint.status]] || presenceStatusClass[myEndpoint.status]}}
        <span class="caret" *ngIf="canChangeStatus()"></span>
      </button>
      <ul
        id="dropdown-basic" *dropdownMenu class="dropdown-menu"
        role="menu" aria-labelledby="button-basic"
      >
        <li role="menuitem"
            *ngFor="let option of statusOptions"
            (click)="changeSelectedOption(option)"
        >
          <span class="dropdown-item">{{localizationService.myLocalizationData.presenceStatus[presenceStatusClass[option]] || presenceStatusClass[option]}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
