/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Component, Output, EventEmitter, Input} from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { IUser, Companion } from "companion";
import { ActionType, Dispatcher } from "../../shared/services/dispatcher";
import { TopBarService, TopBarMenuItem } from "./top-nav.service";

@Component({
  selector: "top-nav-link",
  templateUrl: "./top-nav-link.template.html"
})
/**
 * top nav link view in the login bar
 */
export class TopNavLinkComponent {
  constructor(public topBarService: TopBarService) {
    // nothing needed here
  }

  /**
   * menu item onclick event handler
   */
  onMenuItemClick(menuItem: TopBarMenuItem) {
    if (menuItem.clickAction != null) {
      Dispatcher.dispatch(menuItem.clickAction, menuItem.payload);
    }
  }
}
