/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { Injectable } from "@angular/core";
import { RestService } from "../shared/services/rest.service";
import {
  Companion,
  IGroup,
  IUser,
  IReportDefinition,
  IParameter,
  ParameterType,
  PermissionLevel,
  Alert,
  AlertLevel,
  AlertCode,
  IGroupStatus,
  IQueueConfig,
  ConferenceUtil,
  ThirdPartyAuthProtocol,
  ThirdPartyAuthProvider,
  IThirdPartyAuthConfig,
  IHoursOfOperation,
  IOperatingHours,
  ISpecificDayHours,
} from "companion";
import { TreeViewItem } from "../shared/components/tree-view/tree-item";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { FormService } from "./../form/form.service";
import { ICategory, ISkillTags, ISkillProficiency } from "../../../companion/skills/skill.interface";

@Injectable()
export class GroupManagementService {
  static ENABLE_SUFFIX = "_enabled";
  static STATUS_SUFFIX = "_status";
  childGroups: IGroup[];
  groupTreeRoots: TreeViewItem[];
  static cMAX_CATEGORY_SUBSKILL_LEVELS_SUPPORTED: number = 2;
  categoriesTree: TreeViewItem[];
  categoriesList: string[];
  allAccessibleGroupsDictionary: { [id: string]: IGroup } = {};
  // whether the license supports Voice config
  pstnEnabled: boolean;
  // whether the license supports SSO configuration...
  ssoEnabled: boolean;
  constructor(private restService: RestService) {
    Dispatcher.register(ActionType.SubmitGroupForm, this.SubmitGroupForm.bind(this));
    Dispatcher.register(ActionType.DeleteGroup, this.DeleteGroupAction.bind(this));
    Dispatcher.register(ActionType.LoadGroupData, this.loadGroupData.bind(this));
  }

  /**
   * system current user
   */
  get currentUser() : IUser {
    return Companion.getUserService().currentUser;
  }

  loadGroupData() {
    this.getAllAccessibleGroups().then((data: IGroup[]) => {
      Dispatcher.dispatch(ActionType.LoadGroupDataCallback, data);
    })
    .catch((error) => {
      console.log("Failed to getAccessibleGroups: ", error);
    });
  }

  // Get all accessible groups
  getAllAccessibleGroups(): Promise<IGroup[]> {
    return new Promise((resolve: (response: IGroup[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getAllGroups", {})
      .subscribe(
        (data: IGroup[]) => {
          this.allAccessibleGroupsDictionary = _.keyBy(data, "_id");
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  // Get sub groups
  getSubGroups(): Promise<IGroup[]> {
    return new Promise((resolve: (response: IGroup[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getSubGroups", {})
      .subscribe(
        (data: IGroup[]) => {
          this.childGroups = data;
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  // Get the group trees
  getGroupTrees(): Promise<IGroup[]> {
    return new Promise((resolve: (response: IGroup[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getGroupTree", {})
      .subscribe(
        (data: IGroup[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  loadGroupTree(): Promise<TreeViewItem[]> {
    return this.getGroupTrees()
    .then((groupRoots: IGroup[]) => {
      let treeViewRoots = _.map(groupRoots, (root: IGroup) => {
        return this.groupToTreeViewItem(root);
      });
      this.groupTreeRoots = treeViewRoots;
      return Promise.resolve(treeViewRoots);
    })
    .catch((err: Error) => {
      console.error("GET_GROUP_TREES_ERROR", err);
      return Promise.reject(err);
    });
  }

  groupToTreeViewItem(group: IGroup): TreeViewItem {
    let item: TreeViewItem = {
      text: group.name,
      value: group,
      collapsed: false,
    };
    if (group.children_model && !_.isEmpty(group.children_model)) {
      let children: TreeViewItem[] = [];
      _.forEach(group.children_model, (childGroup: IGroup) => {
        children.push(this.groupToTreeViewItem(childGroup));
      });
      item.children = _.orderBy(children, ["text"]);
    }
    return item;
  }

  markSelectedIfMatch(groupId: string, item: TreeViewItem) {
    item.selected = item.value._id == groupId;
    if (item.children) {
      _.forEach(item.children, (child: TreeViewItem) => {
        this.markSelectedIfMatch(groupId, child);
      });
    }
  }

  // Get the group details
  getGroup(groupId: string): Promise<IGroup> {
    return new Promise((resolve: (response: IGroup) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getGroup", { groupId: groupId })
      .subscribe(
        (data: IGroup) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the queue config associated with the group. We need to fetch it specifically from
   * here as the generic group object may inherit queue config from parent group, and not 
   * actually be contained in the child group
   */
  getGroupsQueueConfig(groupId: string): Promise<IQueueConfig> {
    return new Promise((resolve: (response: IQueueConfig) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getGroupsQueueConfig", { groupId: groupId })
      .subscribe(
        (data: IQueueConfig) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get all transfer data for theme returns an object with
   * queueConfig (the configuration of the queus for the them)
   * opCount which is a map of all the online operators per category. key = category, value = object with language keys
   * and online count numbers for each language as well as a "total" attribute which counts operators for
   * the entire category.
   */
  getTransferDataForTheme(theme: string, endpointCategory: string): Promise<any> {
    return new Promise((resolve: (response: any) => void, reject: (error: Error) => void) => {
      this.restService
        .post("/getTransferDataForTheme", { theme: theme, endpointCategory: endpointCategory })
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   *  create a group
   */
  createGroup(group: IGroup): Promise<IGroup> {
    return new Promise((resolve: (response: IGroup) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/createGroup", group)
      .subscribe(
        (data: IGroup) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * delete group
   */
  DeleteGroupAction(payload: any) {
    this.deleteGroup(payload.group)
    .then(() => {
      Dispatcher.dispatch(ActionType.HideModalDialog, "group-form");
      Dispatcher.dispatch(ActionType.LoadGroupData);
    })
    .catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * delete a group
   * promises a boolean indicating if the delete succeeded
   */
  deleteGroup(group: IGroup): Promise<boolean> {
    return new Promise((resolve: (response: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/deleteGroup", group)
      .subscribe(
        (data: boolean) => {
          resolve(data);
        },
        (error: any) => {
          reject(new Error(error.error));
        }
      );
    });
  }

  /**
   * edit group details
   */
  editGroup(group: IGroup): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      this.restService.post("/editGroup", group).subscribe(
        (res: any) => {
          resolve();
        },
        (error: any) => {
          reject(new Error(error.error));
        }
      );
    });
  }

  /**
   * get default report definitions
   */
  getDefaultReportDefinitions(): Promise<{ [key: string]: IReportDefinition }> {
    return new Promise(
      (resolve: (response: { [key: string]: IReportDefinition }) => void, reject: (error: Error) => void) => {
        this.restService
        .post("/getDefaultReportDefinitions", {})
        .subscribe(
          (data: { [key: string]: IReportDefinition }) => {
            resolve(data);
          },
          (error: any) => {
            console.error(error);
            reject(error);
          }
        );
      }
    );
  }

  /**
   * find selected Group Tree Items
   */
  findSelectedGroupTreeItems(root: TreeViewItem): TreeViewItem[] {
    let results: TreeViewItem[] = [];
    if (root.selected) {
      results.push(root);
    }
    _.forEach(root.children, (child: TreeViewItem) => {
      results = _.concat(results, this.findSelectedGroupTreeItems(child));
    });
    return results;
  }

  /**
   * find tree item by group id
   */
  findTreeItemByGroupId(id: string, roots: TreeViewItem[] = this.groupTreeRoots): TreeViewItem {
    let result: TreeViewItem;
    _.forEach(roots, (root: TreeViewItem) => {
      result = this.findTreeItemByGroupIdUnderRoot(id, root);
      if (result) {
        // this is the way to break from lodash forEach
        return false;
      }
    });
    return result;
  }

  /**
   * find tree item by group id under root
   */
  findTreeItemByGroupIdUnderRoot(id: string, root: TreeViewItem): TreeViewItem {
    let result: TreeViewItem;
    if (root.value._id == id) {
      result = root;
    } else if (root.children) {
      _.forEach(root.children, (child: TreeViewItem) => {
        result = this.findTreeItemByGroupIdUnderRoot(id, child);
        if (result) {
          // this is the way to break from lodash forEach
          return false;
        }
      });
    }
    return result;
  }

  /**
   * find tree item by group name
   */
  findTreeItemByGroupName(name: string, roots: TreeViewItem[] = this.groupTreeRoots): TreeViewItem {
    let result: TreeViewItem;
    _.forEach(roots, (root: TreeViewItem) => {
      result = this.findTreeItemByGroupNameUnderRoot(name, root);
      if (result) {
        // this is the way to break from lodash forEach
        return false;
      }
    });
    return result;
  }

  /**
   * find tree item by group name under root
   */
  findTreeItemByGroupNameUnderRoot(name: string, root: TreeViewItem): TreeViewItem {
    let result: TreeViewItem;
    if (root.value.name === name) {
      result = root;
    } else if (root.children) {
      _.forEach(root.children, (child: TreeViewItem) => {
        result = this.findTreeItemByGroupNameUnderRoot(name, child);
        if (result) {
          // this is the way to break from lodash forEach
          return false;
        }
      });
    }
    return result;
  }

  /**
   * check if group tree item is under root
   */
  checkIfTreeItemUnderRoot(item: TreeViewItem, root: TreeViewItem): boolean {
    let result: boolean = false;
    if (item === root) {
      result = true;
    } else if (root.children) {
      result = _.some(root.children, (child: TreeViewItem) => {
        return this.checkIfTreeItemUnderRoot(item, child);
      });
    }
    return result;
  }

  /**
   * check if group tree item is under any of roots
   */
  checkIfTreeItemUnderRoots(item: TreeViewItem, roots: TreeViewItem[] = this.groupTreeRoots): boolean {
    return _.some(roots, (root: TreeViewItem) => {
      return this.checkIfTreeItemUnderRoot(item, root);
    });
  }

  /**
   * flag if has permission to edit
   */
  hasPermissionToEdit(groupId: string) {
    switch (parseInt(this.currentUser.permissions.modifyChildGroups, 10)) {
      case PermissionLevel.Always:
        return true;
      case PermissionLevel.InGroup:
      case PermissionLevel.InGroupOrPublic:
        return this.isGroupInMyAllAccessibleGroupsById(groupId);
      case PermissionLevel.Public:
      case PermissionLevel.None:
      default:
        return false;
    }
  }

  /**
   * flag if has permission to delete
   */
  hasPermissionToDelete(group: IGroup) {
    switch (parseInt(this.currentUser.permissions.deleteChildGroups, 10)) {
      case PermissionLevel.Always:
        return true;
      case PermissionLevel.InGroup:
      case PermissionLevel.InGroupOrPublic:
        return this.isGroupInMyChildGroups(group);
      case PermissionLevel.Public:
      case PermissionLevel.None:
      default:
        return false;
    }
  }

  /**
   * flag if has permission to delete report data
   */
  hasPermissionToDeleteReportData(groupId: string) {
    switch (parseInt(this.currentUser.permissions.deleteBillingData, 10)) {
      case PermissionLevel.Always:
        return true;
      case PermissionLevel.InGroup:
      case PermissionLevel.InGroupOrPublic:
        return this.isGroupInMyAllAccessibleGroupsById(groupId);
      case PermissionLevel.Public:
      case PermissionLevel.None:
      default:
        return false;
    }
  }

  /**
   * is group in my child groups
   */
  isGroupInMyChildGroups(group: IGroup): boolean {
    return _.some(this.childGroups, (child: IGroup) => {
      return group["_id"] == child["_id"];
    });
  }

  /**
   * is group in my child groups by id
   */
  isGroupInMyChildGroupsById(groupId: string): boolean {
    return _.some(this.childGroups, (child: IGroup) => {
      return groupId == child["_id"];
    });
  }

  isGroupInMyAllAccessibleGroups(group: IGroup): boolean {
    return !!this.allAccessibleGroupsDictionary[group["_id"]];
  }

  /**
   * check license
   */
  checkLicense() {
    if (this.currentUser.isAuthenticated) {
      this.checkLicenseWarning()
        .then((warningMessage: string) => {
          if (warningMessage) {
            Dispatcher.dispatch(ActionType.Alert, {
              alert: new Alert(warningMessage, "Please Update Your License.", AlertLevel.warning),
            });
          }
      })
        .catch((err: Error) => {
          console.error(err);
        });
    }
    let checkLicenseTimer = setTimeout(() => {
      clearTimeout(checkLicenseTimer);
      this.checkLicense();
    }, 60 * 1000);
  }

  /**
   * check license warning by user
   */
  private checkLicenseWarning() {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
        .post("/checkLicenseWarningByUser", {})
        .subscribe(
          (data: any) => {
            resolve(data.warningMessage);
          },
          (error: any) => {
            console.error(error);
            reject(error);
          }
        );
    });
  }

  /**
   * is group in my all accessible groups by id
   */
  isGroupInMyAllAccessibleGroupsById(groupId: string): boolean {
    return !!this.allAccessibleGroupsDictionary[groupId];
  }

  /**
   * get default group form template parameters
   */
  getDefaultGroupFormParameters(): Promise<{ [key: string]: IParameter }> {
    let _tasks: Promise<any>[] = [];
    let formParameters: { [key: string]: IParameter } = {};
    formParameters[GroupFormKey[GroupFormKey.group_enabled]] = {
      key: GroupFormKey[GroupFormKey.group_enabled],
      title: "Group Configuration",
      type: ParameterType[ParameterType.hidden],
      value: true,
    };
    formParameters[GroupFormKey[GroupFormKey.group_id]] = {
      key: GroupFormKey[GroupFormKey.group_id],
      title: "Id",
      type: ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.group_name]] = {
      key: GroupFormKey[GroupFormKey.group_name],
      title: "Name",
      type: ParameterType[ParameterType.string],
      required: true,
    };
    formParameters[GroupFormKey[GroupFormKey.group_license]] = {
      key: GroupFormKey[GroupFormKey.group_license],
      title: "License",
      type: ParameterType[ParameterType.string],
    };
    formParameters[GroupFormKey[GroupFormKey.group_available_op_count_in_license]] = {
      key: GroupFormKey[GroupFormKey.group_available_op_count_in_license],
      type: ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.group_license_alert_emails]] = {
      key: GroupFormKey[GroupFormKey.group_license_alert_emails],
      title: "License Expire or Over Limit Alert Email Recipients",
      type: ParameterType[ParameterType.list],
    };
    formParameters[GroupFormKey[GroupFormKey.group_parent]] = {
      key: GroupFormKey[GroupFormKey.group_parent],
      title: "Parent Group",
      type: ParameterType[ParameterType.tree],
    };
    formParameters[GroupFormKey[GroupFormKey.group_parent_id]] = {
      key: GroupFormKey[GroupFormKey.group_parent_id],
      title: "Parent Group Id",
      type: ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.group_themes]] = {
      key: GroupFormKey[GroupFormKey.group_themes],
      title: "Themes Associated",
      type: ParameterType[ParameterType.label]
    };
    formParameters[GroupFormKey[GroupFormKey.group_kiosk_unlock]] = {
      key: GroupFormKey[GroupFormKey.group_kiosk_unlock],
      title: "Kiosk Unlock Code (This group's themes only.)",
      type: ParameterType[ParameterType.string]
    };
    this.initGroupMessageConfigParameters(
      formParameters,
      this.currentUser.permissions && this.currentUser.permissions.canManageMessageConfig > 0
    );
    _tasks.push(
      this.getGroup(this.currentUser.groups[0]).then((group: IGroup) => {
        if (group) {
          this.convertGroupMessageConfigToParameters(group, formParameters);
        }
      })
    );
    this.initGroupMessageTemplateParameters(
      formParameters,
      this.currentUser.permissions && this.currentUser.permissions.canManageMessageTemplate > 0
    );
    _tasks.push(
      this.getGroup(this.currentUser.groups[0]).then((group: IGroup) => {
        if (group) {
          this.convertGroupMessageTemplatesToParameters(group, formParameters);
        }
      })
    );
    this.initGroupAuthenticationParameters(
      formParameters,
      this.currentUser.permissions && this.currentUser.permissions.canManageAuthentication > 0
    );

    this.initGroupTwilioParameters(formParameters);

    this.initVccConfigParameters(formParameters);
    this.initSkillSetParameters(formParameters);
    this.initRemoteDesktopParameters(formParameters);
    this.initThirdPartyAPIIntegrationParameters(formParameters);
    this.initHoursOfOperationParameters(formParameters);

    _tasks.push(
      this.loadGroupTree().then((treeRoots: TreeViewItem[]) => {
        formParameters[GroupFormKey[GroupFormKey.group_parent]].options = treeRoots;
        formParameters[GroupFormKey[GroupFormKey.group_parent]].value = treeRoots[0].value;
        this.markSelectedIfMatch(treeRoots[0].value._id, treeRoots[0]);
      })
    );
    
    return Promise.all(_tasks).then(() => {
      return Promise.resolve(formParameters);
    });
  }

  addStatusToFormParameter(formParameters: { [key: string]: IParameter }, key: GroupFormKey) {
    formParameters[GroupFormKey[key]] = {
      key: GroupFormKey[key],
      title: "Status",
      options: { custom: "Custom", disabled: "Disabled", inherited: "Inherited from parent" },
      type: ParameterType[ParameterType.dropdown],
    };
  }

  initGroupAuthenticationParameters(formParameters: { [key: string]: IParameter }, enabled: boolean = true) {
    formParameters[GroupFormKey[GroupFormKey.password_enabled]] = {
      key: GroupFormKey[GroupFormKey.password_enabled],
      title: "Authentication Configuration",
      type: ParameterType[ParameterType.hidden],
      disabled: !enabled,
      value: true
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.password_status);
    formParameters[GroupFormKey[GroupFormKey.password_minimum_length]] = {
      key: GroupFormKey[GroupFormKey.password_minimum_length],
      title: "Password Minimum Length",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 8,
    };
    formParameters[GroupFormKey[GroupFormKey.password_maximum_length]] = {
      key: GroupFormKey[GroupFormKey.password_maximum_length],
      title: "Password Maximum Length",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 20,
    };
    formParameters[GroupFormKey[GroupFormKey.password_minimum_uppercase]] = {
      key: GroupFormKey[GroupFormKey.password_minimum_uppercase],
      title: "Password Minimum Uppercase",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_minimum_lowercase]] = {
      key: GroupFormKey[GroupFormKey.password_minimum_lowercase],
      title: "Password Minimum Lowercase",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_minimum_numbers]] = {
      key: GroupFormKey[GroupFormKey.password_minimum_numbers],
      title: "Password Minimum Numbers",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_minimum_characters]] = {
      key: GroupFormKey[GroupFormKey.password_minimum_characters],
      title: "Password Minimum Special Characters",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_forbidden_characters]] = {
      key: GroupFormKey[GroupFormKey.password_forbidden_characters],
      title: "Password Forbidden Characters",
      type: enabled ? ParameterType[ParameterType.list] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_enable_expiration]] = {
      key: GroupFormKey[GroupFormKey.password_enable_expiration],
      title: "Password Enable Expiration",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
      value: false,
    };
    formParameters[GroupFormKey[GroupFormKey.password_valid_days]] = {
      key: GroupFormKey[GroupFormKey.password_valid_days],
      title: "Password Expires (Days)",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 90,
    };
    formParameters[GroupFormKey[GroupFormKey.password_warning_email]] = {
      key: GroupFormKey[GroupFormKey.password_warning_email],
      title: "Email Expiration Warning",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
      value: false,
    };
    formParameters[GroupFormKey[GroupFormKey.password_warning_duration]] = {
      key: GroupFormKey[GroupFormKey.password_warning_duration],
      title: "Warn User (Days before Expiration)",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 7,
    };
    formParameters[GroupFormKey[GroupFormKey.password_enable_lockout]] = {
      key: GroupFormKey[GroupFormKey.password_enable_lockout],
      title: "Password Enable Lockout",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
      value: "false",
    };
    formParameters[GroupFormKey[GroupFormKey.password_lockout_attempts]] = {
      key: GroupFormKey[GroupFormKey.password_lockout_attempts],
      title: "Password Attempts Until Lockout",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_lockout_duration]] = {
      key: GroupFormKey[GroupFormKey.password_lockout_duration],
      title: "Password Lockout Duration (Mins)",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_disable_inactive]] = {
      key: GroupFormKey[GroupFormKey.password_disable_inactive],
      title: "Disable Inactive Accounts",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_inactive_after_days]] = {
      key: GroupFormKey[GroupFormKey.password_inactive_after_days],
      title: "Accounts are Inactive after (Days)",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_store_old]] = {
      key: GroupFormKey[GroupFormKey.password_store_old],
      title: "Store Old Passwords",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_prevent_reuse_number]] = {
      key: GroupFormKey[GroupFormKey.password_prevent_reuse_number],
      title: "Prevent Reusing Last # Passwords",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
      value: 0,
    };
    formParameters[GroupFormKey[GroupFormKey.password_two_factor_required]] = {
      key: GroupFormKey[GroupFormKey.password_two_factor_required],
      title: "Dual Factor Authentication Enabled",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
    };
    if(this.ssoEnabled)
    {
      formParameters[GroupFormKey[GroupFormKey.auth_third_party_enabled]] = {
        key: GroupFormKey[GroupFormKey.auth_third_party_enabled],
        title: "Third Party Authentication Configuration",
        type: ParameterType[ParameterType.hidden],
        disabled: !enabled ,
        value: true,
      };
      this.addStatusToFormParameter(formParameters, GroupFormKey.auth_third_party_status);
      formParameters[GroupFormKey[GroupFormKey.auth_third_party_config]] = {
        key: GroupFormKey[GroupFormKey.auth_third_party_config],
        title: "Third Party Authentication Configuration",
        type: enabled ? ParameterType[ParameterType.third_party_auth_list] : ParameterType[ParameterType.hidden],
      };
    }
  }

  initGroupMessageConfigParameters(formParameters: { [key: string]: IParameter }, enabled: boolean = true) {
    formParameters[GroupFormKey[GroupFormKey.messaging_config_enabled]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_enabled],
      title: "SMS Service Configuration",
      type: ParameterType[ParameterType.hidden],
      disabled: !enabled,
      value: true,
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.messaging_config_status);
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_provider]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_provider],
      title: "SMS Provider",
      options: { GupShup: "GupShup", SMSCountry: "SMSCountry", Nexmo: "Nexmo" },
      type: enabled ? ParameterType[ParameterType.dropdown] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_api_key]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_api_key],
      title: "SMS Message API Key",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_api_secret]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_api_secret],
      title: "SMS Message API Secret",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_account_id]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_account_id],
      title: "SMS Provider Account ID",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_account_password]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_account_password],
      title: "SMS Provider Account Password",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_from_number]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_from_number],
      title: "SMS Message From Number",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_sms_sender_id]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_sms_sender_id],
      title: "SMS Message From Sender ID",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_enabled]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_enabled],
      title: "Messaging Service Configuration",
      type: ParameterType[ParameterType.hidden],
      disabled: !enabled,
      value: true,
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.messaging_config_status);
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_host]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_host],
      title: "Email Service Host",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_port]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_port],
      title: "Email Service Port",
      type: enabled ? ParameterType[ParameterType.number] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_is_secure]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_is_secure],
      title: "Email Service Secure",
      type: enabled ? ParameterType[ParameterType.checkbox] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_account_username]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_account_username],
      title: "Email Service Account Username",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_account_password]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_account_password],
      title: "Email Service Account Password",
      type: enabled ? ParameterType[ParameterType.password] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.messaging_config_email_sender_from]] = {
      key: GroupFormKey[GroupFormKey.messaging_config_email_sender_from],
      title: "Email Service Sender From",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
  }

  initGroupMessageTemplateParameters(formParameters: { [key: string]: IParameter }, enabled: boolean = true) {
    formParameters[GroupFormKey[GroupFormKey.message_template_enabled]] = {
      key: GroupFormKey[GroupFormKey.message_template_enabled],
      title: "Message Template Configuration",
      type: ParameterType[ParameterType.hidden],
      disabled: !enabled,
      value: true,
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.message_template_status);
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_create_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_create_email_subject],
      title: "[Created] Subject - Message Subject Sent to Guest on Create Reservation",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_create_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_create_email_template],
      title: "[Created] Template - Message Template Sent to Guest on Create Reservation",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_edit_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_edit_email_subject],
      title: "[Modified] Subject - Message Subject Sent to Guest on Modify Reservation",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_edit_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_edit_email_template],
      title: "[Modified] Template - Message Template Sent to Guest on Modify Reservation",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_cancel_email_subject],
      title: "[Deleted] Subject - Message Subject Sent to Guest on Delete Reservation",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_cancel_email_template],
      title: "[Deleted] Template - Message Template Sent to Guest on Delete Reservation",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_reminder_email_subject],
      title: "[Reminder] Subject - Message Subject Sent to Guest on Reservation Reminder",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_reminder_email_template],
      title: "[Reminder] Template - Message Template Sent to Guest on Reservation Reminder",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_invite_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_invite_email_subject],
      title: "[Invite] Subject - Message Subject Sent to Guest on Reservation invitation",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_guest_invite_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_guest_invite_email_template],
      title: "[Invite] Template - Message Template Sent to Guest on Reservation invitation",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_subject]] = {
      key: GroupFormKey[GroupFormKey.message_template_host_confirmation_email_subject],
      title: "[Confirm] Subject - Message Subject Sent to Host on Reservation Confirmation",
      type: enabled ? ParameterType[ParameterType.string] : ParameterType[ParameterType.hidden],
    };
    formParameters[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_template]] = {
      key: GroupFormKey[GroupFormKey.message_template_host_confirmation_email_template],
      title: "[Confirm] Template - Message Template Sent to Host on Reservation Confirmation",
      type: enabled ? ParameterType[ParameterType.wysiwyg] : ParameterType[ParameterType.hidden],
    };
  }

  initGroupTwilioParameters(formParameters: { [key: string]: IParameter }) {
    if (!this.pstnEnabled) {
      return;
    }
    formParameters[GroupFormKey[GroupFormKey.twilio_account_enabled]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_enabled],
      title: "Voice Configuration",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.twilio_account_status);
    formParameters[GroupFormKey[GroupFormKey.twilio_account_sid]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_sid],
      title: "Account Sid",
      type: ParameterType[ParameterType.string],
    };
    formParameters[GroupFormKey[GroupFormKey.twilio_account_token]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_token],
      title: "Auth Token",
      type: ParameterType[ParameterType.string],
    };
    formParameters[GroupFormKey[GroupFormKey.twilio_account_app_sid]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_app_sid],
      title: "Voice Application Sid",
      type: ParameterType[ParameterType.string],
    };
    formParameters[GroupFormKey[GroupFormKey.twilio_account_phone_numbers]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_phone_numbers],
      title: "Phone Numbers",
      type: ParameterType[ParameterType.list],
    };
    formParameters[GroupFormKey[GroupFormKey.twilio_account_edge_locations]] = {
      key: GroupFormKey[GroupFormKey.twilio_account_edge_locations],
      title: "Edge Location",
      type: ParameterType[ParameterType.list],
    };
  }

  initVccConfigParameters(formParameters: { [key: string]: IParameter })
  {
    formParameters[GroupFormKey[GroupFormKey.vcc_enabled]] = {
      key: GroupFormKey[GroupFormKey.vcc_enabled],
      title: "VCC Settings",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    formParameters[GroupFormKey[GroupFormKey.vcc_call_ring_duration]] = {
      key: GroupFormKey[GroupFormKey.vcc_call_ring_duration],
      title: "VCC Call Ring Duration",
      type: ParameterType[ParameterType.number],
      value: 30
    };
    formParameters[GroupFormKey[GroupFormKey.vcc_require_guest_entry_id]] = {
      key: GroupFormKey[GroupFormKey.vcc_require_guest_entry_id],
      title: "VCC Require Guest Form Entry Submission",
      type: ParameterType[ParameterType.checkbox],
      value: false
    };
    formParameters[GroupFormKey[GroupFormKey.vcc_redirect_on_guest_entry_deny]] = {
      key: GroupFormKey[GroupFormKey.vcc_redirect_on_guest_entry_deny],
      title: "VCC Redirect On Guest Entry Deny URL",
      type: ParameterType[ParameterType.string],
      value: ""
    };
  }

  initSkillSetParameters(formParameters: {[key: string]: IParameter})
  {
    formParameters[GroupFormKey[GroupFormKey.skill_set_enabled]] = {
      key: GroupFormKey[GroupFormKey.skill_set_enabled],
      title: "Skills Configuration",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.skill_set_status);
    formParameters[GroupFormKey[GroupFormKey.skill_set_categories_subskills]] = {
      key: GroupFormKey[GroupFormKey.skill_set_categories_subskills],
      title: "Categories Sub Skills",
      type: ParameterType[ParameterType.dynamic_tree],
      value: []
    };
    formParameters[GroupFormKey[GroupFormKey.skill_set_categories_subskills_new]] = {
      key: GroupFormKey[GroupFormKey.skill_set_categories_subskills_new],
      title: "",
      type: ParameterType[ParameterType.category_subskills_new],
      value: ""
    };
    formParameters[GroupFormKey[GroupFormKey.skill_set_languages]] = {
      key: GroupFormKey[GroupFormKey.skill_set_languages],
      title: "Language",
      type: ParameterType[ParameterType.list],
      value: []
    };
    formParameters[GroupFormKey[GroupFormKey.skill_set_default_category]] = {
      key: GroupFormKey[GroupFormKey.skill_set_default_category],
      title: "Default Category",
      type: ParameterType[ParameterType.dropdown],
      value: "" 
    };
    formParameters[GroupFormKey[GroupFormKey.skill_set_default_language]] = {
      key: GroupFormKey[GroupFormKey.skill_set_default_language],
      title: "Default Language",
      type: ParameterType[ParameterType.dropdown],
      value: "" 
    };
    this.convertGroupSkillSetToParameters(null, formParameters);
  }

  initRemoteDesktopParameters(formParameters: {[key: string]: IParameter})
  {
    formParameters[GroupFormKey[GroupFormKey.remote_desktop_enabled]] = {
      key: GroupFormKey[GroupFormKey.remote_desktop_enabled],
      title: "Remote Desktop",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    formParameters[GroupFormKey[GroupFormKey.remote_desktop_secret_key]] = {
      key: GroupFormKey[GroupFormKey.remote_desktop_secret_key],
      title: "Secret Key",
      type: ParameterType[ParameterType.password],
      value: ""
    };
    formParameters[GroupFormKey[GroupFormKey.remote_desktop_broker_connection_string]] = {
      key: GroupFormKey[GroupFormKey.remote_desktop_broker_connection_string],
      title: "WSS Guac broker connection string",
      type: ParameterType[ParameterType.string],
      value: ""
    };
    formParameters[GroupFormKey[GroupFormKey.remote_desktop_connections]] = {
      key: GroupFormKey[GroupFormKey.remote_desktop_connections],
      title: "Remote Connections",
      type: ParameterType[ParameterType.remote_desktop_conn_list],
      value: []
    };
  }

  initThirdPartyAPIIntegrationParameters(formParameters: { [key: string]: IParameter }) {
    formParameters[GroupFormKey[GroupFormKey.third_party_api_integration_enabled]] = {
      key: GroupFormKey[GroupFormKey.third_party_api_integration_enabled],
      title: "Third Party API Integration",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    formParameters[GroupFormKey[GroupFormKey.third_party_api_integration_google_maps_api_key]] = {
      key: GroupFormKey[GroupFormKey.third_party_api_integration_google_maps_api_key],
      title: "Google Maps API Key",
      type: ParameterType[ParameterType.string],
      value: ""
    };
  }

  initHoursOfOperationParameters(formParameters: { [key: string]: IParameter }) {
    formParameters[GroupFormKey[GroupFormKey.hours_of_operation_enabled]] = {
      key: GroupFormKey[GroupFormKey.hours_of_operation_enabled],
      title: "Hours Of Operation",
      type: ParameterType[ParameterType.hidden],
      value: true
    };
    this.addStatusToFormParameter(formParameters, GroupFormKey.hours_of_operation_status);
    formParameters[GroupFormKey[GroupFormKey.hours_of_operation_operating_hours]] = {
      key: GroupFormKey[GroupFormKey.hours_of_operation_operating_hours],
      title: "Hours Of Operation In Week",
      type: ParameterType[ParameterType.hours_of_operation],
      value: [],
      timezoneOptions: Intl.supportedValuesOf('timeZone')
    };
    this.convertHoursOfOperationToParameters(null, formParameters);
  }

  convertGroupMessageConfigToParameters(group: IGroup, parameters: { [key: string]: IParameter }) {
    if (group.messagingConfig) {
      parameters[GroupFormKey[GroupFormKey.messaging_config_status]].value = group.messagingConfig.status;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_provider]].value = group.messagingConfig.SMSProvider;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_api_key]].value = group.messagingConfig.TextApiKey;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_api_secret]].value = group.messagingConfig.TextApiSecret;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_account_id]].value = group.messagingConfig.AccountId;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_account_password]].value = group.messagingConfig.AccountPassword;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_from_number]].value = group.messagingConfig.TextFromNumber;
      parameters[GroupFormKey[GroupFormKey.messaging_config_sms_sender_id]].value = group.messagingConfig.SenderId;

      parameters[GroupFormKey[GroupFormKey.messaging_config_email_host]].value = group.messagingConfig.EmailHost;
      parameters[GroupFormKey[GroupFormKey.messaging_config_email_port]].value = group.messagingConfig.EmailPort;
      parameters[GroupFormKey[GroupFormKey.messaging_config_email_is_secure]].value = group.messagingConfig.EmailSecure;
      parameters[GroupFormKey[GroupFormKey.messaging_config_email_account_username]].value = group.messagingConfig.EmailUser;
      parameters[GroupFormKey[GroupFormKey.messaging_config_email_account_password]].value = group.messagingConfig.EmailPass;
      parameters[GroupFormKey[GroupFormKey.messaging_config_email_sender_from]].value = group.messagingConfig.EmailFrom;
    } else {
      parameters[GroupFormKey[GroupFormKey.messaging_config_status]].value = IGroupStatus.disabled;
    }
    this.loadStatus(GroupFormKey.messaging_config_status, parameters);
  }

  convertGroupMessageTemplatesToParameters(group: IGroup, parameters: { [key: string]: IParameter }, disabled:boolean=false) {
    if (group.messagingTemplate && !disabled) {
      parameters[GroupFormKey[GroupFormKey.message_template_status]].value = group.messagingTemplate.status;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_create_email_template]].value =
        group.messagingTemplate.guestCreateEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_create_email_subject]].value =
        group.messagingTemplate.guestCreateEmailSubject;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_edit_email_template]].value =
        group.messagingTemplate.guestEditEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_edit_email_subject]].value =
        group.messagingTemplate.guestEditEmailSubject;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_template]].value =
        group.messagingTemplate.guestCancelEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_subject]].value =
        group.messagingTemplate.guestCancelEmailSubject;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_template]].value =
        group.messagingTemplate.guestReminderEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_subject]].value =
        group.messagingTemplate.guestReminderEmailSubject;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_invite_email_template]].value =
        group.messagingTemplate.guestInviteEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_guest_invite_email_subject]].value =
        group.messagingTemplate.guestInviteEmailSubject;
      parameters[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_template]].value =
        group.messagingTemplate.hostConfirmationEmailTemplate;
      parameters[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_subject]].value =
        group.messagingTemplate.hostConfirmationEmailSubject;
    } else {
      parameters[GroupFormKey[GroupFormKey.message_template_status]].value = IGroupStatus.disabled;
    }
    this.loadStatus(GroupFormKey.message_template_status, parameters);
  }

  convertParameterValuesToGroupMessageConfig(parameterValues: { [key: string]: any }, group: IGroup) {
    group.messagingConfig = {};
    group.messagingConfig.status = parameterValues[GroupFormKey[GroupFormKey.messaging_config_status]];
    group.messagingConfig.SMSProvider = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_provider]];
    group.messagingConfig.TextApiKey = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_api_key]];
    group.messagingConfig.TextApiSecret = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_api_secret]];
    group.messagingConfig.AccountId = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_account_id]];
    group.messagingConfig.AccountPassword = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_account_password]];
    group.messagingConfig.TextFromNumber = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_from_number]];
    group.messagingConfig.SenderId = parameterValues[GroupFormKey[GroupFormKey.messaging_config_sms_sender_id]];
    group.messagingConfig.EmailHost = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_host]];
    group.messagingConfig.EmailPort = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_port]];
    group.messagingConfig.EmailSecure = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_is_secure]];
    group.messagingConfig.EmailUser = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_account_username]];
    group.messagingConfig.EmailPass = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_account_password]];
    group.messagingConfig.EmailFrom = parameterValues[GroupFormKey[GroupFormKey.messaging_config_email_sender_from]];
  }

  convertParameterValuesToGroupMessageTemplates(parameterValues: { [key: string]: any }, group: IGroup) {
    group.messagingTemplate = {};
    group.messagingTemplate.status = parameterValues[GroupFormKey[GroupFormKey.message_template_status]];
    group.messagingTemplate.guestCreateEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_create_email_template]];
    group.messagingTemplate.guestCreateEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_create_email_subject]];
    group.messagingTemplate.guestReminderEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_template]];
    group.messagingTemplate.guestReminderEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_reminder_email_subject]];
    group.messagingTemplate.guestEditEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_edit_email_template]];
    group.messagingTemplate.guestEditEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_edit_email_subject]];
    group.messagingTemplate.guestCancelEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_template]];
    group.messagingTemplate.guestCancelEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_cancel_email_subject]];
    group.messagingTemplate.guestInviteEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_invite_email_template]];
    group.messagingTemplate.guestInviteEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_guest_invite_email_subject]];
    group.messagingTemplate.hostConfirmationEmailTemplate =
      parameterValues[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_template]];
    group.messagingTemplate.hostConfirmationEmailSubject =
      parameterValues[GroupFormKey[GroupFormKey.message_template_host_confirmation_email_subject]];
  }

  convertParameterValuesToGroupTwilioConfig(parameterValues: { [key: string]: any }, group: IGroup) {
    group.twilioAccount = {};
    group.twilioAccount.status = parameterValues[GroupFormKey[GroupFormKey.twilio_account_status]];
    group.twilioAccount.accountSid = parameterValues[GroupFormKey[GroupFormKey.twilio_account_sid]];
    group.twilioAccount.authToken = parameterValues[GroupFormKey[GroupFormKey.twilio_account_token]];
    group.twilioAccount.twimlAppSid = parameterValues[GroupFormKey[GroupFormKey.twilio_account_app_sid]];
    group.twilioAccount.phoneNumbers = parameterValues[GroupFormKey[GroupFormKey.twilio_account_phone_numbers]];
    group.twilioAccount.edgeLocations = parameterValues[GroupFormKey[GroupFormKey.twilio_account_edge_locations]];
  }

  convertGroupTwilioConfigToParameters(group: IGroup, parameters: { [key: string]: IParameter }) {
    if (!this.pstnEnabled) {
      return;
    }

    if (group.twilioAccount) {
      parameters[GroupFormKey[GroupFormKey.twilio_account_status]].value = group.twilioAccount.status;
      parameters[GroupFormKey[GroupFormKey.twilio_account_sid]].value = group.twilioAccount.accountSid;
      parameters[GroupFormKey[GroupFormKey.twilio_account_token]].value = group.twilioAccount.authToken;
      parameters[GroupFormKey[GroupFormKey.twilio_account_app_sid]].value = group.twilioAccount.twimlAppSid;
      parameters[GroupFormKey[GroupFormKey.twilio_account_phone_numbers]].value = group.twilioAccount.phoneNumbers;
      parameters[GroupFormKey[GroupFormKey.twilio_account_edge_locations]].value = group.twilioAccount.edgeLocations;
    }

    this.loadStatus(GroupFormKey.twilio_account_status, parameters);
  }

  convertGroupVccConfigToParameters(group: IGroup, parameterValues: { [key: string]: any })
  {
    parameterValues[GroupFormKey[GroupFormKey.vcc_enabled]].value = true;
    if (group.VccConfig) {
      parameterValues[GroupFormKey[GroupFormKey.vcc_call_ring_duration]].value = group.VccConfig.ringtonePanelDuration;
      parameterValues[GroupFormKey[GroupFormKey.vcc_require_guest_entry_id]].value = group.VccConfig.requireGuestEntryId;
      parameterValues[GroupFormKey[GroupFormKey.vcc_redirect_on_guest_entry_deny]].value = group.VccConfig.redirectOnDenyEntryUrl;
    }
  }

  convertGroupSkillSetToParameters(group: IGroup, parameterValues: { [key: string]: any })
  {
    this.categoriesTree = this.getCategoriesTreeFromGroup(group);
    parameterValues[GroupFormKey[GroupFormKey.skill_set_categories_subskills]].options = this.categoriesTree;
    this.categoriesList = this.getCategoriesListFromGroup(group);
    parameterValues[GroupFormKey[GroupFormKey.skill_set_default_category]].options = this.categoriesList;
    // get list to make every combination of skillset...
    if(group?.queueConfig)
    {
      parameterValues[GroupFormKey[GroupFormKey.skill_set_status]].value = group.queueConfig.status;

      if(group.queueConfig?.skillSet)
      {
        parameterValues[GroupFormKey[GroupFormKey.skill_set_enabled]].value = true;
        parameterValues[GroupFormKey[GroupFormKey.skill_set_languages]].value  = group.queueConfig.skillSet.languages;
  
        if(group?.queueConfig?.skillTags)
        {
          parameterValues[GroupFormKey[GroupFormKey.skill_set_default_category]].value = this.categoriesList.indexOf(group.queueConfig.skillTags.category);
  
          parameterValues[GroupFormKey[GroupFormKey.skill_set_default_language]].options = group.queueConfig.skillSet.languages;
            
          parameterValues[GroupFormKey[GroupFormKey.skill_set_default_language]].value =
            group.queueConfig.skillSet.languages?.indexOf(group.queueConfig.skillTags.language);
        }
      }
    }
    else
    {
      parameterValues[GroupFormKey[GroupFormKey.skill_set_status]].value = IGroupStatus.disabled;
    }

    this.loadStatus(GroupFormKey.skill_set_status, parameterValues);
  }

  updatePstnVisibilityFields(status: string, parameters: { [key: string]: IParameter }) {
    const isHidden = status !== "enabled";
    parameters[GroupFormKey[GroupFormKey.twilio_account_sid]].isHidden = isHidden;
    parameters[GroupFormKey[GroupFormKey.twilio_account_token]].isHidden = isHidden;
    parameters[GroupFormKey[GroupFormKey.twilio_account_app_sid]].isHidden = isHidden;
    parameters[GroupFormKey[GroupFormKey.twilio_account_phone_numbers]].isHidden = isHidden;
    parameters[GroupFormKey[GroupFormKey.twilio_account_edge_locations]].isHidden = isHidden;
  }

  /**
   * Update the visibility of a section based on the status
   * @param section
   * @param status
   * @param parameters
   */
  updateVisibilityFields(section: string, status: string, parameters: { [key: string]: IParameter }) {
    if (!status) {
      return;
    }

    const isHidden = status !== IGroupStatus.custom;

    const keys = Object.keys(parameters)
      .filter(key => key.startsWith(section) &&
        !key.endsWith(GroupManagementService.ENABLE_SUFFIX) &&
        !key.endsWith(GroupManagementService.STATUS_SUFFIX)
      );

    keys.forEach(key => {
      parameters[key].isHidden = isHidden;
    });
  }

  /**
   * Load the status of a section
   * @param statusGroupKey
   * @param parameters
   */
  loadStatus(statusGroupKey: GroupFormKey, parameters) {
    const enabledKey = GroupFormKey[statusGroupKey].toString();
    const status = parameters[GroupFormKey[statusGroupKey]].value;
    const section = enabledKey.substring(0, enabledKey.lastIndexOf(GroupManagementService.STATUS_SUFFIX));
    this.updateVisibilityFields(section, status, parameters);
  }

  /**
   * convert group data to parameters
   */
  convertGroupToParameters(data: IGroup): Promise<{ [key: string]: IParameter }> {
    this.pstnEnabled = data.license.metadata.pstnAudioIntegrationEnabled || false;
    this.ssoEnabled = data.license.metadata.ssoEnabled || false;

    return this.getDefaultGroupFormParameters().then((parameters: { [key: string]: IParameter }) => {
      parameters[GroupFormKey[GroupFormKey.group_id]].value = data["_id"];
      if (data["_id"]) {
        let selfTreeItem: TreeViewItem = this.findTreeItemByGroupId(data["_id"]);
        selfTreeItem.hideChildren = true;
      }

      if(this.ssoEnabled)
      {
        parameters[GroupFormKey[GroupFormKey.auth_third_party_config]]["groupId"] = data["_id"];
      }
      parameters[GroupFormKey[GroupFormKey.group_name]].value = data.name;
      parameters[GroupFormKey[GroupFormKey.group_license]].value = data.licenseKey;
      if (data.license) {
        parameters[GroupFormKey[GroupFormKey.group_available_op_count_in_license]].value =
          "Max Online Operators Capacity In License: " + (data.license.metadata.maxOnlineOperatorCount || 0);
      }
      parameters[GroupFormKey[GroupFormKey.group_license_alert_emails]].value = data.licenseAlertEmails;
      parameters[GroupFormKey[GroupFormKey.group_parent_id]].value = data.parentId;
      if (data.parentId) {
        let parentTreeItem = this.findTreeItemByGroupId(data.parentId);
        if (parentTreeItem) {
          parameters[GroupFormKey[GroupFormKey.group_parent]].value = parentTreeItem.value;
          this.markSelectedIfMatch(
            parameters[GroupFormKey[GroupFormKey.group_parent]].value._id,
            this.groupTreeRoots[0]
          );
        } else {
          parameters[GroupFormKey[GroupFormKey.group_parent]].value = { _id: data.parentId, name: null } as IGroup;
          parameters[GroupFormKey[GroupFormKey.group_parent]].type = ParameterType[ParameterType.hidden];
        }
      } else {
        parameters[GroupFormKey[GroupFormKey.group_parent]].value = null;
        this.markSelectedIfMatch(null, this.groupTreeRoots[0]);
      }
      // Just list the themes here.
      parameters[GroupFormKey[GroupFormKey.group_themes]].value = data.themes.join(' | ');
      parameters[GroupFormKey[GroupFormKey.group_kiosk_unlock]].value = data.kioskUnlock;
      
      this.convertGroupMessageConfigToParameters(data, parameters);
      this.convertGroupMessageTemplatesToParameters(data, parameters);
      this.convertGroupTwilioConfigToParameters(data, parameters);
      this.convertGroupVccConfigToParameters(data, parameters);
      this.convertGroupSkillSetToParameters(data, parameters);
      this.convertHoursOfOperationToParameters(data, parameters);

      if (data.AuthenticationConfig) {
        if (data.AuthenticationConfig.passwordPolicy) {
          parameters[GroupFormKey[GroupFormKey.password_status]].value = data.AuthenticationConfig?.passwordPolicyStatus || IGroupStatus.disabled;
          parameters[GroupFormKey[GroupFormKey.password_minimum_length]].value =
            data.AuthenticationConfig.passwordPolicy.minimumLength;
          parameters[GroupFormKey[GroupFormKey.password_maximum_length]].value =
            data.AuthenticationConfig.passwordPolicy.maximumLength;
          parameters[GroupFormKey[GroupFormKey.password_minimum_uppercase]].value =
            data.AuthenticationConfig.passwordPolicy.minimumUppercase;
          parameters[GroupFormKey[GroupFormKey.password_minimum_lowercase]].value =
            data.AuthenticationConfig.passwordPolicy.minimumLowercase;
          parameters[GroupFormKey[GroupFormKey.password_minimum_numbers]].value =
            data.AuthenticationConfig.passwordPolicy.minimumNumbers;
          parameters[GroupFormKey[GroupFormKey.password_minimum_characters]].value =
            data.AuthenticationConfig.passwordPolicy.minimumSpecialCharacters;
          parameters[GroupFormKey[GroupFormKey.password_forbidden_characters]].value =
            data.AuthenticationConfig.passwordPolicy.forbiddenCharacters;
          parameters[GroupFormKey[GroupFormKey.password_enable_expiration]].value =
            data.AuthenticationConfig.passwordPolicy.enablePasswordExpiration;
          parameters[GroupFormKey[GroupFormKey.password_warning_email]].value =
            data.AuthenticationConfig.passwordPolicy.enableExpirationWarning;
          parameters[GroupFormKey[GroupFormKey.password_warning_duration]].value =
            data.AuthenticationConfig.passwordPolicy.passwordWarnDays;
          parameters[GroupFormKey[GroupFormKey.password_valid_days]].value =
            data.AuthenticationConfig.passwordPolicy.passwordValidDays;
          parameters[GroupFormKey[GroupFormKey.password_disable_inactive]].value =
            data.AuthenticationConfig.passwordPolicy.disableInactiveAccounts;
          parameters[GroupFormKey[GroupFormKey.password_inactive_after_days]].value =
            data.AuthenticationConfig.passwordPolicy.disableInactiveDuration;
          parameters[GroupFormKey[GroupFormKey.password_enable_lockout]].value =
            data.AuthenticationConfig.passwordPolicy.enableLockout;
          parameters[GroupFormKey[GroupFormKey.password_lockout_attempts]].value =
            data.AuthenticationConfig.passwordPolicy.failedAttemptsLockout;
          parameters[GroupFormKey[GroupFormKey.password_lockout_duration]].value =
            data.AuthenticationConfig.passwordPolicy.lockoutDuration;
          parameters[GroupFormKey[GroupFormKey.password_store_old]].value =
            data.AuthenticationConfig.passwordPolicy.storeOldPasswords;
          parameters[GroupFormKey[GroupFormKey.password_prevent_reuse_number]].value =
            data.AuthenticationConfig.passwordPolicy.preventReuseNumber;
          if (data.AuthenticationConfig.twoFactorConfig) {
            parameters[GroupFormKey[GroupFormKey.password_two_factor_required]].value = !!data.AuthenticationConfig
              .twoFactorConfig.isRequired;
          }
        } else {
          parameters[GroupFormKey[GroupFormKey.password_status]].value = IGroupStatus.disabled;
        }
        
        if (data.AuthenticationConfig.thirdPartyAuthConfigs && this.ssoEnabled) {
          // Third Party Authentication Configuration
          parameters[GroupFormKey[GroupFormKey.auth_third_party_status]].value = data.AuthenticationConfig?.thirdPartyAuthStatus || IGroupStatus.disabled;
          parameters[GroupFormKey[GroupFormKey.auth_third_party_config]].value =
            data.AuthenticationConfig?.thirdPartyAuthConfigs;
        }
      } else {
        parameters[GroupFormKey[GroupFormKey.password_status]].value = IGroupStatus.disabled;
        parameters[GroupFormKey[GroupFormKey.auth_third_party_status]].value = IGroupStatus.disabled;
      }

      this.loadStatus(GroupFormKey.password_status, parameters);

      if(this.ssoEnabled)
      {
        this.loadStatus(GroupFormKey.auth_third_party_status, parameters);
      }

      if(data.remoteDesktopConnection)
      {
        parameters[GroupFormKey[GroupFormKey.remote_desktop_enabled]].value = true;
        parameters[GroupFormKey[GroupFormKey.remote_desktop_secret_key]].value = data.remoteDesktopConnection.secretkey;
        parameters[GroupFormKey[GroupFormKey.remote_desktop_broker_connection_string]].value = data.remoteDesktopConnection.brokerConnectionString;
        parameters[GroupFormKey[GroupFormKey.remote_desktop_connections]].value = data.remoteDesktopConnection.remoteDesktopConnections ? 
          data.remoteDesktopConnection.remoteDesktopConnections : [];      
      }

      if (data.thirdPartyAPIIntegration) {
        parameters[GroupFormKey[GroupFormKey.third_party_api_integration_enabled]].value = true;
        parameters[GroupFormKey[GroupFormKey.third_party_api_integration_google_maps_api_key]].value = data.thirdPartyAPIIntegration.googleMapsAPIKey;
      }

      return Promise.resolve(parameters);
    });
  }

  convertParametersToGroup(parameters: { [key: string]: IParameter }): IGroup {
    let group: IGroup = { name: null };
    let parameterValues: { [key: string]: any } = FormService.collectParameterValues(parameters);
    if (parameterValues[GroupFormKey[GroupFormKey.group_id]]) {
      group["_id"] = parameterValues[GroupFormKey[GroupFormKey.group_id]];
    }
    group.name = parameterValues[GroupFormKey[GroupFormKey.group_name]];
    group.licenseKey = parameterValues[GroupFormKey[GroupFormKey.group_license]];
    if (!group.licenseKey) {
      group.license = null;
    }
    group.licenseAlertEmails = parameterValues[GroupFormKey[GroupFormKey.group_license_alert_emails]];
    group.parentId = parameterValues[GroupFormKey[GroupFormKey.group_parent]]._id;
    if (group["_id"] === group.parentId) {
      group.parentId = _.isEmpty(parameterValues[GroupFormKey[GroupFormKey.group_parent_id]])
        ? null
        : parameterValues[GroupFormKey[GroupFormKey.group_parent_id]];
    }
    group.kioskUnlock = parameterValues[GroupFormKey[GroupFormKey.group_kiosk_unlock]];
    if (!group.kioskUnlock) {
      group.kioskUnlock = undefined;
    }

    this.convertParameterValuesToGroupMessageConfig(parameterValues, group);
    this.convertParameterValuesToGroupMessageTemplates(parameterValues, group);
    this.convertParameterValuesToGroupTwilioConfig(parameterValues, group);

    group.AuthenticationConfig = {};
    
    group.AuthenticationConfig.thirdPartyAuthStatus =
      parameterValues[GroupFormKey[GroupFormKey.auth_third_party_status]];
    group.AuthenticationConfig.thirdPartyAuthConfigs =
      parameterValues[GroupFormKey[GroupFormKey.auth_third_party_config]];
    group.AuthenticationConfig.twoFactorConfig = {};
    group.AuthenticationConfig.twoFactorConfig.isRequired =
      parameterValues[GroupFormKey[GroupFormKey.password_two_factor_required]];

    // Password Policy Configuration
    group.AuthenticationConfig.passwordPolicy = {};
    group.AuthenticationConfig.passwordPolicyStatus = parameterValues[GroupFormKey[GroupFormKey.password_status]];
    group.AuthenticationConfig.passwordPolicy.minimumLength =
      parameterValues[GroupFormKey[GroupFormKey.password_minimum_length]];
    group.AuthenticationConfig.passwordPolicy.maximumLength =
      parameterValues[GroupFormKey[GroupFormKey.password_maximum_length]];
    group.AuthenticationConfig.passwordPolicy.minimumUppercase =
      parameterValues[GroupFormKey[GroupFormKey.password_minimum_uppercase]];
    group.AuthenticationConfig.passwordPolicy.minimumLowercase =
      parameterValues[GroupFormKey[GroupFormKey.password_minimum_lowercase]];
    group.AuthenticationConfig.passwordPolicy.minimumNumbers =
      parameterValues[GroupFormKey[GroupFormKey.password_minimum_numbers]];
    group.AuthenticationConfig.passwordPolicy.minimumSpecialCharacters =
      parameterValues[GroupFormKey[GroupFormKey.password_minimum_characters]];
    group.AuthenticationConfig.passwordPolicy.forbiddenCharacters =
      parameterValues[GroupFormKey[GroupFormKey.password_forbidden_characters]];
    group.AuthenticationConfig.passwordPolicy.enablePasswordExpiration = parameterValues[
      GroupFormKey[GroupFormKey.password_enable_expiration]
      ]
      ? parameterValues[GroupFormKey[GroupFormKey.password_enable_expiration]]
      : false;
    group.AuthenticationConfig.passwordPolicy.passwordValidDays =
      parameterValues[GroupFormKey[GroupFormKey.password_valid_days]];
    group.AuthenticationConfig.passwordPolicy.enableExpirationWarning =
      parameterValues[GroupFormKey[GroupFormKey.password_warning_email]];
    group.AuthenticationConfig.passwordPolicy.passwordWarnDays =
      parameterValues[GroupFormKey[GroupFormKey.password_warning_duration]];
    group.AuthenticationConfig.passwordPolicy.enableLockout =
      parameterValues[GroupFormKey[GroupFormKey.password_enable_lockout]];
    group.AuthenticationConfig.passwordPolicy.failedAttemptsLockout =
      parameterValues[GroupFormKey[GroupFormKey.password_lockout_attempts]];
    group.AuthenticationConfig.passwordPolicy.lockoutDuration =
      parameterValues[GroupFormKey[GroupFormKey.password_lockout_duration]];
    group.AuthenticationConfig.passwordPolicy.disableInactiveAccounts =
      parameterValues[GroupFormKey[GroupFormKey.password_disable_inactive]];
    group.AuthenticationConfig.passwordPolicy.disableInactiveDuration =
      parameterValues[GroupFormKey[GroupFormKey.password_inactive_after_days]];
    group.AuthenticationConfig.passwordPolicy.storeOldPasswords =
      parameterValues[GroupFormKey[GroupFormKey.password_store_old]];
    group.AuthenticationConfig.passwordPolicy.preventReuseNumber =
      parameterValues[GroupFormKey[GroupFormKey.password_prevent_reuse_number]];

    group.VccConfig = {};
    group.VccConfig.ringtonePanelDuration = parameterValues[GroupFormKey[GroupFormKey.vcc_call_ring_duration]];
    group.VccConfig.requireGuestEntryId = parameterValues[GroupFormKey[GroupFormKey.vcc_require_guest_entry_id]];
    group.VccConfig.redirectOnDenyEntryUrl = parameterValues[GroupFormKey[GroupFormKey.vcc_redirect_on_guest_entry_deny]];
    
    group.queueConfig = {};
    group.queueConfig.status =
      parameterValues[GroupFormKey[GroupFormKey.skill_set_status]];

    // clear the skill set if we are disabled...

    if(group.queueConfig.status === IGroupStatus.custom)
    {
      let categoryIndex = parameterValues[GroupFormKey[GroupFormKey.skill_set_default_category]];
      let langIndex = parameterValues[GroupFormKey[GroupFormKey.skill_set_default_language]];

      group.queueConfig.skillTags = {
        category: parameters[GroupFormKey[GroupFormKey.skill_set_default_category]].options[categoryIndex],
        language:  parameters[GroupFormKey[GroupFormKey.skill_set_default_language]].options[langIndex]
      };
  
      group.queueConfig.skillSet = {
        categorySubskills: this.getCategoriesSubskillsFromTreeView(this.categoriesTree),
        languages: parameterValues[GroupFormKey[GroupFormKey.skill_set_languages]]
      };
    }
    else
    {
      group.queueConfig.skillTags = null;
      group.queueConfig.skillSet = null;
    }

    group.remoteDesktopConnection = {
      enabled: true,
      secretkey: parameterValues[GroupFormKey[GroupFormKey.remote_desktop_secret_key]],
      brokerConnectionString: parameterValues[GroupFormKey[GroupFormKey.remote_desktop_broker_connection_string]],
      remoteDesktopConnections : parameterValues[GroupFormKey[GroupFormKey.remote_desktop_connections]]
    };     
    
    group.thirdPartyAPIIntegration = {
      enabled: true,
      googleMapsAPIKey: parameterValues[GroupFormKey[GroupFormKey.third_party_api_integration_google_maps_api_key]],
    };

    group.hoursOfOperation = {}
    group.hoursOfOperation.status = parameterValues[GroupFormKey[GroupFormKey.hours_of_operation_status]];
    if (group.hoursOfOperation.status === IGroupStatus.custom) {
      group.hoursOfOperation.operatingHours = parameters[GroupFormKey[GroupFormKey.hours_of_operation_operating_hours]].options;
    }
    else {
      group.hoursOfOperation.operatingHours = null;
    }

    return group;
  }

  SubmitGroupForm(payload: any) {
    let groupData = this.convertParametersToGroup(payload.data);
    let errorMessage = this.validateGroup(groupData);
    
    if(errorMessage)
    {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(errorMessage, errorMessage, AlertLevel.prominent, 10),
      });
      return;
    }

    this.editGroup(groupData)
    .then(() => {
      Dispatcher.dispatch(ActionType.HideModalDialog, "group-form");
      Dispatcher.dispatch(ActionType.LoadGroupData);
    })
    .catch((err: Error) => {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert("EDIT_GROUP_FAIL", err.message, AlertLevel.prominent, 10),
      });
    });
  }

  purgeReportData(purgeRequest: IPurgeRequest): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      this.restService.post("/purgeReportData", purgeRequest).subscribe(
        (res: any) => {
          resolve();
        },
        (error: any) => {
          console.error(error);
          reject(new Error(error._body));
        }
      );
    });
  }

  /**
   * Make sure the group configuration is valid, returns null if valid, 
   * other wise return error message.
   */
  validateGroup(group : IGroup) : string 
  {
    // check that if we are using skills, we have at least one language and one skill.
    if(group?.queueConfig?.status == IGroupStatus.custom)
    {
      if(!group?.queueConfig?.skillSet?.languages?.length)
      {
        return "Queue config requires a language option."
      }

      if (!group?.queueConfig?.skillSet.categorySubskills?.length)
      {
        return "Queue config requires a skill option."
      }
    }

    // check that if we are using 3P auth that we only have one of each type.
    if (group?.AuthenticationConfig?.thirdPartyAuthConfigs) {
      for (const proto in ThirdPartyAuthProtocol ) {
        for (const provider in ThirdPartyAuthProvider) {
          let configs = _.filter(group.AuthenticationConfig.thirdPartyAuthConfigs, (authConfig: IThirdPartyAuthConfig) => {
            return authConfig.protocol === proto && authConfig.provider === provider
          });
          if (configs?.length > 1) {
            return "Cannot have duplicate protocol/provider authentication options per group."
          }
        }
      }
    }

    if (group?.hoursOfOperation?.status == IGroupStatus.custom) {
      const dateRegexp = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      let daysOfWeekHours: ISpecificDayHours[] = group?.hoursOfOperation?.operatingHours?.daysOfWeekHours;
      if (daysOfWeekHours) {
        for (var index = 0; index < daysOfWeekHours.length; index++) {
          var item = daysOfWeekHours[index];
          if (item.startTime != "") {
            if (!timeRegex.test(item.startTime)) {
              return "Hours of Operation: Specify a valid Start Time for Day OF Week: " + item.theDay;
            }
          }
          if (item.endTime != "") {
            if (!timeRegex.test(item.endTime)) {
              return "Hours of Operation: Specify a valid End Time for Day OF Week: " + item.theDay;
            }
          }
          if (item.startTime != "" && item.endTime != "" && item.startTime >= item.endTime) {
            return "Hours of Operation: Specify a Start Time that is less than End Time for Day OF Week: " + item.theDay;
          }
        };
      }
      let specialDayHours: ISpecificDayHours[] = group?.hoursOfOperation?.operatingHours?.specialDayHours;
      if (specialDayHours) {
        console.log("specialDayHours:", specialDayHours);
        for (var index = 0; index < specialDayHours.length; index++) {
          var item = specialDayHours[index];
          if (item.theDay == "") {
            return "Hours of Operation: Specify a Valid Date in Special Dates in row:" + (index + 1);
          }
          else if (item.theDay != "") {
            if (!dateRegexp.test(item.theDay)) {
              return "Hours of Operation: Specify a valid Date for Special Date: " + item.theDay + " found in row " + (index + 1);
            }
          }
          var foundIndex = index;
          if (index != (foundIndex = specialDayHours.findIndex(o => item.theDay === o.theDay))) {
            return "Hours of Operation: Remove Duplicate Special Date: " + item.theDay + " found in row " + (index + 1) + " which is already present in row " + (foundIndex + 1);
          }
          if (item.startTime != "") {
            if (!timeRegex.test(item.startTime)) {
              return "Hours of Operation: Specify a valid Start Time for Special Date: " + item.theDay + " found in row " + (index + 1);
            }
          }
          if (item.endTime != "") {
            if (!timeRegex.test(item.endTime)) {
              return "Hours of Operation: Specify a valid End Time for Special Date: " + item.theDay + " found in row " + (index + 1);
            }
          }
          if (item.startTime != "" && item.endTime != "" && item.startTime >= item.endTime) {
            return "Hours of Operation: Specify a Start Time that is less than End Time for Special Date: " + item.theDay + " found in row " + (index + 1);
          }
        };
      }
    }
    return null;
  }

  getCategoriesTreeFromGroup(data: IGroup): TreeViewItem[] {
    let treeViewRoots: TreeViewItem[] = [];
    try {
      if (data?.queueConfig?.skillSet?.categorySubskills) {
        let categorySubskills: ICategory[] = data?.queueConfig?.skillSet?.categorySubskills;
        treeViewRoots = this.getCategoriesTreeFromCategorySubskills(categorySubskills);
      }
    }
    catch (err) {
      console.error("getCategoriesTreeFromGroup", err);
    };
    return treeViewRoots;
  }

  getCategoriesTreeFromCategorySubskills(categorySubskills: ICategory[]): TreeViewItem[] {
    let treeViewRoots: TreeViewItem[] = [];
    try {
      if (categorySubskills && !_.isEmpty(categorySubskills)) {
        treeViewRoots = _.filter(_.map(categorySubskills, (root: ICategory) => {
          let treeViewItem: TreeViewItem = this.getCategoryTreeFromCategory(root);
          if (treeViewItem) {
            return treeViewItem;
          }
        }));
      }
    }
    catch (err) {
      console.error("getCategoriesTreeFromCategorySubskills", err);
    };
    return treeViewRoots;
  }

  getCategoryTreeFromCategory(category: ICategory): TreeViewItem {
    let item: TreeViewItem = {
      text: category.name,
      value: category,
      collapsed: false
    };
    if (category.subskills && !_.isEmpty(category.subskills)) {
      let children: TreeViewItem[] = [];
      _.forEach(category.subskills, (childCategory: ICategory) => {
        children.push(this.getCategoryTreeFromCategory(childCategory));
      });
      item.children = _.orderBy(children, ["text"]);
    }
    return item;
  }

  getCategoriesListFromGroup(data: IGroup): string[] {
    let categoriesList: string[] = [];
    try {
      if (data?.queueConfig?.skillSet?.categorySubskills) {
        let categorySubskills: ICategory[] = data?.queueConfig?.skillSet?.categorySubskills;
        categoriesList = ConferenceUtil.getCategoriesListFromCategorySubskills(categorySubskills);
      }
    }
    catch (err) {
      console.error("getCategoriesListFromGroup error", err);
    };
    return categoriesList;
  }

  getCategoriesList() : string[] {
    return this.getCategoriesListFromTreeView(this.categoriesTree, false);
  }

  getCategoriesListFromTreeView(roots: TreeViewItem[], selectedOnly: boolean = false): string[] {
    let categorySubskills: string[] = [];
    _.forEach(roots, (root: TreeViewItem) => {
      if ((selectedOnly == false) || (selectedOnly == true && root.selected)) {
        categorySubskills.push(root.value.name);
      }
      if (root.children) {
        let categorySubskillsChildren: string[] = this.getCategoriesListFromTreeView(root.children, selectedOnly);
        categorySubskills.push(...categorySubskillsChildren);
      }
    });
    return categorySubskills;
  }

  getCategoriesSubskillsFromTreeView(roots: TreeViewItem[]): ICategory[] {
    let categorySubskills: ICategory[] = [];
    _.forEach(roots, (root: TreeViewItem) => {
      let category: ICategory = { name: root.value.name, subskills: root.children? this.getCategoriesSubskillsFromTreeView(root.children) : null }
      categorySubskills.push(category);
    });
    return categorySubskills;
  }

  getSkillProficiencyListFromTreeView(roots: TreeViewItem[]): ISkillProficiency[] {
    let categorySubskills: ISkillProficiency[] = [];
    _.forEach(roots, (root: TreeViewItem) => {
      if (root.selected) {
        categorySubskills.push({ skill: root.value.name, proficiencyLevel: root.value.proficiencyLevel ? root.value.proficiencyLevel : 1});
      }
      if (root.children) {
        let categorySubskillsChildren: ISkillProficiency[] = this.getSkillProficiencyListFromTreeView(root.children);
        categorySubskills.push(...categorySubskillsChildren);
      }
    });
    return categorySubskills;
  }

  findTreeItemByCategoryName(name: string, roots: TreeViewItem[], maxLevelsSupported: number): TreeViewItem {
    let result: TreeViewItem;
    _.forEach(roots, (root: TreeViewItem) => {
      result = this.findTreeItemByCategoryNameUnderRoot(name, root, 1, maxLevelsSupported);
      if (result) {
        // this is the way to break from lodash forEach
        return false;
      }
    });
    return result;
  }

  findTreeItemByCategoryNameUnderRoot(name: string, root: TreeViewItem, level: number, maxLevelsSupported: number): TreeViewItem {
    let result: TreeViewItem;
    if (root.value.name == name) {
      result = root;
    } else if (root.children) {
      if (level < maxLevelsSupported) {
        _.forEach(root.children, (child: TreeViewItem) => {
          result = this.findTreeItemByCategoryNameUnderRoot(name, child, (level + 1), maxLevelsSupported);
          if (result) {
            // this is the way to break from lodash forEach
            return false;
          }
        });
      }
    }
    return result;
  }

  AddCategorySubskill(newCategorySubskillName: string, parentCategorySubskillName: string) {
    let newCategorySubskillTreeViewItem: TreeViewItem = this.findTreeItemByCategoryName(newCategorySubskillName, this.categoriesTree, GroupManagementService.cMAX_CATEGORY_SUBSKILL_LEVELS_SUPPORTED);
    if (!newCategorySubskillTreeViewItem) {
      // Add only if the new caterory subkill doesn't already exit
      let newCategory: ICategory = {
        name: newCategorySubskillName,
        subskills: null
      };
      let item: TreeViewItem = {
        text: newCategorySubskillName,
        value: newCategory,
        collapsed: false,
      };
      if (!parentCategorySubskillName) {
        // Parent not speciied, Add at top level
        this.categoriesTree.push(item);
      }
      else {
        let parentTreeViewItem: TreeViewItem = this.findTreeItemByCategoryName(parentCategorySubskillName, this.categoriesTree, GroupManagementService.cMAX_CATEGORY_SUBSKILL_LEVELS_SUPPORTED - 1);
        if (parentTreeViewItem) {
          if (parentTreeViewItem.children) {
            // Parent speciied has children, add to existing list
            parentTreeViewItem.children.push(item);
            parentTreeViewItem.children = _.orderBy(parentTreeViewItem.children, ["text"]);
          }
          else {
            // Parent speciied has no children, add new list
            let children: TreeViewItem[] = [];
            children.push(item);
            parentTreeViewItem.children = children;
          }
        }
        else {
           // Parent speciied is not at the level we can add to,  so add at top level
          this.categoriesTree.push(item);
        }
      }
    }
    else {
      //new caterory subkill already exits!
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(AlertCode.categorySkillAddFailed, "Catergory Subskill already exists! Use a different name", AlertLevel.error, 5),
      });
    }
  }

  getHoursOfOperationFromGroup(data: IGroup): IOperatingHours {
    // Default timezone is client's timezone
    let defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // Default hours are M-F 8am-5pm
    let defaultHoursOfOperation: ISpecificDayHours[] = [
      { theDay: "Sunday", open: false, startTime: "", endTime: "" },
      { theDay: "Monday", open: true, startTime: "08:00", endTime: "17:00" },
      { theDay: "Tuesday", open: true, startTime: "08:00", endTime: "17:00" },
      { theDay: "Wednesday", open: true, startTime: "08:00", endTime: "17:00" },
      { theDay: "Thursday", open: true, startTime: "08:00", endTime: "17:00" },
      { theDay: "Friday", open: true, startTime: "08:00", endTime: "17:00" },
      { theDay: "Saturday", open: false, startTime: "", endTime: "" }];
    let defaultOperatingHours: IOperatingHours = { operatingTimezone: defaultTimezone, daysOfWeekHours: defaultHoursOfOperation };
    try {
      if (data?.hoursOfOperation?.operatingHours) {
        let operatingHours: IOperatingHours = data?.hoursOfOperation?.operatingHours;
        if (!operatingHours.operatingTimezone) {
          operatingHours.operatingTimezone = defaultTimezone;
        }
        if (!operatingHours.daysOfWeekHours) {
          operatingHours.daysOfWeekHours = defaultHoursOfOperation;
        }
        return operatingHours;
      }
    }
    catch (error) {
      console.error("group management getHoursOfOperationFromGroup error:", error);
    };
    return defaultOperatingHours;
  }

  convertHoursOfOperationToParameters(group: IGroup, parameterValues: { [key: string]: any }) {
    parameterValues[GroupFormKey[GroupFormKey.hours_of_operation_operating_hours]].options = this.getHoursOfOperationFromGroup(group);
    if (group?.hoursOfOperation) {
      parameterValues[GroupFormKey[GroupFormKey.hours_of_operation_status]].value = group.hoursOfOperation.status;
      if (group.hoursOfOperation?.operatingHours) {
        parameterValues[GroupFormKey[GroupFormKey.hours_of_operation_enabled]].value = true;
      }
    }
    else {
      parameterValues[GroupFormKey[GroupFormKey.hours_of_operation_status]].value = IGroupStatus.disabled;
    }
    this.loadStatus(GroupFormKey.hours_of_operation_status, parameterValues);
  }

}

export enum GroupFormKey {
  group_enabled,
  group_id,
  group_name,
  group_license,
  group_license_alert_emails,
  group_available_op_count_in_license,
  group_parent,
  group_parent_id,
  group_themes,
  group_kiosk_unlock,
  report_enabled,
  report_vcc_system,
  report_vcc_agent,
  report_vcc_customer,
  report_vcc_kiosk,
  report_reservation,
  messaging_config_enabled,
  messaging_config_status,
  messaging_config_sms_provider,
  messaging_config_sms_api_key,
  messaging_config_sms_api_secret,
  messaging_config_sms_account_id,
  messaging_config_sms_account_password,
  messaging_config_sms_from_number,
  messaging_config_sms_sender_id,

  messaging_config_email_host,
  messaging_config_email_port,
  messaging_config_email_is_secure,
  messaging_config_email_account_username,
  messaging_config_email_account_password,
  messaging_config_email_sender_from,

  message_template_enabled,
  message_template_status,
  message_template_guest_create_email_template,
  message_template_guest_create_email_subject,
  message_template_guest_edit_email_template,
  message_template_guest_edit_email_subject,
  message_template_guest_cancel_email_template,
  message_template_guest_cancel_email_subject,
  message_template_guest_reminder_email_template,
  message_template_guest_reminder_email_subject,
  message_template_guest_invite_email_template,
  message_template_guest_invite_email_subject,
  message_template_host_confirmation_email_template,
  message_template_host_confirmation_email_subject,
  password_enabled,
  password_status,
  password_minimum_length,
  password_maximum_length,
  password_minimum_uppercase,
  password_minimum_lowercase,
  password_minimum_numbers,
  password_minimum_characters,
  password_forbidden_characters,
  password_enable_expiration,
  password_warning_email,
  password_warning_duration,
  password_valid_days,
  password_enable_lockout,
  password_lockout_attempts,
  password_lockout_duration,
  password_disable_inactive,
  password_inactive_after_days,
  password_store_old,
  password_prevent_reuse_number,
  password_two_factor_required,
  auth_third_party_enabled,
  auth_third_party_status,
  auth_third_party_config,
  twilio_account_enabled,
  twilio_account_status,
  twilio_account_sid,
  twilio_account_token,
  twilio_account_app_sid,
  twilio_account_phone_numbers,
  twilio_account_edge_locations,
  vcc_enabled,
  vcc_call_ring_duration,
  vcc_require_guest_entry_id,
  vcc_redirect_on_guest_entry_deny,
  skill_set_enabled,
  skill_set_status,
  skill_set_categories,
  skill_set_categories_subskills,
  skill_set_categories_subskills_new,
  skill_set_languages, 
  skill_set_default_category,
  skill_set_default_language,
  remote_desktop_enabled,
  remote_desktop_secret_key,
  remote_desktop_broker_connection_string,
  remote_desktop_connections,
  third_party_api_integration_enabled,
  third_party_api_integration_google_maps_api_key,
  hours_of_operation_enabled,
  hours_of_operation_status,
  hours_of_operation_operating_hours,
}

export interface IPurgeRequest {
  groupId?: string;
  date?: Date;
}
