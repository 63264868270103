/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */

import { Injectable } from "@angular/core";
import { RestService } from "../shared/services/rest.service";
import { IUser, Companion, IRole, PermissionLevel, PermissionType, IGroup, ILocalization } from "companion";
import { GroupManagementService } from "../group-management/group-management.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { LocalizationService } from "./../localization/localization.service";
import { Store } from "../Store/store.service";

@Injectable()
export class UserManagementService {
  
  users: IUser[];
  constructor(
    private restService: RestService,
    private groupManagementService: GroupManagementService,
    private localizationService: LocalizationService,
    private store: Store
  ) {
    Dispatcher.register(ActionType.LogOut, this.onLogOutAction.bind(this));
  }

  /**
   * system current user
   */
  private currentUser: IUser = Companion.getUserService().currentUser;

  // Get the users
  getUsers(): Promise<IUser[]> {
    return new Promise((resolve: (response: IUser[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getUsers", {})
      .subscribe(
        (data: IUser[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  getUserById(id: string): Promise<IUser> {
    if (this.users) {
      let result = _.find(this.users, { _id: id }) as IUser;
      return Promise.resolve(result);
    } else {
      return this.getMyAccessibleUsersWithRolesAndGroups()
      .then((users: IUser[]) => {
        if (users == null) {
          return Promise.resolve(null);
        }
        let result = _.find(users, { _id: id }) as IUser;
        return Promise.resolve(result);
      })
      .catch(() => {
        return Promise.resolve(null);
      });
    }
  }

  // Get my accessible users with roles and groups
  getMyAccessibleUsersWithRolesAndGroups(): Promise<any[]> {
    return new Promise((resolve: (response: any[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getMyAccessibleUsersWithRolesAndGroups", {})
      .subscribe(
        (data: any[]) => {
          this.users = data;
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  // get user with roles and groups by userId
  getUserWithRolesAndGroupsById(userId: string): Promise<any> {
    return new Promise((resolve: (response: any[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getUserWithRolesAndGroupsById", { userId: userId })
      .subscribe(
        (data: any) => {
          _.merge(Companion.getUserService().currentUser, data);
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   *  create a user
   */
  createUser(
    user: IUser,
    localizationData: ILocalization = this.localizationService.myLocalizationData
  ): Promise<IUser> {
    return new Promise((resolve: (response: IUser) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/createUser", { user: user, theme: this.localizationService.myLocalizationData.style })
      .subscribe(
        (data: IUser) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * delete a user
   * promises a boolean indicating if the delete succeeded
   */
  deleteUser(username: String): Promise<boolean> {
    return new Promise((resolve: (response: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/deleteUser", { username: username })
      .subscribe(
        (data: boolean) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * Force a user to reset there password at nect login
   * promises a boolean indicating if the reset was successful
   */
  forcePasswordReset(username: String): Promise<boolean> {
    return new Promise((resolve: (response: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/forcePasswordReset", { username: username })
      .subscribe(
        (data: boolean) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * edit user profile
   */
  editUserProfile(user: IUser): Promise<IUser> {
    return new Promise((resolve: (response: IUser) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/editUserProfile", user)
      .subscribe(
        (data: IUser) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * disable, or enable user
   */
  disableUser(user: IUser, disable: Boolean): Promise<IUser> {
    return new Promise((resolve: (response: IUser) => void, reject: (error: Error) => void) => {
      let request: any = {};
      request.username = user.username;
      request.disable = disable;

      this.restService
      .post("/disableUser", request)
      .subscribe(
        (data: IUser) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get the roles on the system
   */
  getRoles(): Promise<IRole[]> {
    return new Promise((resolve: (response: IRole[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getMyAssignableRoles", {
        user: this.currentUser
      })
      .subscribe(
        (data: IRole[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * get my user's roles
   */
  getMyRoles(): Promise<IRole[]> {
    return new Promise((resolve: (response: IRole[]) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getMyRoles", { user: this.currentUser })
      .subscribe(
        (data: IRole[]) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * flag if has permission of action on user
   */
  hasPermissionOfActionOnUser(action: PermissionType, user: IUser) {
    switch (parseInt(this.currentUser.permissions[action], 10)) {
      case PermissionLevel.Always:
        return true;
      case PermissionLevel.InGroup:
      case PermissionLevel.InGroupOrPublic:
        return this.isUserInMyGroup(user);
      case PermissionLevel.Public:
      case PermissionLevel.None:
      default:
        return false;
    }
  }

  /**
   * flag if has permission to edit
   */
  hasPermissionToEdit(user: IUser) {
    return this.hasPermissionOfActionOnUser(PermissionType.modifyUsers, user);
  }

  /**
   * flag if has permission to delete
   */
  hasPermissionToDelete(user: IUser) {
    return this.hasPermissionOfActionOnUser(PermissionType.deleteUsers, user);
  }

  /**
   * is user under in group
   */
  isUserInMyGroup(user: IUser): boolean {
    return _.every(user.groups, (group: IGroup) => {
      return (
        this.groupManagementService.isGroupInMyAllAccessibleGroupsById(group["_id"]) ||
        _.includes(_.map(this.currentUser.groups, "_id"), group["_id"])
      );
    });
  }

  /**
   * check if user by user id has permission to action on target
   */
  hasPermissionToActionOnUserByUserId(userId: string, action: PermissionType, target: string): Promise<boolean> {
    return new Promise((resolve: (response: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/hasPermissionToActionOnUserByUserId", {
        userId: userId,
        action: PermissionType[action],
        target: target
      })
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * get my permissions
   */
  getMyPermissions(): Promise<any> {
    return this.restService
    .getMyPermissions()
    .then((result: any) => {
      Companion.getUserService().currentUser.permissions = result;
      return Promise.resolve(result);
    })
    .catch((err: any) => {
      Companion.getUserService().currentUser.permissions = {};
      return Promise.resolve({});
    });
  }

  /**
   * check if user authenticated
   */
  isAuthenticated(): Promise<boolean> {
    return this.restService
    .isAuthenticated()
    .then((result: any) => {
      Companion.getUserService().currentUser.isAuthenticated = true;
      _.assignInWith(Companion.getUserService().currentUser, result, (objValue, srcValue) => {
        return _.isUndefined(objValue) ? srcValue : objValue;
      });
      return Promise.resolve(true);
    })
    .catch((err: any) => {
      return Promise.resolve(false);
    });
  }

  promptForTwoFactorAuthTokenValidation(): Promise<boolean> {
    return new Promise((resolve: (result: boolean) => void, reject: (error: Error) => void) => {
      bootbox.prompt("Please enter your Two Factor Authentication Token", (token: string) => {
        this.validateTwoFactorAuthToken(token)
        .then((result: boolean) => {
          resolve(result);
        })
        .catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  validateTwoFactorAuthToken(token: string): Promise<boolean> {
    return new Promise((resolve: (result: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/totp-validate", {
        token: token
      })
      .subscribe(
        (data: any) => {
          resolve(data.valid);
        },
        (error: any) => {
          console.error(error);
          reject(new Error(error._body));
        }
      );
    });
  }

  getUserBasicInfo(): Promise<IUser> {
    return new Promise((resolve: (result: IUser) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getUserBasicInfo", {})
      .subscribe(
        (data: any) => {
          resolve(data.user);
        },
        (error: any) => {
          console.error(error);
          reject(new Error(error._body));
        }
      );
    });
  }

  /**
   * logout
   */
  logout(): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      Companion.getUserService().currentUser.isAuthenticated = false;
      this.restService.logout().then((data : any) => {
        resolve(data);
      }).catch((error : any) =>
      {
        reject(error);
      });
    });
  }

  /**
   * on logout action handler
   */
  onLogOutAction(payload: any): void {
    this.logout()
      .catch((error: any) => {
        console.error(error);
      })
      .then(() => {
        if (payload && payload.callback) {
          Dispatcher.dispatch(payload.callback);
        }
      });
  }

  /**
   * check if Dashboard is enabled in the license
   */
  checkIfDashboardIsEnabled() {
    this.restService.dashboardIsEnabled()
    .then(() => {
      this.store.update("dashboardEnabled", true);
      return Promise.resolve(true);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
  }
}
