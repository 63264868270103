/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IMessage } from "./message.interface";

export class Message implements IMessage {
  constructor(
    public fromEndpointId: string,
    public fromEndpointName: string,
    public toEndpointIds: string[],
    public toEndpointNames: string[],
    public content: string,
    public roomOwnerRtcId: string,
    public sendDate: Date = new Date(),
    public confirmAction?: () => void
  ) {
    // nothing needed here
  }
}
