<!--
    Copyright Compunetix Incorporated 2016-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<ng-container *ngIf="localizationService.myLocalizationData?.toolbar">
  <nav
    role="navigation"
    class="navbar navbar-expand-lg fixed-top bd-navbar bg-faded conference-header-nav navbar-text"
    [ngClass]="{'fadeIn': isNavShown, 'animated fadeOut': !isNavShown}"
    [style.backgroundColor]="localizationService.myLocalizationData.toolbar.backgroundColor"
    [style.fontFamily]="localizationService.myLocalizationData.toolbar.fontFamily"
    [style.borderColor]="borderColor"
    [style.borderTopWidth]="borderWidth"
    [style.borderBottomWidth]="borderWidth"
    [style.color]="navBarService.getColor()"
    *ngIf="!isMobileApp"
  >
  <ul class="nav navbar-nav float-left d-lg-none">
    <ng-container *ngFor="let menuItem of navBarService.leftStaticMenu">
      <li
        class="nav-item"
        [ngClass]="{'shift-down': menuItem.key === NavBarMenuItemKey.ActiveTalker}"
        *ngIf="!isLocked || menuItem.key === NavBarMenuItemKey.Lock"
         (mouseenter)="onMouseEnter(menuItem)" (mouseleave)="onMouseLeave(menuItem)"
      >
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: menuItem, displayTitle: true}"></ng-container>
      </li>
    </ng-container>
  </ul>
    <div class="clearfix">
      <button
        class="btn btn-link navbar-toggler d-lg-none p-0 ml-3 float-right"
        id="menu-button"
        [style.color]="navBarService.getColor()"
        type="button"
        data-toggle="collapse"
        data-target="#bd-main-nav"
        aria-controls="bd-main-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <label
        class="badge badge-pill badge-danger"
        *ngIf="chatMenuItem?.tagCount > 0"
        style="font-size: 100%;"
        >
          {{chatMenuItem.tagCount}}
        </label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="2.5rem"
          height="2.5rem"
          focusable="false"
          role="img"
        >
          <title>Menu</title>
          <path
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-miterlimit="10"
            d="M4 7h22M4 15h22M4 23h22"
          >
          </path>
        </svg>
      </button>
      <label
        class="navbar-brand pull-center d-lg-none"
        tabindex="0"
        [attr.aria-label]="getAriaLabelOfLogo()"
        [style.color]="localizationService.myLocalizationData.toolbar.titleColor"
      >
        {{localizationService.myLocalizationData.toolbar?.leftTitle || ""}}
        <img
          *ngIf="localizationService.myLocalizationData.toolbar.logo"
          [attr.src]="localizationService.myLocalizationData.toolbar.logo"
          crossorigin="anonymous"
        />
        <span *ngIf="!localizationService.myLocalizationData.toolbar.logo">
        {{this.localizationService.getValueByPath(".toolbar.logoAriaLabel") ?? ""}}
        </span>
        {{localizationService.myLocalizationData.toolbar?.rightTitle || ""}}
    </label>
    </div>
    <div
      class="collapse navbar-collapse text-center"
      id="bd-main-nav"
    >
      <ul class="nav navbar-nav navbar-expand-sm float-left">
        <ng-container *ngFor="let menuItem of navBarService.leftMenu">
          <li
            class="nav-item"
            *ngIf="!isLocked || menuItem.key === NavBarMenuItemKey.Lock"
             (mouseenter)="onMouseEnter(menuItem)" (mouseleave)="onMouseLeave(menuItem)"
            [ngClass]="{'d-none d-lg-block':menuItem.position === NavBarMenuPosition.LeftStatic}"
          >
            <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: menuItem, displayTitle: true}"></ng-container>
          </li>
        </ng-container>
      </ul>
      <ul class="nav navbar-nav navbar-expand-sm">
        <li class="nav-item">
          <label
            class="navbar-brand"
            tabindex="0"
            [attr.aria-label]="getAriaLabelOfLogo()"
            [style.color]="localizationService.myLocalizationData.toolbar.titleColor"
          >
          {{localizationService.myLocalizationData.toolbar?.leftTitle || ""}}
          <img
            *ngIf="localizationService.myLocalizationData.toolbar.logo"
            [attr.src]="localizationService.myLocalizationData.toolbar.logo"
            crossorigin="anonymous"
          />
          <span *ngIf="!localizationService.myLocalizationData.toolbar.logo">
          {{this.localizationService.getValueByPath(".toolbar.logoAriaLabel") ?? ""}}
          </span>
          {{localizationService.myLocalizationData.toolbar?.rightTitle || ""}}
        </label>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-expand-sm float-right">
        <ng-container *ngFor="let menuItem of navBarService.rightMenu">
          <li
            class="nav-item"
            *ngIf="!isLocked || menuItem.key === NavBarMenuItemKey.Lock"
             (mouseenter)="onMouseEnter(menuItem)" (mouseleave)="onMouseLeave(menuItem)"
          >
            <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: menuItem, displayTitle: true}"></ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>

  <nav
    role="navigation"
    class="navbar"
    #toolbar
    [ngClass]="isMobileApp ? 'bottom-nav navbar-expand' : 'top-left-nav d-lg-none'"
    [ngStyle]="{
    'background-color':isMobileApp?localizationService.myLocalizationData.toolbar.backgroundColor:'',
    'top':isMobileApp?'calc(' + navbarTop + 'px - 80px)':''
    }"

  >
    <ul class="nav navbar-nav">
      <ng-container *ngFor="let menuItem of navBarService.leftMenu | filterMenu : isMobileApp">
        <li
          class="nav-item ml-0"
          *ngIf="!isLocked || menuItem.key === NavBarMenuItemKey.Lock"
           (mouseenter)="onMouseEnter(menuItem)" (mouseleave)="onMouseLeave(menuItem)"
          [ngClass]="{'d-none':(menuItem.position === NavBarMenuPosition.LeftStatic) && !isMobileApp}"
        >
          <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: menuItem}"></ng-container>
        </li>
      </ng-container>
      <ng-container *ngFor="let menuItem of navBarService.rightMenu | filterMenu : isMobileApp">
        <li
          class="nav-item ml-0"
          *ngIf="!isLocked || menuItem.key === NavBarMenuItemKey.Lock"
           (mouseenter)="onMouseEnter(menuItem)" (mouseleave)="onMouseLeave(menuItem)"
        >
          <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem: menuItem}"></ng-container>
        </li>
      </ng-container>
      <li
        class="nav-item ml-0"
        [ngClass]="{'d-none':!isMobileApp}"
      >
        <button

          class="nav-link btn btn-link handle-btn"
          (touchstart)="navBarMouseDown($event)"
          (touchmove)="navBarMove($event)"
          (touchend)="navBarMouseUp()"
          [ngClass]="{'active': true}"
        >
          <i
            class="fa fa-grip-vertical"
            aria-hidden="true"
          ></i>
        </button>
      </li>
    </ul>
  </nav>

  <div
    class="popover popover-bottom"
    role="tooltip"
    id="sharelink-popover-content"
    style="display: none;"
  >
    <div class="popover-arrow"></div>
    <div class="popover-content">
      <div class="container-fluid">
        <div class="row">
          <a
            tabindex
            for="example-month-input"
            class="btn col-form-label"
            (click)="copy()"
          >
            <i
              class="fas fa-clone"
              aria-hidden="true"
            ></i>
          </a>
          <div class="fill-up-right-of-btn">
            <input
              class="form-control"
              type="text"
              [id]="'topnavShareUrl'"
              [(ngModel)]="shareUrl"
              readonly="true"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #submenuTemplate let-subMenu="subMenu" let-active="hover">
  <button *ngIf="subMenu" class="btn submenu-button">
    <i class="fas fa-chevron-down"></i>
  </button>
  <ul
    *ngIf="subMenu"
    class="submenu"
    [hidden]="!active"
    [style.color]="navBarService.getColor(menuItem) || '#fff'"
    [style.backgroundColor]="localizationService.myLocalizationData.toolbar.backgroundColor || '#8fad93'"
    [style.fontFamily]="localizationService.myLocalizationData.toolbar.fontFamily"
  >
    <li class="submenu-item" *ngFor="let subMenuItem of subMenu">
      <a class="btn btn-secondary" (click)="onMenuItemClick(subMenuItem)" *ngIf="!subMenuItem.displayCheckbox">
        {{navBarService.getTitle(subMenuItem)}}
      </a>
      <label class="form-check-label" *ngIf="subMenuItem.displayCheckbox">
        <input class="form-check-input margin-left" type="checkbox" value=""
        [(ngModel)]="subMenuItem.isChecked"
        (change)="onMenuItemChecked(subMenuItem)">
        {{navBarService.getTitle(subMenuItem)}}
      </label>
    </li>
  </ul>
</ng-template>

<ng-template #menuItemTemplate let-menuItem="menuItem" let-displayTitle="displayTitle">
  <button
    class="nav-link btn btn-link"
    role="button"
    [attr.aria-label]="navBarService.getAriaLabel(menuItem)"
    [style.color]="navBarService.getColor(menuItem)"
    [style.backgroundColor]="localizationService.myLocalizationData.toolbar.backgroundColor"
    [style.fontFamily]="localizationService.myLocalizationData.toolbar.fontFamily"
    [title]="navBarService.getTitle(menuItem)"
    (click)="onMenuItemClick(menuItem)"
    [ngClass]="{'active': menuItem.active, 'alert' : menuItem.alert}"
    [disabled]="menuItem.disabled"
  >
    <i
      *ngIf="!menuItem.displayWithTagContent"
      class="{{navBarService.getIcon(menuItem)}}"
      aria-hidden="true"
    ></i>
    <label
      class="badge badge-pill badge-{{menuItem.tagColor}}"
      *ngIf="!menuItem.displayWithTagContent && menuItem.tagCount > 0"
    >
      {{menuItem.tagCount}}
    </label>
    <sup *ngIf="navBarService.displayAlertIcon(menuItem)">
        <span class="badge badge-pill badge-info" >
           <i class="{{navBarService.getAlertIcon(menuItem)}}"
              style="font-size:0.8rem"
           ></i>
        </span>
    </sup>
    <ng-container *ngIf="displayTitle">
      <label class="badge-content" *ngIf="menuItem.displayWithTagContent">
        <i
          class="{{navBarService.getIcon(menuItem)}}"
          aria-hidden="true"
        ></i>
        {{navBarService.getTitle(menuItem)}}: {{ menuItem.tagContent ? (menuItem.tagContent | truncate:15:'...') : ''}}
      </label>
      <br>
      <label *ngIf="!menuItem.displayWithTagContent">
        {{navBarService.getTitle(menuItem)}}
      </label>
    </ng-container>
  </button>
  <ng-container *ngTemplateOutlet="submenuTemplate; context: menuItem"></ng-container>
</ng-template>
