/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {Pipe} from "@angular/core";
import {NavBarMenuItem, NavBarMenuItemKey} from "../../layout/nav-bar/nav-bar.service";

/**
 * filter menu items for mobile app.
 * @param	boolean if is a mobile device
 * @example Usage:
 * ```html
 * <p>
 *   {{ menuItems | filterMenu : true : false}}
 * </p>
 * ```
 */
@Pipe({ name: "filterMenu" })
export class FilterMenuPipe {
  transform(navItems: NavBarMenuItem[], isMobileApp: boolean): NavBarMenuItem[] {
    if (!navItems || !isMobileApp) {
      return navItems;
    }

    return navItems.filter(item =>
        item.key == NavBarMenuItemKey.Leave ||
        item.key == NavBarMenuItemKey.Audio ||
        item.key == NavBarMenuItemKey.Video);
  }
}
