/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */
import { NgModule } from "@angular/core";
import { SignalBarsComponent } from "./signal-bars.component";

@NgModule({
  imports: [SignalBarsComponent],
  declarations: [],
  exports: [SignalBarsComponent]
})
export class SignalBarsModule {}