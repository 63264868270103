<!--
    Copyright Compunetix Incorporated 2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  Ramadevi Gavirneni
-->

<div>
<span>
  <a>
    &nbsp;&nbsp;&nbsp; TimeZone: &nbsp;
  </a>
  <select [(ngModel)]="operatingHours.operatingTimezone"
          (change)="onOperatingTimezoneChange(operatingHours, $event.target.value)" >
    <option *ngFor="let obj of timezoneOptions"
            [value]="obj"
            [selected]="operatingHours.operatingTimezone == obj" >
      {{obj}}
    </option>
  </select>
</span>
</div>
<div style="height:20px">
</div>
<div>
<span>
  <a>
    &nbsp;&nbsp;&nbsp; Weekly Hours: &nbsp;
  </a>
</span>
</div>
<div>
<ul>
  <specific-day-hours *ngFor="let item of operatingHours.daysOfWeekHours; index as i"
                      [item]="item"
                      [itemIndex]="i"
                      [specificDayHoursId]="operatingHoursId + '_dayOfWeekItem_' + i"
                      [specialHours]="false">
  </specific-day-hours>
</ul>
</div>
<div>
<span>
  <a>
    &nbsp;&nbsp;&nbsp; Special Day Hours: &nbsp;
  </a>
</span>
</div>
<div style="max-height: 300px; overflow-y:auto;">
  <ol>
    <specific-day-hours *ngFor="let item of operatingHours.specialDayHours; index as i"
                        [item]="item"
                        [itemIndex]="i"
                        [specificDayHoursId]="operatingHoursId + '_specialDayItem_' + i"
                        [specialHours]="true"
                        (remove)="removeSpecialDayHours($event)">
    </specific-day-hours>
  </ol>
</div>
<div>
  <span>
    <a [attr.id]="operatingHoursId + '_add'"
       class="cursor-pointer"
       (click)="addSpecialDayHours()">
       &nbsp;&nbsp;&nbsp; Add Special Hours Day &nbsp;
       <i class="fas fa-plus-circle"></i>
    </a>
  </span>
</div>

