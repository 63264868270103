/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "in-page-panel",
  templateUrl: "./in-page-panel.component.html"
})

/**
 * in-page-panel view
 */
export class InPagePanelComponent {
  @Input() fullscreen = false;
  @Input() title: string = "";
  @Output("exit") exitEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  constructor() {
    
  }
  exit() {
    this.exitEventEmitter.emit();
  }
}
