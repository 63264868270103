/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";
import { PresenceStatus } from "companion";

/**
* Translate presence status to label style class name.
* @example Usage:
* ```html
* <p>
  {{ endpoint.status | labelStyle }}
</p>
* ```
*/
@Pipe({ name: "labelStyle" })
export class StatusStylePipe {
  transform(status: PresenceStatus): any {
    switch (status) {
      case PresenceStatus.available:
      case PresenceStatus.ready:
        return "info";
      case PresenceStatus.connecting:
      case PresenceStatus.ringing:
      case PresenceStatus.disconnecting:
      case PresenceStatus.renegotiating:
      case PresenceStatus.recording:
      case PresenceStatus.observing:
      case PresenceStatus.onhold:
      case PresenceStatus.custom1:
      case PresenceStatus.custom2:
      case PresenceStatus.custom3:
      case PresenceStatus.custom4:
      case PresenceStatus.alone_in_conf:
        return "warning";
      case PresenceStatus.busy:
        return "success";
      case PresenceStatus.away:
      case PresenceStatus.work:
        return "danger";
      case PresenceStatus.offline:
      case PresenceStatus.invisible:
      case PresenceStatus.online:
      default:
        return "secondary";
    }
  }
}
