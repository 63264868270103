/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IRTCServiceFileEventHandler } from "../services/rtc.service.file-event-handler.interface";
import { ISharedFile, FileTransferStatus } from "../sharedfile/sharedfile.interface";
import { IConferenceService } from "./conference.service.interface";
import { ConferenceService } from "./conference.service";
import { ISharedFileService } from "../sharedfile/sharedfile.service.interface";
import { SharedFileService } from "../sharedfile/sharedfile.service";
import { SharedFile } from "../sharedfile/sharedfile";
import { IEndpointService } from "../endpoint/endpoint.service.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { AlertCode } from "../alert/alert.interface";
import * as _ from "lodash";

export class RTCServiceFileEventHandler implements IRTCServiceFileEventHandler {
  constructor(
    private conferenceService: IConferenceService = ConferenceService.getSharedInstance(),
    private sharedFileService: ISharedFileService = SharedFileService.getSharedInstance(),
    private endpointService: IEndpointService = EndpointService.getSharedInstance()
  ) {
    // nothing needed here
  }

  /**
   * accept or reject checker on new file receving
   * @param senderId: string - file sender rtcid
   * @param fileList: Array<any> - file receiving
   * @param acceptCallback: any - call back function on accept
   */
  fileAcceptChecker(senderId: string, fileList: Array<any>, acceptCallback: any) {
    _.forEach(fileList, (fileInfo: any) => {
      let file: ISharedFile = SharedFile.toReceive(senderId, fileInfo.name, fileInfo.size, fileInfo.uuid);
      this.conferenceService.addSharedFile(file);
    });
    acceptCallback(true);
  }

  /**
   * handler on file received
   * @param senderId: string - file sender rtcid
   * @param fileblob: File - file in blob format
   */
  fileReceivedHandler(senderId: string, fileBlob: File) {
    this.conferenceService.alertHandler(AlertCode.fileReceived, "file received: " + senderId + " | " + fileBlob.name);
    let file: ISharedFile = this.conferenceService.getSharedFile(fileBlob['uuid']);
    if (file) {
      file.fileBlob = fileBlob;
    }
  }

  /**
   * handler on file receiving status changes
   * @param senderId: string - file sender rtcid
   * @param msg: any - message received with new status
   */
  fileReceiveStatusHandler(senderId: string, msg: any) {
    let file: ISharedFile;
    if (msg.uuid) {
      file = this.conferenceService.getSharedFile(msg.uuid);
    }
    if (file) {
      file.transmittedSize = msg.received || file.transmittedSize;
      file.fileSize = msg.size || file.fileSize;
      switch (msg.status) {
        case "started":
        case "started_file":
          file.status = FileTransferStatus.started;
          break;
        case "progress":
          file.status = FileTransferStatus.transmitting;
          break;
        case "eof":
        case "done":
          switch (msg.reason) {
            case "accept_failed":
            case "cancelled":
              file.status = FileTransferStatus.failed;
              break;
            default:
              file.status = FileTransferStatus.finished;
              file.transmittedSize = file.fileSize;
              this.conferenceService.notifyFileComplete(file);
              break;
          }
          break;
      }
    }
    return true;
  }

  /**
   * handler on file sending progress changes
   * @param msg: any - message received with new status
   */
  fileSendProgressHandler(receiverId: string, msg: any) {
    let file: ISharedFile;
    if (msg.uuid) {
      file = this.conferenceService.getSharedFile(msg.uuid);
    }
    if (file) {
      file.transmittedSize = msg.position || file.transmittedSize;
      file.fileSize = msg.size || file.fileSize;
      switch (msg.status) {
        case "waiting":
          file.status = FileTransferStatus.idle;
          break;
        case "started_file":
          file.status = FileTransferStatus.started;
          break;
        case "working":
          file.status = FileTransferStatus.transmitting;
          break;
        case "rejected":
          file.status = FileTransferStatus.failed;
          break;
        case "done":
          file.status = FileTransferStatus.finished;
          break;
      }
    }
    return true;
  }
}
