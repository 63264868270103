/**
 * Copyright Compunetix Incorporated 2016
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Pipe } from "@angular/core";
import { Many } from "lodash";

/**
 * Truncate a string to the given length and append suffix.
 * @param	 The collection to iterate over
 * @param	The iteratees to sort by, specified in arrays.
 * @example Usage:
 * ```html
 * <p>
 *   {{ [] | sortBy:['column1','column2']:['asc', 'desc'] }}
 * </p>
 * ```
 */
@Pipe({
  name: "sortBy",
  pure: false
})
export class SortByPipe {
  tmp: Object[] = [];
  transform(list: Object[], iteratees: string[], orders?: Many<boolean| "asc" | "desc">): any {
    this.tmp.length = 0;
    this.tmp = _.orderBy(list, iteratees, orders);
    return this.tmp;
  }
}
