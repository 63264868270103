import { EndpointService } from '../endpoint/endpoint.service';
import {IActiveConference, IMonitorConference} from '../conference/conference.interface';
import {IEndpointRef} from '../endpoint/endpoint.interface';
import { sm } from 'jssm';
import { Companion} from "..";
import { PeerConnectionSm, PeerConnectionState } from "./peer-sm";
import * as _ from "lodash";

export enum ActiveConferenceState {
    conference_alone = 1,
    conference_connecting,
    conference_connected,
    conference_disconnecting,
    conference_held,
    conference_closed
}

export enum ActiveConferenceEvent {
    connecting_peers = 1,
    connected_peers,
    no_active_peers_quit,
    no_active_peers_held,
    no_active_peers_wait,
    disband
}

export type ActiveConferenceEventResult = {
    success: Readonly<boolean>,
    inputEvent: Readonly<ActiveConferenceEvent>,
    confId: Readonly<string>,
    oldState: Readonly<ActiveConferenceState>,
    newState: Readonly<ActiveConferenceState>
};

export enum MonitorConferenceActivityStatus {
    none = 1,
    observed,
    monitoring,
    ended
}

export class ActiveConferenceSm {
    /* 
    // Slimmed-down graphviz edge list with consistent events from labels
    private _machine = sm`
    conference_alone 'connecting_peers' -> conference_connecting;
    conference_alone 'connected_peers' -> conference_connected;
    conference_alone 'no_active_peers_wait' -> conference_alone;
    conference_alone 'disband' -> conference_disconnecting;

    conference_connecting 'connecting_peers' -> conference_connecting;
    conference_connecting 'connected_peers' -> conference_connected;
    conference_connecting 'disband' -> conference_disconnecting;
    conference_connecting 'no_active_peers_wait' -> conference_alone;

    conference_connected 'connecting_peers' -> conference_connecting;
    conference_connected 'disband' -> conference_disconnecting;

    conference_disconnecting 'no_active_peers_quit' -> conference_closed;
    conference_disconnecting 'disband' -> conference_disconnecting;
    conference_disconnecting 'connecting_peers' -> conference_connecting;
    conference_disconnecting 'no_active_peers_held' -> conference_held;
    conference_disconnecting 'no_active_peers_wait' -> conference_alone;

    conference_held 'connecting_peers' -> conference_connecting;
    conference_held 'disband' -> conference_disconnecting;
    conference_held 'no_active_peers_wait' -> conference_alone;
    `;
    */

    // Terse definition is better?
    private _machine = sm`
    [conference_alone conference_connecting conference_connected conference_disconnecting conference_held] 'connecting_peers' -> conference_connecting;

    [conference_alone conference_disconnecting conference_connecting conference_held conference_connected] 'no_active_peers_wait' -> conference_alone;

    [conference_alone conference_connecting conference_connected conference_disconnecting conference_held] 'disband' -> conference_disconnecting;

    [conference_alone conference_connecting] 'connected_peers' -> conference_connected;

    [conference_alone conference_connected] 'no_active_peers_held' -> conference_held;

    conference_disconnecting 'no_active_peers_quit' -> conference_closed;

    conference_disconnecting 'no_active_peers_held' -> conference_held;
    `;

    private _confId: string;

    get confId() : Readonly<string> {
        return this._confId;
    }

    get state(): Readonly<ActiveConferenceState> {
        return ActiveConferenceState[this._machine.state() as keyof typeof ActiveConferenceState];
    }

    constructor(confId: string) {
        this._confId = confId;
    }

    check(event: Readonly<ActiveConferenceEvent>): Readonly<boolean> {
        return this._machine.valid_action(ActiveConferenceEvent[event])
    }

    event(event: Readonly<ActiveConferenceEvent>): Readonly<ActiveConferenceEventResult> {
        let oldState : Readonly<ActiveConferenceState> = _.clone(this.state);
        let result = this._machine.action(ActiveConferenceEvent[event]);
        if (this.state != oldState) {
            console.log(`Conference ${this.confId} transition ${ActiveConferenceState[oldState]} -> 
                ${ActiveConferenceEvent[event]} = ${ActiveConferenceState[this.state]}`);
        }
        return {success: result, inputEvent: event, confId: this.confId, oldState: oldState, newState: this.state};
    }

    /**
     * Get the conference update for our conference, perform any events that need to occur based on the update.
     * return the resulting state (if it changed) null if it has not changed.
     */
    handleActiveConfUpdate(conf : Readonly<IActiveConference>) : Readonly<ActiveConferenceEventResult>
    {
        let myId : string = EndpointService.getSharedInstance().myEndpoint.rtcId;
        let peerConnections = _.filter([...Companion.getConferenceService().peerSmMap.values()], 
            (peer : PeerConnectionSm) => { return peer.confId == this._confId});

        // the conference is gone OR i'm no longer a part of this conference...
        if(conf == null || !_.some(conf.everyone, (endpoint : IEndpointRef) => { return endpoint.rtcId === myId}))
        {
            if(this.state == ActiveConferenceState.conference_disconnecting)
            {
                // check that we have no more peer connections left...
                if(peerConnections.length == 0)
                {
                    return this.event(ActiveConferenceEvent.no_active_peers_quit);
                }
            }

            // disband here.
            return this.event(ActiveConferenceEvent.disband);
        }
        // AM i onhold in here?
        else if(_.some(conf.held, (heldEp : IEndpointRef) => 
            {return heldEp.rtcId ===  EndpointService.getSharedInstance().myEndpoint.rtcId}))
        {
            return this.event(ActiveConferenceEvent.no_active_peers_held);
        }
        // there are active peers in this conference...
        else if (conf.active.length >= 2)
        {
            // verify that we are connected with all the active parties
            let allConnected : boolean = true;
            
            _.forEach(peerConnections, (peer : PeerConnectionSm) => {
                allConnected = (allConnected && Companion.getConferenceService().peerSmMap.has(peer.peerId) && 
                Companion.getConferenceService().peerSmMap.get(peer.peerId).state == PeerConnectionState.peer_connected);
            });

            if(!allConnected)
            {
                // if not, we need to be connecting.
                // we are in the conference with no peers...
                return this.event(ActiveConferenceEvent.connecting_peers);
            }
            else
            {
                return this.event(ActiveConferenceEvent.connected_peers);
            }
        }
        else
        {
            // there are no peers here i should wait
            return this.event(ActiveConferenceEvent.no_active_peers_wait);
        }
    }

    /**
     * Get the conference update for our conference, perform any events that need to occur based on the update.
     * return the resulting state (if it changed) null if it has not changed.
     */
    handleMonitorConfUpdate(conf : Readonly<IMonitorConference>) : Readonly<ActiveConferenceEventResult>
    {

        let myId : string = EndpointService.getSharedInstance().myEndpoint.rtcId;
        let myUserId: string = EndpointService.getSharedInstance().myEndpoint.userId;
        let peerConnections = _.filter([...Companion.getConferenceService().peerSmMap.values()], 
            (peer : PeerConnectionSm) => { return peer.confId == this._confId});

        // the conference is gone OR i'm no longer a part of this conference...
        if(conf == null || !_.some(conf.everyone, (endpoint : IEndpointRef) => { return endpoint.rtcId === myId}))
        {
            if(this.state == ActiveConferenceState.conference_disconnecting)
            {
                // check that we have no more peer connections left...
                if(peerConnections.length == 0)
                {
                    return this.event(ActiveConferenceEvent.no_active_peers_quit);
                }
            }

            // disband here.
            return this.event(ActiveConferenceEvent.disband);
        }
        // Am I an observer in here with subjects OR am I a subject in here with observers?
        else if((_.some(conf.observers, (endpoint : IEndpointRef) => { return endpoint.rtcId === myId}) && (conf.subjects.length >= 1)) ||
                (_.some(conf.subjects, (endpoint : IEndpointRef) => { return endpoint.rtcId === myId}) && (conf.observers.length >= 1)))
        {
            // verify that we are connected with all the active parties
            let allConnected : boolean = true;
            
            _.forEach(peerConnections, (peer : PeerConnectionSm) => {
                allConnected = (allConnected && Companion.getConferenceService().peerSmMap.has(peer.peerId) && 
                Companion.getConferenceService().peerSmMap.get(peer.peerId).state == PeerConnectionState.peer_connected);
            });

            if(!allConnected)
            {
                // if not, we need to be connecting.
                // we are in the conference with no peers...
                return this.event(ActiveConferenceEvent.connecting_peers);
            }
            else
            {
                return this.event(ActiveConferenceEvent.connected_peers);
            }
        }
        else if (_.some(conf.observers, (endpoint : IEndpointRef) => { return endpoint.rtcId === myId}) && (conf.ownerId == myUserId) && (conf.subjects.length == 0) &&
                (this.state != ActiveConferenceState.conference_disconnecting)) {
            // I am an observer of no one, disband the conference.
            return this.event(ActiveConferenceEvent.disband);
        }
        else
        {
          if (this.state == ActiveConferenceState.conference_disconnecting) {
            return this.event(ActiveConferenceEvent.no_active_peers_quit);
          }
          else {
            // there are no peers here i should wait
            return this.event(ActiveConferenceEvent.no_active_peers_wait);
          }
        }
    }
}
