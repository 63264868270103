/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Pipe } from "@angular/core";

/**
 * search items by keyword
 * ```
 */
@Pipe({ name: "listSearch" })
export class ListSearchPipe {
  transform(items: any[], keyword: string, searchByFields: string[]): any {
    if (keyword) {
      return _.filter(items, (item: any) => {
        if (searchByFields) {
          let jsonStringToSearchOn = JSON.stringify(_.pick(item, searchByFields));
          let searchRegex = new RegExp('[{,]\\"\\w+\\":([^{}]*' + keyword + "[^{}]*)[,}]", "i");
          return !!searchRegex.exec(jsonStringToSearchOn);
        } else {
          let searchRegex = new RegExp(keyword, "i");
          return !!searchRegex.exec(item);
        }
      });
    } else {
      return items;
    }
  }
  resolve(obj: any, path: string): any {
    let pathList: string[] = _.compact(path.split("."));
    var current = obj;
    while (pathList.length) {
      if (typeof current !== "object") {
        return undefined;
      }
      current = current[pathList.shift()];
    }
    return current;
  }
}
