/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

let f: number = 20;
let defaultThreshold: number = -30;
let interval: number = 150;
import { Component, Input, OnInit, NgZone, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "volume-meter",
  templateUrl: "volume-meter.template.html"
})

/**
 * volume meter view
 */
export class VolumeMeterComponent implements OnInit {
  /**
   * the stream to visualize
   */
  @Input() stream: any;

  /**
   * the id of canvas
   */
  @Input() id: string;

  /**
   * the view height
   */
  @Input() height: number;

  @Output("volumeChanged") volumeChangedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

  /**
   * the current drawing color
   */
  color: string;

  /**
   * flag if it's speaking
   */
  isSpeaking: boolean;

  /**
   * flag if it's too load over threshold
   */
  isTooLoud: boolean;

  /**
   * volume objects
   */
  volumes: VolumeMeterObject[];

  /**
   * flag if it's muted which is no volume
   */
  isMuted: boolean;

  /**
   * meter animation
   */
  meterAnimation: any;

  speachEvents: any;

  constructor(private _ngZone: NgZone) {
    // nothing needed here
  }

  /**
   * reset current volume meter view
   */
  init() {
    if (this.stream) {
      this.start();
    }
  }

  start() {
    this.isMuted = true;
    this.isTooLoud = false;
    this.isSpeaking = false;
    this.color = "black";
    // init volumes array
    this.volumesArrayReload();
    // listen on the stream
    this.speachEvents = hark(this.stream, { interval: interval, threshold: defaultThreshold });
    // set event handlers
    this.speachEvents.on("speaking", this.onSpeaking.bind(this));
    this.speachEvents.on("volume_change", this.onVolumeChange.bind(this));
    this.speachEvents.on("stopped_speaking", this.onStoppedSpeaking.bind(this));
    // start animation
    this.meterAnimation = window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * volumes array reload
   */
  volumesArrayReload() {
    this.volumes = [];
    for (var i = 0; i < f; ++i) {
      this.volumes.push(new VolumeMeterObject(0, "black"));
    }
  }

  /**
   * meter animation stop
   */
  stop() {
    if (this.meterAnimation) {
      window.cancelAnimationFrame(this.meterAnimation);
      this.meterAnimation = null;
    }
    this.volumesArrayReload();
  }

  /**
   * meter animation restart
   */
  restart() {
    this.stop();
    this.meterAnimation = window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * clean meter canvas and volume data list
   */
  clean() {
    let canvas: any = document.getElementById(this.id);
    if (!canvas) {
      return;
    }
    var drawContext = canvas.getContext("2d");
    drawContext.clearRect(0, 0, canvas.width, canvas.height);
    this.volumesArrayReload();
  }

  /**
   * component load handler
   */
  ngOnInit() {
    this.init();
  }

  /**
   * handler on speaking event
   */
  onSpeaking() {
    this.isSpeaking = true;
  }

  /**
   * handler on volume change event
   * @param volume: number - current volume
   * @param threshold: number - the threshold number current volume meter configured
   */
  onVolumeChange(volume: number, threshold: number) {
    if (volume > threshold) {
      this.isTooLoud = true;
    } else {
      this.isTooLoud = false;
    }

    if (volume > -130) {
      this.isMuted = false;
    } else {
      this.isMuted = true;
    }
    let color = this.updateColor();
    this.volumes.push(new VolumeMeterObject(volume, color));
    if (this.volumes.length > f) {
      this.volumes.shift();
    }
    this.volumeChangedEventEmitter.emit(volume);
  }

  /**
   * handler on stop speaking event
   */
  onStoppedSpeaking() {
    this.isSpeaking = false;
  }

  /**
   * draw wave line for the data
   * @param canvas: any - the canvas dom element
   * @param data: VolumeMeterObject[] - the volumes to be drawn
   */
  drawLineOfData(canvas: any, data: VolumeMeterObject[]) {
    var drawContext = canvas.getContext("2d");
    drawContext.moveTo(0, canvas.height);
    drawContext.beginPath();
    for (var i = 0; i < data.length; i++) {
      drawContext.strokeStyle = data[i].color;
      var value = -data[i].volume;
      var percent = value / 100;
      var height = canvas.height * percent;
      var vOffset = height;
      var hOffset = (i * canvas.width) / f;
      drawContext.lineTo(hOffset, vOffset);
    }
    drawContext.stroke();
  }

  /**
   * draw rect bar for the data
   * @param canvas: any - the canvas dom element
   * @param data: VolumeMeterObject[] - the volumes to be drawn
   */
  drawRect(canvas: any, data: VolumeMeterObject[]) {
    var drawContext = canvas.getContext("2d");
    for (var i = 0; i < data.length; i++) {
      drawContext.fillStyle = data[i].color;
      if (data[i].volume) {
        var value = Math.max(0, (data[i].volume as number) + 100);
        var percent = value / 100;
        var width = canvas.width / f - 2;
        var height = canvas.height * percent;
        var y = (canvas.height - height) / 2;
        var x = (i * canvas.width) / f + 1;
        drawContext.fillRect(x, y, width, height);
      }
    }
  }

  /**
   * draw horizontal bar for the data
   * @param canvas: any - the canvas dom element
   * @param data: VolumeMeterObject[] - the volumes to be drawn
   */
  drawHorizontalBar(canvas: any, data: VolumeMeterObject[]) {
    var drawContext = canvas.getContext("2d");
    let volumeMeter: VolumeMeterObject = _.last(data);
    var height = this.height;
    var y = (canvas.height - height) / 2;
    var x = 0;
    drawContext.fillStyle = "#c0c0c0";
    drawContext.fillRect(x, y, canvas.width, height);
    if (volumeMeter) {
      var value = Math.max(0, (volumeMeter.volume as number) + 120);
      var percent = value / 100;
      var width = canvas.width * percent;
      drawContext.fillStyle = volumeMeter.color;
      drawContext.fillRect(x, y, width, height);
    }
  }

  /**
   * draw volume meter
   */
  draw() {
    let canvas: any = document.getElementById(this.id);
    if (!canvas) {
      return;
    }
    var drawContext = canvas.getContext("2d");
    canvas.height = this.height * 2;
    drawContext.clearRect(0, 0, canvas.width, canvas.height);

    this.drawHorizontalBar(canvas, this.volumes);
    window.requestAnimationFrame(this.draw.bind(this));
  }

  /**
   * update color of volume meter
   */
  updateColor() {
    if (this.isMuted) {
      this.color = "red";
    } else {
      this.color = "green";
      if (this.isTooLoud) {
        this.color = "red";
      }
    }
    return this.color;
  }
  refresh() {
    this.stop();
    this.speachEvents.stop();
    this.speachEvents = null;
    if (this.stream) {
      this.start();
    }
  }
}

class VolumeMeterObject {
  constructor(public volume: number, public color: string) {
    // nothing needed here
  }
}
