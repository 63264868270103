/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  frivolta
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PresenceStatus} from "companion";
import {LocalizationService} from "../../localization/localization.service";

@Component({
  selector: "top-nav-theme",
  templateUrl: "./top-nav-theme.template.html"
})

/**
 * top nav language switch component in the login bar
 */
export class TopNavThemeComponent {
  constructor(public localizationService: LocalizationService) {
    // nothing to do
  }

  @Input() isLoggedIn: boolean;
  @Input() accessibleThemes: string[];
  @Input() themeFilter: string;
  @Input() presenceStatus: PresenceStatus;

  /**
   * emit event when theme filter changed
   */
  @Output("themeFilterChanged") themeFilterChangedEmitter: EventEmitter<string> = new EventEmitter<string>();

  PresenceStatus = PresenceStatus;

  get themeFilterMenuText() {
    return 'Change Theme' + (this.themeFilter?" ("+this.themeFilter+")": "");
  }

  /**
   * handler on theme selected
   * @param themeFilter
   */
  changeThemeFilter(themeFilter: string) {
    this.themeFilterChangedEmitter.emit(themeFilter);
  }
}
