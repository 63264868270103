/**
 * Copyright Compunetix Incorporated 2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  jporter
 */

import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

/**
 * This pipe is used to ignore possible angular filtering of bad content that is supplied by a user. 
 * This should be used sparingly and for data from the theme manager such as custom page/message renders for guest pages.
 * Either instantiate it and call transform or use it in the angular template via injection.
 */
@Pipe({ name: "trustHtml" })
export class TrustHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) : SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
