import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

/**
 * This directive will alert when the DOM contents of the directed component changes (attr, children, charData).
 * Use the [domChangeNotify] attribute on your DOM entry to enable.
 * Pass (domChange) output event binding to receive the event.
 * Check the event is a MutationRecord which contains added, removed, etc.
 */
@Directive({
    standalone: true,
    selector: '[domChangeNotify]' // CSS Attribute domChangeNotify
  })
  export class DomChangeDirective implements OnDestroy {
    private changes: MutationObserver;
    @Output() public domChange = new EventEmitter();
  
    constructor(private elementRef: ElementRef) {
      const element = this.elementRef.nativeElement;
  
      this.changes = new MutationObserver((mutations: MutationRecord[]) => {
          mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
        }
      );
      this.changes.observe(element, {
        attributes: true,
        childList: true,
        characterData: true
      });
    }
  
    ngOnDestroy(): void {
      this.changes.disconnect();
    }
  }