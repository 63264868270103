/**
 * Copyright Compunetix Incorporated 2016-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender, jporter
 */

import { ISkillSet, ISkillTags } from "../skills/skill.interface";
import { ICallQuality, IPCStats } from "../util/stats";
import { VideoResolution } from "../settings/video-resolution";
import { VideoMediaConnectionMode } from "../services/rtc.client.interface";
import { IEndpointBase } from "./endpoint.base.interface";
import { IEntryField} from "../index";
import { BrowserResourceAccessEvent } from "../report/report.interface";
import { IDevice } from "../report/report.interface";
import { ClientState } from "../conference/client-sm";

export interface IEndpointRef {
  rtcId: string;
  userId?: string;
  name?: string;
  isVoiceEp?: boolean;
  twilioCallId?: string;
}
export type EndpointUpdate = Exclude<Partial<IEndpoint>, {rtcId: string, smState: ClientState, enteredOn: number, enteredOnAvailable: number, userId: string, streams: any}>;
export type EndpointUpdates = {[id: string]: EndpointUpdate};

export interface IEndpoint extends IEndpointBase {
  smState?: ClientState;
  statusReason?: StatusReason;
  status?: PresenceStatus;
  isInCustomState?: boolean;
  enteredOn?: number;
  enteredOnAvailable?: number;
  transmitMode?: VideoMediaConnectionMode;
  streams?: {
    [streamId: string]: MediaStream;
  };
  browser?: string;
  mic?: string;
  camera?: string;
  cameraRotation?: string;
  speaker?: string;
  isMicMuted?: boolean;
  isCameraMuted?: boolean;
  isAudioMuted?: boolean;
  isVideoMuted?: boolean;
  isVoiceEp?: boolean;
  callbackType?: CallbackType;
  cameraPermission?: CameraPermission;
  microphonePermission?: CameraPermission;
  voiceDirection?: VoiceDirection;
  twilioCallId?: string;
  volume?: number;
  showVideo?: boolean;
  mostRecentChatRoomId?: string;
  theme?: string;
  lastStats?: IPCStats;
  callQuality?: ICallQuality;
  location?: ICoordinates;
  applicationId?: string;
  // last text message
  lastMessage?: string;
  note?: string;

  // status on geolocation capturing
  geoAccessStatus?: BrowserResourceAccessEvent;
  lastUpdateTimestamp?: Date;

  // Data acquired inside the form presented to the guest
  guestFormData?: IEntryField[];

  // Name for the user interface
  uiName?: string;

  // Guest form data has been done for the voice call
  dataReconciliationDone?: boolean;

  // Endpoint RTCid that starts the dial out
  dialOutInviterRtcId?: string;

  // language
  language?: string;
  serverConnected?: boolean;
  serverNodeId?: string;

  isVoiceMultiParty?: boolean;
  // Active skill tags if specified
  skillTags?: ISkillTags;

  // Endpoints active skill set, if specified
  skillSet?: ISkillSet;

  // is the call rejected
  isCallRejected?: boolean;
  // is the endpoint presenting data
  isPresenting?: boolean;
  // browser support dual video channel
  supportDualChannel?: boolean;
  // id of socket associated to the endpoint
  socketId?: string;
  // remote identifier used to link this endpoint to a set of remote Desktop
  // credentials for controlling the endpoint
  remoteIdentity?: string;

  // Device info
  deviceInfo?: IDevice;
}

export interface IQueueStatus {
  estimatedWaitTime: number;
  position: number;
}

export enum TargetType {
  guest = "guest",
  voice = "voice",
  operator = "operator"
}

export interface IPushToTalkEvent {
  talk: boolean,
  endpoint: IEndpoint
}

export interface IConnector {
  // connector host address
  host: string;
  // connector port
  port: number;
  // whether to auto connect/reconnect the link.
  autoConnect?: boolean;
  // connector socket connection protocol
  protocol: SocketServiceProtocol;
  // activate against local conferences on the XPM (not using XMCM)
  useXpmConferences?: boolean;
  // testing passcode
  testingPasscode?: string;
  // connector activation status
  activated?: boolean;
  // bridge status
  bridgeStatus?: string;
}

export enum SocketServiceProtocol {
  websocket = "websocket",
  io = "io"
}

export enum PresenceState {
  away,
  chat,
  dnd,
  xa
}

export enum PresenceStatus {
  offline,
  away,
  invisible,
  online,
  connecting,
  callback,
  callback_connected_external,
  available,
  busy,
  renegotiating,
  recording,
  onhold,
  work,
  observing,
  disconnecting,
  ringing,
  ready,
  custom1,
  custom2,
  custom3,
  custom4,
  alone_in_conf
}

export enum CallbackType {
  callback = 1,
  callback_connected_external,
}

export enum StatusReason {
  none = 0,
  invisible = 1,
  recess,
  away,
  custom1,
  custom2,
  custom3,
  custom4,
  on_hold,
  resume,
  transfer,
  work,
  callback_external
}

export interface IEndpointStats {
  from?: string;
  to?: string;
  browser?: string;
  language?: string;
  ip?: string;
  mic?: string;
  camera?: string;
  speaker?: string;
  resolution?: VideoResolution;
  stats?: IPCStats;
}

export enum VoiceDirection {
  DialIn = "dialIn",
  DialOut = "dialOut",
  CallBack = "callBack"
}

export enum ConnectionType {
  inbound = "Inbound",
  outbound = "Outbound",
  internal = "Internal"
}

export enum CameraPermission {
  pending = "pending",
  allowed = "allowed",
  disallowed = "disallowed",
}

export interface ICoordinates {
  longitude?: number;
  latitude?: number;
  accuracy?: number;
}

export interface InviteToConnectResponse {
  commandName: string;
  callerId: string;
  voiceEpId: string;
  targetRtcIds: string[];
  isTransfer: boolean;
  isResumeGuestConference: boolean;
  isTakeover: boolean;
  connectionType:  ConnectionType;
}
