/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { ISharedFile } from "./sharedfile.interface";
import { SharedFile } from "./sharedfile";
import { ISharedFileService } from "./sharedfile.service.interface";
import { ConferenceService } from "../conference/conference.service";
import { IConferenceService } from "../conference/conference.service.interface";
import { EasyRTCService } from "../services/rtc.service.easy";
/**
 * sharedFile methods delegate
 */
export class SharedFileService implements ISharedFileService {
  private static sharedInstance: SharedFileService;

  private conferenceService: IConferenceService;

  /**
   * get shared singleton object
   */
  static getSharedInstance(): ISharedFileService {
    if (!SharedFileService.sharedInstance) {
      SharedFileService.sharedInstance = new SharedFileService();
      this.sharedInstance.conferenceService = ConferenceService.getSharedInstance();
    }
    return SharedFileService.sharedInstance;
  }

  /**
   * build drag and drop region
   */
  buildDragNDropRegion(element: HTMLElement) {
    if (element) {
      EasyRTCService.getSharedInstance().buildDragNDropRegion(
        element,
        this.newFilesUploadedHandler.bind(this)
      );
    }
  }

  /**
   * new file uploaded handler
   * @param files: FileList - files uploaded
   */
  newFilesUploadedHandler(files: FileList) {
    if (files.length === 0) {
      return false;
    }
    let currentRtcId = EasyRTCService.getSharedInstance().getCurrentRTCId();
    for (var i = 0; i < files.length; ++i) {
      let file : File = files[i];
      let newSharedFile = new SharedFile(currentRtcId, file.name, file.size, file);
      this.conferenceService.addSharedFile(newSharedFile);
    }
  }
}
