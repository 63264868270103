/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IMessage } from "./message.interface";
import { IChatRoom } from "../chatroom/chatroom.interface";
import { IMessageService } from "./message.service.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { EasyRTCService } from "../services/rtc.service.easy";
import * as _ from "lodash";

/**
 * message methods delegate
 */
export class MessageService implements IMessageService {
  private static sharedInstance: MessageService;

  /**
   * get shared singleton object
   */
  static getSharedInstance(): IMessageService {
    if (!MessageService.sharedInstance) {
      MessageService.sharedInstance = new MessageService();
    }
    return MessageService.sharedInstance;
  }

  /**
   * create new message record in room
   * @param room: IChatRoom - chat room to create message in
   * @param message: IMessage - the new message
   */
  create(room: IChatRoom, message: IMessage): void {
    room.messages.unshift(message);
  }

  /**
   * send new message in room
   * @param room: IChatRoom - chat room to send message to
   * @param message: IMessage - the new message
   */
  sendMessage(room: IChatRoom, message: IMessage): void {
    if (message) {
      let uniqEps = _.uniqBy(room.targets, "rtcId");
      let targetRtcIds = _.compact(_.map(uniqEps, (epRef) => {
        var easyrtcid = epRef.rtcId;
        if (easyrtcid && easyrtcid !== "" && easyrtcid !== EasyRTCService.getSharedInstance().rtcClient.rtcId) {
          return easyrtcid;
        } else {
          return null;
        }
      }));

      // Send to server.
      EasyRTCService.getSharedInstance().sendServerMessage(
        "chatMessage",
        {targetRtcIds: targetRtcIds, roomId: room.roomId, theme: EndpointService.getSharedInstance().myEndpoint.theme, content: message},
        (msgType, msgData) => {
          console.log("SENT CHAT");
        },
        (errorCode, errorString) => {
          console.log("FAILED CHAT");
        });
    } 
  }
}
