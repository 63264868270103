/**
 * Copyright Compunetix Incorporated 2017-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { VideoResolution } from "../settings/video-resolution";
import { IRTCServiceConnectionEventHandler } from "./rtc.service.connection-event-handler.interface";
import { IRTCServiceFileEventHandler } from "./rtc.service.file-event-handler.interface";
import { IRTCServiceServerMessageHandler } from "./rtc.service.server-message-handler.interface";
import { IRTCServiceStreamEventHandler } from "./rtc.service.stream-event-handler.interface";
import { Browser } from "../util/browser";
import { AlertLevel } from "../alert/alert.interface";
import { DeviceInfo } from "../settings/device-info";
import { IRTCClient, PresentationMode } from "./rtc.client.interface";
import { IEndpoint } from "../endpoint/endpoint.interface";
import { IVideoFilter, SegmentMethod } from "../video/video-filter.interface";
import { MediaUtil } from "../util/media";
const MAX_AUDIO_BANDWIDTH: number = 64;
export class RTCClient implements IRTCClient {

  /**
   * default streams
   */
  public get defaultStreams(): { [streamId: string]: MediaStream } {
    let result = Object();
    if (this.videoMuted && this.avatarStream) {
      result[this.avatarStream.id] = this.avatarStream;
    } else if (this.filteredStreamEnabled && this.filteredStream) {
      result[this.filteredStream.id] = this.filteredStream;
    } else if (this.cameraStreamEnabled && this.cameraStream) {
      result[this.cameraStream.id] = this.cameraStream;
    }
    if (this.secondaryCameraEnabled && this.secondaryCameraStream) {
      result[this.secondaryCameraStream.id] = this.secondaryCameraStream;
    }
    if (this.screenShareEnabled && this.secondaryStream) {
      result[this.secondaryStream.id] = this.secondaryStream;
    }
    if (this.showContentEnabled && this.contentStream) {
      result[this.contentStream.id] = this.contentStream;
    }
    return result;
  }

  /**
   * selected microphone
   */
  selectedMicOption: DeviceInfo;

  /**
   * selected camera
   */
  selectedPrimaryCameraOption: DeviceInfo;

  /**
   * selected camera rotation
   */
  selectedPrimaryCameraRotation: string;

  /**
   * selected secondary camera
   */
  selectedSecondaryCameraOption: DeviceInfo;

  /**
   * selected speaker
   */
  selectedSpeakerOption: DeviceInfo;

  /**
   * session id of the web client
   */
  rtcId: string;

  /**
   * recording canvas stream
   */
  recordingCanvasStream: any;

  /**
   * broadcast canvas stream
   */
  broadcastCanvasStream: any;

  /**
   * camera stream
   */
  public cameraStream: any;

  /**
   * flag if current camera is accessible
   */
  public cameraAccessible: boolean;

  /**
  * flag if current microphone is accessible
  */
  public microphoneAccessible: boolean;

  /**
   * mic only stream
   */
  public micOnlyStream: any;

  /**
   * recording stream
   */
  public recordingStream: any;

  /**
   * broadcast stream
   */
  public broadcastStream: any;

  /**
   * avatar stream
   */
  public avatarStream: any;

  /**
   * avatar image
   */
  public avatarImageSrc: string;

  /**
   * flag if show content
   */
  public showContentEnabled: boolean = false;

  /**
   * content stream
   */
  public contentStream: any;

  /**
   * secondary stream
   */
  public secondaryStream: any;

  /**
   * stream to transmit
   */
  public streamToTransmit: any;

  /**
   * flag if show camera stream
   */
  public cameraStreamEnabled: boolean = true;

  /**
   * flag if show filtered camera stream
   */
  public filteredStreamEnabled: boolean;

  /**
   * virtual background segmentation method
   */
  public virtualBackgroundMethod: SegmentMethod;

  /**
   * file senders
   */
  public fileSenders: {
    [rtcId: string]: any;
  } = {};

  /**
   * customized audio codecs
   */
  public audioCodecBlackList: string[];

  /**
   * customized video codecs
   */
  public videoCodecBlackList: string[];

  /**
   * customized video codecs for secondary video channel
   */
  public secondaryVideoCodecBlackList: string[];

  /**
   * TODO THIS WILL NEED TO BE SET...
   */
  public videoFilter: IVideoFilter;

  /**
   * filtered camera stream
   */
  public filteredStream: any;

  /**
   * flag if show screen capture
   */
  public screenShareEnabled: boolean = false;

  /**
   * screen capture stream
   */
  public screenCaptureStream: any;

  /**
   * screen source id
   */
  public screenSourceid: string;

  /**
   * flag if audio muted
   */
  public audioMuted: boolean = false;

  /**
   * flag if video muted
   */
  public videoMuted: boolean = false;

  /**
   * flag if monitoring
   */
  public monitoring: boolean = false;

  /**
   * flag if this client cannot join by audio only
   */
  public disableAudioOnly: boolean = false;

  /**
   * resolution options
   */
  public resolutionOptions: VideoResolution[] = VideoResolution.systemDefaultResolutions;

  /**
   * bandwidth options
   */
  public bandwidthOptions: number[] = [1024, 768, 512, 384, 256, 128];

  /**
   * pc default primary resolution
   */
  public PC_DEFAULT_PRIMARY_RESOLUTION: VideoResolution = { width: 1280, height: 720 };

  /**
   * mobile default primary resolution
   */
  public MOBILE_DEFAULT_PRIMARY_RESOLUTION: VideoResolution = { width: 704, height: 576 };

  /**
   * auto mode primary resolution
   */
  public AUTO_MODE_PRIMARY_RESOLUTION: VideoResolution;

  /**
   * default primary resolution
   */
  public get DEFAULT_PRIMARY_RESOLUTION_IN_THEME(): VideoResolution {
    let result = this.PC_DEFAULT_PRIMARY_RESOLUTION;
    if (this.isMobileDevice) {
      return this.MOBILE_DEFAULT_PRIMARY_RESOLUTION;
    }
    return result;
  }

  /**
   * the user selected primary video resolution
   */
  public userSelectedPrimaryResolution?: VideoResolution;

  /**
   * max primary video resolution
   */
  public maxPrimaryResolution?: VideoResolution;

  /**
   * the current system video resolution
   */
  public get currentSysPrimaryResolution(): VideoResolution {
    return _.minBy(
      _.compact([
        this.maxPrimaryResolution,
        this.userSelectedPrimaryResolution ||
          this.AUTO_MODE_PRIMARY_RESOLUTION ||
          this.DEFAULT_PRIMARY_RESOLUTION_IN_THEME
      ]),
      MediaUtil.videoResolutionSortValue
    );
  }

  /**
   * pc default secondary resolution
   */
  public PC_DEFAULT_SECONDARY_RESOLUTION: VideoResolution = { width: 1280, height: 720 };

  /**
   * mobile default secondary resolution
   */
  public MOBILE_DEFAULT_SECONDARY_RESOLUTION: VideoResolution = { width: 704, height: 576 };

  /**
   * auto mode secondary resolution
   */
  public AUTO_MODE_SECONDARY_RESOLUTION: VideoResolution;

  /**
   * default secondary resolution
   */
  public get DEFAULT_SECONDARY_RESOLUTION_IN_THEME(): VideoResolution {
    let result = this.PC_DEFAULT_SECONDARY_RESOLUTION;
    if (this.isMobileDevice) {
      return this.MOBILE_DEFAULT_SECONDARY_RESOLUTION;
    }
    return result;
  }

  /**
   * the user selected secondary video resolution
   */
  public userSelectedSecondaryResolution?: VideoResolution;

  /**
   * max secondary video resolution
   */
  public maxSecondaryResolution: VideoResolution;

  /**
   * the audio source element to feed speaker test
   */
  public speakerTestAudioSourceElement: any;

  /**
   * the audio element to play speaker test
   */
  public speakerTestAudioPlayElement: any;

  /**
   * speaker test audio source node
   */
  public speakerTestAudioSourceNode: MediaElementAudioSourceNode;

  /**
   * speaker test audio destination node
   */
  public speakerTestAudioDestinationNode: any;

  /**
   * flag if secondary camera source enabled
   */
  public secondaryCameraEnabled: boolean = false;

  /**
   * secondary camera stream
   */
  public secondaryCameraStream: any;

  /**
   * secondary video source id
   */
  public get secondaryVideoSrcId(): string {
    return this.selectedSecondaryCameraOption ? this.selectedSecondaryCameraOption.deviceId : null;
  }

  /**
   * use relay candidate only
   */
  public relayCandidateOnly: boolean = false;

  /**
   * use ipv4 candidate only
   */
  public ipv4CandidateOnly: boolean = true;

  /**
   * use rtp candidate only
   */
  public rtpCandidateOnly: boolean = true;

  /**
   * server configured disconnect timeout
   */
  public SERVER_DISCONNECT_TIMEOUT: number = 60 * 1000;

  /**
   * flag if client shall send logs to server
   */
  public sendLogsToServer: boolean;

  /**
   * the current system secondary video resolution
   */
  public get currentSysSecondaryResolution(): VideoResolution {
    if (this.screenShareEnabled) {
      return _.minBy(
        _.compact([
          this.maxSecondaryResolution,
          this.presentationMode === PresentationMode.Sharpness_First ?
          this.PRESENTATION_SHARPNESS_FIRST_RESOLUTION : this.PRESENTATION_MOTION_FIRST_RESOLUTION
        ]),
        MediaUtil.videoResolutionSortValue
      );
    }
    if (this.secondaryCameraEnabled) {
      return _.minBy(
        _.compact([
          this.maxSecondaryResolution,
          this.userSelectedSecondaryResolution ||
            this.AUTO_MODE_SECONDARY_RESOLUTION ||
            this.DEFAULT_SECONDARY_RESOLUTION_IN_THEME
        ]),
        MediaUtil.videoResolutionSortValue
      );
    }
    return _.minBy(
      _.compact([
        this.maxSecondaryResolution,
        this.PRESENTATION_SHARPNESS_FIRST_RESOLUTION
      ]),
      MediaUtil.videoResolutionSortValue
    );
  }

  /**
   * video source id
   */
  public get primaryVideoSrcId(): string {
    return this.selectedPrimaryCameraOption ? this.selectedPrimaryCameraOption.deviceId : null;
  }

  /**
   * ideal video facingMode
   */
  public facingMode: string = "user";

  /**
   * audio source id
   */
  public audioSrcId: string;

  /**
   * ideal primary video framerate
   */
  public get primaryVideoFrameRate(): number {
    return this.screenShareEnabled
      ? this.maxPrimaryFramerate - this.maxSecondaryFramerate
      : this.maxPrimaryFramerate;
  }

  /**
   * ideal secondary video framerate
   */
  public get secondaryVideoFrameRate(): number {
    return _.min(_.compact([
      this.maxSecondaryFramerate,
      this.presentationMode === PresentationMode.Sharpness_First ?
        this.PRESENTATION_SHARPNESS_FIRST_FRAMERATE : this.PRESENTATION_MOTION_FIRST_FRAMERATE
    ]));
  }

  /**
   * default primary max framerate
   */
  public maxPrimaryFramerate: number = 30;

  /**
   * default secondary max framerate
   */
  public maxSecondaryFramerate: number = 30;

  /**
   * ideal filtered video framerate
   */
  public get filteredVideoFrameRate(): number {
    return _.min([this.maxFilteredVideoFramerate, this.primaryVideoFrameRate]);
  }

  /**
   * default max framerate of filtered video
   */
  public maxFilteredVideoFramerate: number = 24;

  /**
   * theme configured presentation sharpness first resolution
   */
  public PRESENTATION_SHARPNESS_FIRST_RESOLUTION: VideoResolution = { width: 1920, height: 1080 };

  /**
   * theme configured presentation sharpness first framerate
   */
  public PRESENTATION_SHARPNESS_FIRST_FRAMERATE: number = 3;


  /**
   * theme configured presentation motion first resolution
   */
  public PRESENTATION_MOTION_FIRST_RESOLUTION: VideoResolution = { width: 1280, height: 720 };

  /**
   * theme configured presentation motion first framerate
   */
  public PRESENTATION_MOTION_FIRST_FRAMERATE: number = 30;

  /**
   * current presentation mode
   */
  public presentationMode: PresentationMode = PresentationMode.Sharpness_First;

  /**
   * pc default bandwidth
   */
  public PC_DEFAULT_BANDWIDTH: number = 1024;

  /**
   * mobile default bandwidth
   */
  public MOBILE_DEFAULT_BANDWIDTH: number = 384;

  /**
   * default bandwidth
   */
  public get DEFAULT_BANDWIDTH_IN_THEME(): number {
    let result: number = this.PC_DEFAULT_BANDWIDTH;
    if (this.isMobileDevice) {
      return this.MOBILE_DEFAULT_BANDWIDTH;
    }
    return result;
  }

  /**
   * the current system primary audio bandwidth
   */
  public get sysPrimaryAudioBandwidth(): number {
    return MAX_AUDIO_BANDWIDTH;
  }

  /**
   * the current system secondary audio bandwidth
   */
  public get sysSecondaryAudioBandwidth(): number {
    return MAX_AUDIO_BANDWIDTH;
  }

  /**
   * the current video bandwidth
   */
  public userSelectedVideoBandwidth?: number;

  /**
   * max primary video bandwidth
   */
  public maxPrimaryBandwidth?: number;

  /**
   * the current system primary video bandwidth
   */
  public get sysPrimaryVideoBandwidth(): number {
    return this.screenShareEnabled
      ? _.max(
          _.compact([
            this.maxSecondaryBandwidth ? this.fullBandwidth - this.maxSecondaryBandwidth : null,
            this.fullBandwidth / 2
          ])
        )
      : this.fullBandwidth;
  }

  /**
   * max secondary video bandwidth
   */
  public maxSecondaryBandwidth?: number;

  /**
   * the current system secondary video bandwidth
   */
  public get sysSecondaryVideoBandwidth(): number {
    return _.min(_.compact([this.maxSecondaryBandwidth, this.fullBandwidth / 2]));
  }

  /**
   * get full bandwidth of both videos
   */
  private get fullBandwidth(): number {
    return _.min(
      _.compact([this.maxPrimaryBandwidth, this.userSelectedVideoBandwidth || this.DEFAULT_BANDWIDTH_IN_THEME])
    );
  }

  /**
   * shared audio context
   */
  public audioContext: any;

  /**
   * destination node of audio mix as virtual mic
   */
  public virtualMicAudioMixDestinationNode: any;

  /**
   * source node of mic audio
   */
  public micAudioSourceNode: MediaStreamAudioSourceNode;

  /**
   * source node of desktop audio
   */
  public desktopAudioSourceNode: MediaStreamAudioSourceNode;

  /**
   * destination node of audio mix as transmitting audio source
   */
  public transmittingAudioMixDestinationNode: any;

  /**
   * rtc connection event handler
   */
  public connectionEventHandler: IRTCServiceConnectionEventHandler;

  /**
   * file event handler
   */
  public fileEventHandler: IRTCServiceFileEventHandler;

  /**
   * server message event handler
   */
  public serverMessageHandler: IRTCServiceServerMessageHandler;

  /**
   * stream event handler
   */
  public streamEventHandler: IRTCServiceStreamEventHandler;

  /**
   * auto reconnect server
   */
  public autoReconnectServer: boolean = true;

  /**
   * dual channel enabled
   */
  public dualChannelEnabled: boolean = true;

  /**
   * support dual channel
   */
  public get supportDualChannel(): boolean {
    return this.dualChannelEnabled && this.isLocalDualChannelSupported();
  }

  /**
   * peerconnection config
   */
  public peerconnectionConfig: any = { sdpSemantics: "unified-plan" };

  /**
   * avatar image element
   */
  public avatarImageElement: HTMLImageElement;

  /**
   * avatar canvas element
   */
  public avatarCanvasElement: HTMLCanvasElement;

  /**
   * gifler animate object
   */
  public gifAnimator: any;

  /**
   * timer for media permission check
   */
  public mediaPermissionsCheckTimer: any;

  /**
   * avatar canvas draw timer
   */
  public avatarCanvasDrawTimer: any;

  /**
   * secondary canvas draw interval
   */
  public secondaryCanvasDrawInterval: any;

  /**
   * secondary canvas element
   */
  public secondaryCanvasElement: HTMLCanvasElement;

  /**
   * screen capture video element
   */
  public secondaryVideoElement: HTMLVideoElement;

  /**
   * camera request queue
   */
  public cameraRequestProcess: Promise<void>;
  public cameraRequestQueue: {
    forceNew: boolean;
    resolve: (stream: MediaStream) => void;
    reject: (error: Error) => void;
  }[] = [];

  /**
   * secondary camera request queue
   */
  public secondaryCameraRequestProcess: Promise<void>;
  public secondaryCameraRequestQueue: {
    forceNew: boolean;
    resolve: (stream: MediaStream) => void;
    reject: (error: Error) => void;
  }[] = [];

  /**
   * if this is a mobile device
   */
  isMobileDevice: boolean;

  constructor() {
    this.resetMaxPrimaryResolution();
    this.resetMaxSecondaryResolution();
    this.isMobileDevice = Browser.isMobile();
  }

  /**
   * to reset max primary resolution for a new call
   */
  public resetMaxPrimaryResolution() {
    this.maxPrimaryResolution = _.maxBy(this.resolutionOptions, MediaUtil.videoResolutionSortValue);
  }

  /**
   * to reset max secondary resolution for a new call
   */
  public resetMaxSecondaryResolution() {
    this.maxSecondaryResolution = _.maxBy(_.compact([
      this.DEFAULT_SECONDARY_RESOLUTION_IN_THEME,
      this.PRESENTATION_SHARPNESS_FIRST_RESOLUTION,
      this.PRESENTATION_MOTION_FIRST_RESOLUTION
    ]),
    MediaUtil.videoResolutionSortValue);
  }

  /**
   * system preferred primary video codecs
   */
  public get systemPreferredPrimaryVideoCodecs(): string[] {
    // default prefer h264 in order to get high resolution
    let results: string[];
    if (this.isMobileDevice) {
      if (Browser.whichBrowser() === "Safari") {
        if (Browser.whichBrowserVersion() >= 12.4) {
          // safari prefers h264 on version 12.4 and later
          results = ["H264", "VP8"];
        } else if (Browser.whichBrowserVersion() >= 12.2 && Browser.whichBrowserVersion() < 12.4) {
          // safari prefer vp8 on version 12.2 and 12.3
          results = ["VP8", "H264"];
        } else {
          // safari prefers h264 on 12.1 and older which probably not support VP8 anyway
          results = ["H264", "VP8"];
        }
      } else {
        // all android mobile devices prefer h264 in order to get high resolution
        results = ["H264", "VP8"];
      }
    } else {
      if (Browser.whichBrowser() === "Safari") {
        if (Browser.whichBrowserVersion() >= 12.4) {
          // safari prefers h264 on version 12.4 and later
          results = ["H264", "VP8"];
        } else {
          // safari prefer vp8 on version 12.3 and older
          results = ["VP8", "H264"];
        }
      } else {
        // all other desktop browsers prefer h264 in order to get high resolution
        results = ["H264", "VP8"];
      }
    }
    // adding rtx to the codec list.
    results.push("rtx");
    return results;
  }

  /**
   * system preferred secondary video codecs
   */
   public get systemPreferredSecondaryVideoCodecs(): string[] {
    // default prefer VP8 in order to get no tear video
    return ["VP8", "H264", "rtx"];
  }

  /**
   * set alert handler
   */
  public alertHandler(alertCode?: string, alertText?: string, alertLevel: AlertLevel = AlertLevel.log): void {
    console.log(new Date().toISOString() + " ", alertCode, alertText);
  }

  /**
   * prepare AudioContext
   */
  public prepareAudioContext(): void {
    if (!this.audioContext) {
      let AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
      if (AudioContext) {
        console.log("create new audio context!!");
        this.audioContext = new AudioContext();
        this.transmittingAudioMixDestinationNode = this.audioContext.createMediaStreamDestination();
      }
    }
  }

  public isLocalDualChannelSupported(): boolean {
    return Browser.whichBrowser() === "Chrome" || Browser.whichBrowser() === "Firefox"
      || (Browser.whichBrowser() === "Safari" && Browser.whichBrowserVersion(Browser.whichBrowser()) >= 13);
  }

  /**
   * current silence chat audio alerts status
   */
  public silenceChatAudioAlerts: boolean = false;

}
