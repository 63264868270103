<div [hidden]="getBarCount() != 4" class="signal-bars mt1 sizing-box good four-bars">
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
</div>
<div [hidden]="getBarCount() != 3" class="signal-bars mt1 sizing-box ok three-bars">
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
</div>
<div [hidden]="getBarCount() != 2" class="signal-bars mt1 sizing-box bad two-bars">
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
</div>
<div [hidden]="getBarCount() != 1" class="signal-bars mt1 sizing-box bad one-bar">
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
</div>