/**
 * Copyright Compunetix Incorporated 2017-2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {IRTCServiceConnectionEventHandler} from "../services/rtc.service.connection-event-handler.interface";
import {IConferenceService} from "./conference.service.interface";
import {AlertCode, AlertHandlerCallbackOptions, AlertLevel} from "../alert/alert.interface";
import {ConferenceService} from "./conference.service";
import {IEndpointService} from "../endpoint/endpoint.service.interface";
import {EndpointService} from "../endpoint/endpoint.service";
import {LogUtil } from "../util/log";
import {ILocalization } from "../localization/localization.interface";
import { EasyRTCService } from "../services/rtc.service.easy";
import { PeerConnectionEvent } from "./peer-sm";
import { PresenceStatus } from "../endpoint/endpoint.interface";
import { ConferenceUtil } from "./conference";

export class RTCServiceConnectionEventHandler implements IRTCServiceConnectionEventHandler {
  private iceDisconnectTimeout: { [rtcId: string]: any } = {};
  private endpointConnectionStates: { [rtcId: string]: string } = {};
  private callFailedTimes: { [rtcId: string]: number } = {};
  private serverDisconnectedTimeoutCallback: ()=>void = null;
  private showingDialog = false;

  constructor(
    private conferenceService: IConferenceService = ConferenceService.getSharedInstance(),
    private endpointService: IEndpointService = EndpointService.getSharedInstance()
  ) {}

  setServerDisconnectedTimeoutNotify(callback: ()=>void) {
    this.serverDisconnectedTimeoutCallback = callback;
  }

  resetServerDisconnectedTimeoutNotify() {
    this.serverDisconnectedTimeoutCallback = null;
  }

  /**
   * handler on webrtc server connection succeeded in general mode
   */
  serverConnectSuccessListener(): void {
    this.conferenceService.isServerTimeoutFired = false
    this.conferenceService.alertHandler(AlertCode.serverConnected, null, AlertLevel.success);
    this.endpointService.myEndpoint.rtcId = EasyRTCService.getSharedInstance().getCurrentRTCId();
    this.endpointService.create(this.endpointService.myEndpoint);
    EasyRTCService.getSharedInstance().rtcClient.rtcId = this.endpointService.myEndpoint.rtcId;
    EasyRTCService.getSharedInstance().applyPeerconnectionConfig();
  }

  /**
   * handler on webrtc server disconnect
   */
  serverDisconnectListener() {
    this.conferenceService.alertHandler(AlertCode.serverDisconnected, null, AlertLevel.info);
    this.conferenceService.actionCompleted = true;
    //if we are in callback don't bother with the timeout
    if(this.endpointService.myEndpoint.status === PresenceStatus.callback)
    {
      return;
    }

    if(!EasyRTCService.getSharedInstance().disconnectRequested)
    {
      // NOTIFY IF WE HAVE SENDLOGS:
      if (LogUtil.getLogInstance().logsSettings.serverConnection) {
        this.sendLogs("server connection failed");
      }
      // only send alert if we did not request this disconnect.
      let serverTimeout = setTimeout(() => {
        clearTimeout(serverTimeout);
        if (!EasyRTCService.getSharedInstance().isServerConnected()) {
          console.log("FIRING SERVER TIMEOUT!!!!!!!!!!!");
          this.conferenceService.alertHandler(AlertCode.lostServerConnection, null, AlertLevel.prominent);
          this.conferenceService.isServerTimeoutFired = true;
          // Let the rtc messages flush and then tell the client we timed out?
          setTimeout(() => {
            if (this.serverDisconnectedTimeoutCallback) {
              this.serverDisconnectedTimeoutCallback();
            };
          }, 1500);
        }
      }, EasyRTCService.getSharedInstance().rtcClient.SERVER_DISCONNECT_TIMEOUT);
    }
  }

  /**
   * handler on webrtc server connection failed
   * @param errorCode: string - error code
   * @param message: string - error message
   */
  serverConnectFailureListener(errorCode: string, message: string) {
    this.conferenceService.alertHandler(AlertCode.serverFailedToConnect, errorCode + ", " + message, AlertLevel.warning);

    if (LogUtil.getLogInstance().logsSettings.serverConnection) {
      this.sendLogs("server connection fail");
    }
  }

  /**
   * call fail listener
   * @param rtcId: string - the rtcId of the peer which call failed
   * @param errorCode: string - error code
   * @param message: string - error message
   */
  callFailureListener(rtcId?: string, errorCode?: string, message?: string) {
    this.conferenceService.alertHandler( AlertCode.callFailed,  errorCode + " | " + message, AlertLevel.warning);
    this.callFailedTimes[rtcId] = (this.callFailedTimes[rtcId] || 0) + 1;
    
    this.callDisconnectedListener(rtcId);
    if (LogUtil.getLogInstance().logsSettings.peerConnection) {
      this.sendLogs("peer connection fail");
    }
  }

  /**
   * call disconnect listener
   * @param rtcId: string - the rtcId of the peer which disconnected
   */
  callDisconnectedListener(rtcId?: string) {
    this.conferenceService.alertHandler( AlertCode.callDisconnected, "Endpoint: " + rtcId, AlertLevel.log);
    if (rtcId) {
      this.endpointService.removeAllStreamsOfEndpoint(rtcId);
    }

    // update the PEER SM (if it exists)
    this.conferenceService.peerSmMap.get(rtcId)?.event(PeerConnectionEvent.closed);
    EasyRTCService.getSharedInstance().hangupPeer(rtcId);
  }

  /**
   * Handler when the peer connection is created
   * @param rtcId: string - the rtcId of the peer we are connected to
   * @param pc: any -the created peer connection
   */
  connectionCreated(rtcId: string, pc?: any) {
    if (!pc) {
      return;
    }
    // update PEER SM...
    if(!this.conferenceService.peerSmMap.has(rtcId))
    {
      console.log("Got connection without peer SM, create it now:", rtcId);
      // create an orpahn peer SM...
      // and ready to accept
      this.conferenceService.createPeerStateMachine(rtcId, null, false);
      this.conferenceService.peerSmMap.get(rtcId).event(PeerConnectionEvent.conn_wait);
    }
    this.conferenceService.peerSmMap.get(rtcId)?.event(PeerConnectionEvent.conn_accept);

    // update the client that a connection has been created...
    this.conferenceService.emitPeerConnectionCreatedEvent(rtcId, pc);
  }

  /**
   * handler on ice state change event
   * @param rtcId: string - the rtcId of the pc which ice state changing
   * @param pc: any - the peer connection which ice state changing
   * @param state: string - the new ice state
   */
  iceStateChangeListener(rtcId: string, pc: any, state?: string) {
    if (!pc) {
      return;
    }
    let newState = pc.iceConnectionState;
    this.conferenceService.alertHandler(AlertCode.iceStateChange, "Ice state changed for rtcId " + rtcId + ", new state: " + newState, AlertLevel.log);
    this.endpointConnectionStates[rtcId] = newState;
    if (
      !this.iceDisconnectTimeout[rtcId] &&
      (newState === "failed" || newState === "closed" || newState === "disconnected")
    ) {
      // when disconnected, wait for the recovery, timeout after a longer period of time
      // when failed, wait for the ice-restart, timeout after a longer period of time
      // when closed, hang up immediately
      let MEDIA_DISCONNECT_TIMEOUT = newState === "closed" ? 500 : EasyRTCService.getSharedInstance().rtcClient.SERVER_DISCONNECT_TIMEOUT;
      
      this.iceDisconnectTimeout[rtcId] = setTimeout(() => {
        clearTimeout(this.iceDisconnectTimeout[rtcId]);
        delete this.iceDisconnectTimeout[rtcId];
        let peerconnection: any = EasyRTCService.getSharedInstance().getPeerConnectionById(rtcId);
        if (
          peerconnection == null ||
          (peerconnection.iceConnectionState &&
            (peerconnection.iceConnectionState === "failed" ||
              peerconnection.iceConnectionState === "closed"))
        ) {
          this.conferenceService.alertHandler(AlertCode.mediaConnectionLost, null, AlertLevel.prominent);
          if (LogUtil.getLogInstance().logsSettings.iceNegotiation) {
            this.sendLogs("ice negotiation fail");
          }
      
          // update connection fail here...
        }
      }, MEDIA_DISCONNECT_TIMEOUT);
    }
  }

  /**
   * handler on connection state change event
   * @param rtcId: string - the rtcId of the pc which state changing
   * @param pc: any - the peerconnection which state changing
   */
  connectionStateChangeListener(rtcId: string, pc: any) {
    if (!pc) {
      return;
    }
    let newState = pc.connectionState;
    this.conferenceService.alertHandler(
      AlertCode.connectionStateChange, "pc connection state changed for rtcId: " + rtcId + ", new state: " + newState,
      AlertLevel.log);
    this.endpointConnectionStates[rtcId] = newState;
    if (newState === "failed") {
      // One or more of the ICE transports on the connection is in the "failed" state.
      // Send a confirmable dialog so the user knows what's up.
      let alertOptions: AlertHandlerCallbackOptions;
      
      let localization: ILocalization = EndpointService.getSharedInstance().fetchLocalization();
      let doAlert = false;
      if (localization) {
        let notif = localization?.errorMessages["CALL_DROPPED_NOTIFICATION_MESSAGE"];
        if (notif && !this.showingDialog) {
          this.showingDialog = true;
          alertOptions = {
            seconds: 0, // hide base alert
            dialogOptions: {
              title: localization?.errorMessages["CALL_DROPPED_NOTIFICATION_TITLE"] || "Attention!",
              message: notif,
              backdrop: true,
              onEscape: () => {
                this.showingDialog = false;
              },
              buttons: {
                OK: {
                  label: localization?.errorMessages["CALL_DROPPED_NOTIFICATION_CONFIRM"] || "Continue",
                  className: "btn-success",
                  callback: () => {
                    this.showingDialog = false;
                    console.log('This was logged CALL DROPPED DIALOG EVENT... CONTINUE');
                  }
                },
                ABORT: {
                  label: localization?.errorMessages["CALL_DROPPED_NOTIFICATION_ABORT"] || "Hangup",
                  className: "btn-danger",
                  callback: () => {
                    this.showingDialog = false;
                    console.log('This was logged CALL DROPPED DIALOG EVENT... HANGUP');
                    let ep = this.endpointService.getEndpointById(rtcId);
                    let queueName = ConferenceUtil.GuestWaitRoomPrefix + ConferenceUtil.getQueueName(ep.groupId, ep.skillTags);
                    this.endpointService.transferToQueue(ep, queueName);
                  }
                }
              },
              
            }
          };
          doAlert = true;
        }
      }
      // Do we need to send this confirmable alert?
      if (doAlert) {
        this.conferenceService.alertHandler(AlertCode.mediaConnectionLost, null, AlertLevel.warning, alertOptions);
      }
      else
      {
        this.conferenceService.alertHandler(AlertCode.mediaConnectionLost, "MEDIA_CONNECTION_LOST", AlertLevel.warning);
      }
      
      this.callDisconnectedListener(rtcId);
    
      if (LogUtil.getLogInstance().logsSettings.peerConnection) {
        this.sendLogs("peer connection fail");
      }
    }
    if (newState === "connected") {
      this.callFailedTimes[rtcId] = null;
      // update peer SM...
      console.log("Update peer state", 
      this.conferenceService.peerSmMap.get(rtcId)?.event(PeerConnectionEvent.connected).newState);
      if (this.conferenceService.localVideoUpdateHandler) {
        this.conferenceService.localVideoUpdateHandler();
      }
    }
  }

  /**
   * handler on ice gathering state change event
   * @param rtcId: string - the rtcId of the pc which ice gathering state changing
   * @param pc: any - the peerconnection which ice gathering state changing
   */
  iceGatheringStateChangeListener(rtcId: string, pc: any) {
    if (!pc) {
      return;
    }
    let newState = pc.iceGatheringState;
    this.conferenceService.alertHandler(
      AlertCode.iceStateChange,
      "Ice gathering state changed for rtcId: " + rtcId + ", new state: " + newState,
      AlertLevel.log);
  }

  sendLogs(trigger: string) {
    const logsPromises: Promise<any>[] = [LogUtil.getLogInstance().sendLogs()];
    if (LogUtil.getLogInstance().logsSettings.webrtcStatistics) {
      logsPromises.push(LogUtil.getLogInstance().sendWebRTCStats());
    }
    Promise.all(logsPromises)
    .then((res) => this.conferenceService.alertHandler(
      AlertCode.logAutomaticTrigger,
      "Automatic " + trigger + " logs sent.",
      AlertLevel.warning))
    .catch(error => this.conferenceService.alertHandler(
      AlertCode.logAutomaticTrigger,
      "Automatic " + trigger + " logs sending failed.",
      AlertLevel.warning));
  }
}
